
import { FETCH_USERS_LABELS, UPDATE_FOLLOWUP_COUNT } from '../actions/usersLabels'

const defaultState = {
  status: 'UNINIT',
  users: [],
  loader: false,
  followUpCount: false
}

export default (state = defaultState, action) => {
  switch (action.type) {
    case `${FETCH_USERS_LABELS}_REQUEST`:
    {
      return {...state, 
        status : 'PENDING',
        loader: true,
        users: [],
      }
    }

    case `${FETCH_USERS_LABELS}_SUCCESS`:
    {
      let { 
        responseData : {
          users
        }
       } = action.res
      return {...state, 
        status : 'SUCCESS',
        loader: false,
        users
      }
    }

    case `${FETCH_USERS_LABELS}_FAILURE`:
    {
      let { 
        responseData : {
          users
        }
       } = action.res
      return {...state, 
        status : 'FAILURE',
        loader: false,
        users
      }
    }
    case `${UPDATE_FOLLOWUP_COUNT}`:
    {
      
      return {...state, 
        followUpCount: !state.followUpCount 
      }
    }
    default:
      return state
  }
}
