import React from 'react';
import dateFormat from 'dateformat';
const viewFollowup = ({followupdata}) => {
    const { users, start, desc } = followupdata
    return (
        <div>
            <div className="form-group">
              <div className="card-body">
                    <h5 className="card-title">Name :  {`${users.firstName} ${users.lastName}`} {users.lastName}  </h5>
                    <h6 className="card-title">Email :  {users.email} </h6>
                    <h6 className="card-title">Phone Number :  {users.fullMobileNo} </h6>
                    <h6 className="card-title">Date :  {dateFormat(start, "d mmmm yyyy")} </h6>
                    <p className="card-text">{desc}</p>
                </div> 
            </div>
        </div>
    );
};

export default viewFollowup;