import {  markFollowupNotificationRead } from '../../config/service/notificationService';

// function to mark notification as read
export function markReadNotifications(params) {
    markFollowupNotificationRead(params)
            .then(res => {
                if (res.data.statusCode == 0) {
                    console.log(res.data.error.errorMessage);
                }
            })
}