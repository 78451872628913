import React, { Component } from 'react';
import Header from '../template/Header';
import { getUsersMessages, getUserDetails, getFitnessMessages } from '../../config/service/usersService';
import { getDieticianProfile } from '../../config/service/adminService';
import setting from '../../config/settings/'
import cogoToast from 'cogo-toast';
import { Link } from 'react-router-dom';
import io from 'socket.io-client';
import { Loader } from '../Reuse';
import moment from 'moment'
import notificationSound from '../../assets/sounds/notificationSound.mp3';

const socketUrl = (setting.api.socket)

class Chat extends Component {
    constructor(props) {
        super(props)
        this.state = {
            userDetails: {},
            socket: null,
            user: null,
            senderUserID: "",
            message: "",
            messagesList: [],
            sendDisabled: true,
            sentMessages: [],
            receivedMessages: [],
            senderInfo: {},
            dieticianId: localStorage.getItem('userId'),
            loader: false,
            unReadMessage: false
        }
    }


    componentDidMount() {
        this.getUserDetail();
        this.getDieticianProfile();
        // this.initSocket();
        this.scrollToBottom();
        this.getUsersMessages();
        

    }




    getDieticianProfile = () => {
        getDieticianProfile()
            .then(res => {
                if (res.data.statusCode == 1) {
                    this.setState({ senderInfo: res.data.responseData.resut })
                } if (res.data.statusCode == 0) {
                    cogoToast.error(res.data.error.errorMessage);
                }
            })
    }



    componentDidUpdate() {
        this.scrollToBottom();
    }


    scrollToBottom = () => {
        this.el.scrollIntoView({ block: "end" });
    }


    initSocket = (params) => {
        const { user, senderUserID } = this.state
        let senderId = localStorage.getItem('userId')
        let accessToken = localStorage.getItem('accessToken')
        let receiverId = params.userDetails._id
        this.setState({ user: receiverId })

        var socket = io.connect(socketUrl, {
            transports: ['websocket'],
            query: { accessToken: accessToken, role: 2 }
        });

        let connectionParams = {
            senderId, receiverId, role: 2
        }

        socket.emit('get_specific_messages', connectionParams)

        socket.emit('Read_Message', connectionParams)

        socket.on('connection', (res) => {
        })

        socket.on('Received_Messages', (res) => {
            let audio = new Audio(notificationSound);
            audio.play();
            if (res.senderId === this.props.match.params.id) {
                const messagesList = Array.from(this.state.messagesList);
                messagesList.push(res)
                this.setState({ messagesList })
            }
        })

        this.setState({ socket }, () => {
            this.setUser(params._id);
        })
    }

    setUser = (user) => {
        let userId = localStorage.getItem('userId')
        let self = this;
        this.setState({ user, senderUserID: user })
    }

    getUserDetail = () => {
        let params = {
            params: { id: this.props.match.params.id }
        }
        getUserDetails(params).then(res => {
            if (res.data.statusCode == 1) {
                this.initSocket({ userDetails: res.data.responseData });
                this.setState({ userDetails: res.data.responseData })
            } else {
                this.setState({ errors: res.data.error.errorMessage })
            }
        })
    }

    getUsersMessages = () => {
        this.setState({ loader: true })
        let params = {
            params: { receiverId: this.props.match.params.id }
        }
        getUsersMessages(params).then(res => {
            if (res.data.statusCode == 1) {
                this.setState({ messagesList: res.data.responseData.messages.reverse(), loader: false })
            } else {
                cogoToast.error(res.data.error.errorMessage)
                this.setState({ loader: false })
            }
        })
    }

    handleChange = (e) => {
        let { name, value } = e.target;
        this.setState({ [name]: value, sendDisabled: false })
    }

    keyPressed = event => {
        if (event.key === "Enter" && !event.shiftKey)
            this.sendMessage(event)
    }

    sendMessage = (e) => {
        e.preventDefault();
        let self = this;
        let { message, userDetails, socket } = this.state

        let userId = localStorage.getItem('userId')
        let messageObj = {
            role: 2,
            message,
            senderId: userId,
            receiverId: userDetails._id,
        }

        if (message.length) {
            socket.emit('SEND_MESSAGE', messageObj)
            if (navigator.onLine) {
                const messagesList = Array.from(this.state.messagesList);
                messagesList.push(messageObj)
                self.setState({ messagesList })
                this.setState({ message: "" })
            } else {
                cogoToast.error("Ooops! You are offline, check your internet connection and try again")
            }

        }
    }

    addMenuOpenClass = () => document.body.classList.toggle('open_menu');
    render() {
        let { userDetails, message, sendDisabled, sentMessages, receivedMessages, messagesList, dieticianId, loader, senderInfo } = this.state

        return (
            <React.Fragment>
                <div className="page_wrapper">
                    <div className="chat_wrapper">
                        <div className="row">
                            <div className="col-lg">
                                <div className="card_wrapper cm_window_hieght pt-0 pb-0">

                                    <div className="chat_massage_body" id="messageBox">

                                        {loader ?
                                            <div className='cm_center_pos'>
                                                <div className="spinner-grow text-primary" role="status">
                                                    <span className="sr-only">Loading...</span>
                                                </div></div> : (!messagesList.length ?
                                                    <div className="text-center">
                                                        <span className="text-danger">No messages yet!</span></div> : null)}



                                        {messagesList.map((messages, index) => (
                                            <div key={index} className={dieticianId == messages.senderId ? "chat_wrap my_chat" : "chat_wrap other_chat"}>
                                                <div className="chat_timeline">
                                                    <span className="timeline_img"><img src={dieticianId == messages.senderId ? senderInfo.profilePic : userDetails.profilePic} /></span>
                                                    <span className="timeline_name">{dieticianId == messages.senderId ? "You" : userDetails.firstName + " " + userDetails.lastName}</span>
                                                </div>
                                                
                                                <div className={dieticianId == messages.senderId ? "chat__text display-linebreak" : "chat__text text-left display-linebreak"}>
                                                    {messages.message}
                                                    <span className="_msg_time"><i className="fa fa-clock-o" /> {moment(messages.created).local().format('Do MMM hh:mm a')}
                                                    </span>
                                                </div>

                                            </div>
                                        ))}
                                        <div ref={el => { this.el = el; }} />
                                    </div>


                                    <form onSubmit={this.sendMessage}>
                                        <div className="chat_massage_bottom row">

                                            <div className="cm_textarea col-11">
                                                <textarea className="from-control" placeholder="Type here..." name="message" value={message} onChange={this.handleChange}
                                                    // onKeyPress={this.keyPressed}
                                                ></textarea>
                                            </div>

                                            <div className="cm_msg_send_wrapper col-1">
                                                {/* <div className="chat__tools col">
                                                    <span className="chat_tool"><i className="fa fa-link"></i></span>
                                                    <span className="chat_tool"><i className="fa fa-file-image-o"></i></span>
                                                    <span className="chat_tool"><i className="fa fa-camera"></i></span>
                                                </div> */}

                                                <div className="msg_send_wrapper">
                                                    <button type="submit" disabled={sendDisabled}><i className="fa fa-paper-plane" /></button>
                                                </div>
                                            </div>
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </React.Fragment>
        )
    }
}

export default Chat;