import React, { useEffect, useState } from 'react';
import ExercisePlaceholder from '../../assets/img/ExercisePlaceholder.png';
import { ReadMore } from './ReadMore';
const daysName = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'];

const FitnessPlanCard = ({ item, key }) => {
    
    return (
        <div className="col-lg-4 col-md-6" key={key}>
            <div className="custom-badge fitness-badge">
                {item && item.exerciseCategory && item.exerciseCategory.categoryTitle}
                <span className="ribbon-corner"></span>
            </div>
            <div className="fitness-plan-card">
                <div className="fitness-image-wrapper">
                    <img src={item.exerciseImage ? item.exerciseImage : ExercisePlaceholder} alt="" />
                    <h3 className="excercise-name">{item.exerciseTitle}</h3>
                    <div className="video-wrapper" title="View video">
                        <a href={item.exerciseLink} className="d-flex align-items-center" target="_blank">
                            <i className="fa fa-lg fa-youtube-play" />
                        </a>
                    </div>
                </div>
                <div className="fitness-card-content">
                    <div className="steps-detail">
                        <p className="aminities">
                            <span className="label">Repetitions : </span>
                            <span className="value">{item.repetitions}</span>
                        </p>
                        <p className="aminities">
                            <span className="label">Sets : </span>
                            <span className="value">{item.sets ? item.sets : "-"}</span>
                        </p>
                        <p className="aminities">
                            <span className="label">Time : </span>
                            <span className="value">{item.time ? item.time : '-'} minutes</span>
                        </p>
                    </div>
                </div>
                <div className="fitness-card-description">
                    <h3 className="description-heading">Description</h3>
                    <div className="description-content">
                        <p className="desc-content" id={item._id}>
                            {/* {
                            readMore &&
                            <p className="read-me-wrapper d-flex justify-content-end">
                                <span className="cursor-pointer">Read More</span>
                            </p>
                        } */}
                            <ReadMore>
                                {item.description}
                            </ReadMore>
                        </p>
                        {
                            item.days ?
                                <div className="days-list">
                                    <h6 className="days-heading">Days : </h6>
                                    <ul className="list-unstyled days-listing">
                                        {item.days.map((day, i) =>
                                            <li className="day" key={i}>{daysName[day - 1]} </li>
                                        )}
                                    </ul>
                                </div>
                                :
                                <div className="days-list">
                                    <h6 className="days-heading">Days : </h6>
                                    <ul className="list-unstyled days-listing">
                                        {daysName.map((day, i) =>
                                            <li className="day" key={i}>{day}</li>
                                        )}
                                    </ul>
                                </div>
                        }
                    </div>
                </div>
            </div>
        </div>
    )
}

export default FitnessPlanCard