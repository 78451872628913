
import { FETCH_HELP_VIDEOS } from '../actions/helpVideos'

const defaultState = {
  status: 'UNINIT',
  helpVideos: [],
  totalCount:0,
  loader: false
}

export default (state = defaultState, action) => {
  switch (action.type) {
    case `${FETCH_HELP_VIDEOS}_REQUEST`:
    {
      const {
        isSearch
      } = action
      return {...state, 
        status : 'PENDING',
        totalCount:0,
        ...(isSearch)?{miniLoader: true}: {loader: true},
        dietHistory: [],
      }
    }

    case `${FETCH_HELP_VIDEOS}_SUCCESS`:
    {
      let { 
        responseData : {
          result:helpVideos,
          totalCount
        }
       } = action.res
      return {...state, 
        status : 'SUCCESS',
        helpVideos,
        totalCount,
        loader: false,
        miniLoader:false    
      }
    }

    case `${FETCH_HELP_VIDEOS}_FAILURE`:
    {
      let { 
        responseData : {
          result:helpVideos,
          totalCount
        }
       } = action.res
      return {...state, 
        status : 'FAILURE',
        helpVideos,
        totalCount,
        loader: false,
        miniLoader:false   
      }
    }
     
    default:
      return state
  }
}
