import React, { useEffect, useState } from "react";
import {
  Modal,
  Button,
  Form,
  Alert,
  Dropdown,
  FormCheck,
  ProgressBar,
} from "react-bootstrap";
import Select from "react-select";
import { MiniLoader } from "../Reuse";
import dateFormat from "dateformat";
// import 'bootstrap/dist/css/bootstrap.css';
import "bootstrap-daterangepicker/daterangepicker.css";
import DateRangePicker from "react-bootstrap-daterangepicker";
import DatePicker from "react-datepicker";

const initialSettings = {
  startDate: new Date(),
  endDate: new Date(),
  maxDate: new Date(),
  locale: {
    cancelLabel: "Clear",
  },
};
const datefilter = "diet";
// const dateObject = {
//     dateFrom : null,
//     dateTo : null
// }
const UsersListHeader = ({
  totalUserCount,
  miniLoader,
  search,
  handleSearch,
  handleSort,
  handleFilterDietPlanApproveReject,
  sortType,
  clearFilter,
  handleUnreadUser,
  isUnreadOnly,
  dateRange,
  selectDateRange,
  handleCancelDateRange,
  newMessageUserId,
  handleNewMessage,
  unreadMessageObject,
  filterDateObjet,
  handleDateRange,
  handleRefreshDateRange,
  initialFilterField,
  initialFilterValue,
  handleDietPlanAssigned,
}) => {
  const [dateFrom, setDateFrom] = useState(null);
  const [toFrom, setToFrom] = useState(null);

  const [filterField, setFilterField] = useState(initialFilterField);
  const [filterValue, setFilterValue] = useState(initialFilterValue);

  var dietPlanOptions = [
    { value: "", label: "All" },
    { value: 0, label: "Pending" },
    { value: 1, label: "Reject" },
    { value: 2, label: "Draft" },
    { value: 3, label: "Approve" },
  ];

  const handleCancel = (event, picker) => {
    picker.element.val("");
  };

  const onDateFilterChange = (e) => {
    selectDateRange = e.target.value;
  };

  const refreshDate = () => {
    setDateFrom(null);
    setToFrom(null);
  };

  const handleDateFilter = (date, dateField) => {
    switch (dateField) {
      case "dateFrom":
        filterDateObjet.fromDate = new Date(date);
        setDateFrom(new Date(date));
        break;

      case "dateTo":
        filterDateObjet.toDate = new Date(date);
        setToFrom(new Date(date));
        break;
    }
  };
  const handleFilter = (field, value) => {
    setFilterField(field);
    setFilterValue(value);
  };

  useEffect(() => {
    handleDietPlanAssigned(dateFrom, toFrom, filterValue);
  }, [filterField, filterValue]);

  const unnreadfilterClass = (isUnreadOnly) =>
    isUnreadOnly
      ? "fa fa-envelope fa-border badge-count active"
      : "fa fa-envelope fa-border badge-count";
  const dataFilterClass = (dateRange) =>
    dateRange && dateRange.startDate && dateRange.endDate
      ? "fa fa-calendar pull-right active"
      : "fa fa-calendar pull-right";
  return (
    <>
      <div className="sub_header px-0">
        <h3>Users</h3>
        <span className="separator"></span>
        <span>{totalUserCount} Total</span>
        <div className="search_wrapper">
          {miniLoader ? (
            <MiniLoader />
          ) : (
            <i className="fa fa-search" aria-hidden="true"></i>
          )}

          <input
            type="text"
            placeholder="Search by Name or E-mail"
            className="form-control"
            value={search}
            onChange={handleSearch}
          />
        </div>

        <div className="cm_filter">
          <Select
            className="cm_filter_select"
            isSearchable
            placeholder="Search Diet Plan"
            options={dietPlanOptions}
            onChange={(val) => handleFilterDietPlanApproveReject(val)}
          />
        </div>

        {/* <div className="cm_filter">
                    <Dropdown>
                        <Dropdown.Toggle id="dropdown-basic">Sort by <i className="fa fa-filter"></i></Dropdown.Toggle>
                        <Dropdown.Menu>
                            <Dropdown.Item onClick={() => handleSort('firstName')}><i className={`fa fa-sort-alpha-${sortType == 1 ? 'asc' : 'desc'}`}></i>&nbsp;&nbsp; Sort by name</Dropdown.Item>
                            <Dropdown.Item onClick={() => handleSort('created')}><i className="fa fa-sort-alpha-asc"></i>&nbsp;&nbsp; Sort by Created Date</Dropdown.Item>
                            <Dropdown.Item onClick={() => clearFilter()}><i className="fa fa-refresh"></i>&nbsp;&nbsp; Reset Filter</Dropdown.Item>
                        </Dropdown.Menu>
                    </Dropdown>
                </div> */}

        <div className="date_picker mr-0">
          {dateRange && dateRange.startDate && dateRange.endDate && (
            <div>
              Showing record from{" "}
              <strong>{dateFormat(dateRange.startDate, "d mmmm yyyy")} </strong>
              to{" "}
              <strong>
                {" "}
                {dateFormat(dateRange.endDate, "d mmmm yyyy")}
              </strong>{" "}
            </div>
          )}

          <div className="icons" onClick={() => handleUnreadUser()}>
            <i className={unnreadfilterClass(isUnreadOnly)}>
              {(newMessageUserId || unreadMessageObject?._id) && (
                <i
                  className="fa fa-commenting fa-commenting-custome "
                  onClick={() => handleNewMessage(newMessageUserId)}
                />
              )}
            </i>
          </div>
          <div className="icons">
            <DateRangePicker
              onCallback={selectDateRange}
              initialSettings={initialSettings}
              onCancel={handleCancelDateRange}
            >
              <i className={dataFilterClass(dateRange)} aria-hidden="true"></i>
            </DateRangePicker>
          </div>
        </div>
      </div>

      <div className="filter-wrapper">
        <div className="row flex-column-reverse flex-md-row">
          <div className="col-md-5">
            <div className="filter-user-date-wrapper">
              <label inline="true" className="mr-2 d-block">
                Filter By Date:
              </label>
              <FormCheck
                className="radio-custom-filter"
                name="datefilter"
                checked={datefilter === "diet" ? true : false}
                inline="true"
                type="radio"
                value="diet"
                label="Diet Plan Date"
                onChange={onDateFilterChange}
              />
              <ul className="d-flex list-unstyled align-items-center mb-0">
                <li className="list-inline-item mt-2 mt-md-0">
                  <DatePicker
                    selected={dateFrom}
                    onChange={(date) => handleDateFilter(date, "dateFrom")}
                    selectsStart
                    startDate={dateFrom}
                    endDate={toFrom}
                    maxDate={new Date()}
                    placeholderText="Start Date"
                    dateFormat="do MMM yyyy"
                    className="mr-3 form-control"
                  />
                </li>
                <li className="list-inline-item mt-2 mt-md-0">
                  <DatePicker
                    selected={toFrom}
                    onChange={(date) => handleDateFilter(date, "dateTo")}
                    selectsEnd
                    startDate={dateFrom}
                    endDate={toFrom}
                    minDate={dateFrom}
                    disabled={!dateFrom}
                    maxDate={new Date()}
                    placeholderText="End Date"
                    dateFormat="do MMM yyyy"
                    className="mr-3 form-control"
                  />
                </li>
              </ul>
              <ul className="d-flex list-unstyled mt-3 align-items-center filter-user-btn-wrapper">
                <li className="">
                  <Button
                    variant="primary"
                    className="mr-2 "
                    onClick={() =>
                      handleDateRange(dateFrom, toFrom, filterValue)
                    }
                    disabled={!dateFrom || !toFrom}
                  >
                    {" "}
                    Get{" "}
                  </Button>
                </li>

                <li className="">
                  <Button
                    variant="outline-dark"
                    className="mr-2"
                    onClick={() => {
                      handleRefreshDateRange();
                      refreshDate();
                    }}
                    disabled={!dateFrom && !toFrom}
                  >
                    {" "}
                    <i className="fa fa-refresh" />
                  </Button>
                </li>
              </ul>
            </div>
          </div>
          <div className="col-md-7 text-left text-md-right mt-3 mt-md-0">
            <ul className="d-flex align-items-center mb-2 flex-wrap justify-content-end">
              <li className="list-inline-item text-left mt-2">
                <label className="">Diet Plan Assigned:</label>
                <Dropdown>
                  <Dropdown.Toggle
                    variant="outline-info"
                    id="dropdown-basic"
                    className="w-100"
                  >
                    {filterField == "isDieticianAssigned" && filterValue == true
                      ? "Assigned"
                      : filterField == "isDieticianAssigned" &&
                        filterValue == false
                      ? "Not Assigned"
                      : filterField == "isDieticianAssigned" &&
                        filterValue == null
                      ? "All"
                      : "Select Filter"}
                  </Dropdown.Toggle>
                  <Dropdown.Menu>
                    <Dropdown.Item
                      active={
                        filterField === "isDieticianAssigned" &&
                        filterValue === true
                      }
                      onClick={() => {
                        handleFilter("isDieticianAssigned", true);
                      }}
                    >
                      Assigned
                    </Dropdown.Item>
                    <Dropdown.Item
                      active={
                        filterField === "isDieticianAssigned" &&
                        filterValue === false
                      }
                      onClick={() => {
                        handleFilter("isDieticianAssigned", false);
                      }}
                    >
                      Not Assigned
                    </Dropdown.Item>
                    <Dropdown.Item
                      active={
                        filterField === "isDieticianAssigned" &&
                        filterValue === null
                      }
                      onClick={() => {
                        handleFilter("isDieticianAssigned", null);
                      }}
                    >
                      All
                    </Dropdown.Item>
                  </Dropdown.Menu>
                </Dropdown>
              </li>
            </ul>
            <div></div>
          </div>
        </div>
      </div>
    </>
  );
};

export default UsersListHeader;
