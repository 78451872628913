import React, { Component } from 'react';
import Header from '../template/Header';
import { getDieticianProfile, editDieticianProfile } from '../../config/service/adminService';
import { addCallTimeSlots } from '../../config/service/usersService';

import cogoToast from 'cogo-toast';
import { Loader } from '../Reuse';
import { callSlots } from '../constants/callSlots';
import Select from 'react-select';

class Profile extends Component {
    constructor(props) {
        super(props);

        this.state = {
            isEdit: false,
            loader: false,
            email: '',
            firstName: '',
            lastName: '',
            countryCode: '',
            mobileNo: '',
            profilePic: '',
            speciality: '',
            about: '',
            experience: '',
            validationError: '',
            sunday: [],
            monday: [],
            tuesday: [],
            wednesday: [],
            thursday: [],
            friday: [],
            saturday: [],
            selectedSundaySlots: [],
            selectedMondaySlots: [],
            selectedTuesdaySlots: [],
            selectedWednesdaySlots: [],
            selectedThursdaySlots: [],
            selectedFridaySlots: [],
            selectedSaturdaySlots: [],
            selectedTimeSlots: [],
            isSlotEditable: false,
            profilePicView: 'https://iccie.org/wp-content/uploads/2016/03/placeholder-man.jpg'
        }
    }

    handleEdit = () => this.setState({ isEdit: !this.state.isEdit, validationError: '' });

    handleChange = e => {
        this.setState({
            [e.target.name]: e.target.value
        }, () => this.setState({ errors: '' }))
    }


    handleFileChange = e => {
        let reader = new FileReader();
        let file = e.target.files[0];

        this.setState({ profilePic: e.target.files[0] })

        reader.addEventListener('load', () => {
            this.setState({ profilePicView: reader.result })
        }, false);
        if (file) { reader.readAsDataURL(file) }
    }


    getDieticianProfile = () => {
        this.setState({ loader: true })
        getDieticianProfile()
            .then(res => {
                if (res.data.statusCode == 1) {
                    let response = res.data.responseData.resut;
                    let selectedSundaySlots = [],
                        selectedMondaySlots = [],
                        selectedTuesdaySlots = [],
                        selectedWednesdaySlots = [],
                        selectedThursdaySlots = [],
                        selectedFridaySlots = [],
                        selectedSaturdaySlots = [];


                    if (response.callTimeSlots) {
                        response.callTimeSlots[1].map((slots) => {
                            selectedSundaySlots.push({ label: slots, value: slots })
                        })
                        response.callTimeSlots[2].map((slots) => {
                            selectedMondaySlots.push({ label: slots, value: slots })
                        })
                        response.callTimeSlots[3].map((slots) => {
                            selectedTuesdaySlots.push({ label: slots, value: slots })
                        })
                        response.callTimeSlots[4].map((slots) => {
                            selectedWednesdaySlots.push({ label: slots, value: slots })
                        })
                        response.callTimeSlots[5].map((slots) => {
                            selectedThursdaySlots.push({ label: slots, value: slots })
                        })
                        response.callTimeSlots[6].map((slots) => {
                            selectedFridaySlots.push({ label: slots, value: slots })
                        })
                        response.callTimeSlots[7].map((slots) => {
                            selectedSaturdaySlots.push({ label: slots, value: slots })
                        })
                    }



                    this.setState({
                        loader: false,
                        email: response.email,
                        firstName: response.firstName,
                        lastName: response.lastName,
                        countryCode: response.countryCode,
                        mobileNo: response.mobileNo,
                        profilePic: response.profilePic,
                        speciality: response.speciality,
                        about: response.about,
                        experience: response.experience,
                        callTimeSlots: response.callTimeSlots,
                        profilePicView: response.profilePic ? response.profilePic : this.state.profilePicView,
                        selectedSundaySlots,
                        selectedMondaySlots,
                        selectedTuesdaySlots,
                        selectedWednesdaySlots,
                        selectedThursdaySlots,
                        selectedFridaySlots,
                        selectedSaturdaySlots,
                    })
                } else if (res.data.statusCode == 0) {
                    this.setState({ loader: false })
                    cogoToast.error(res.data.error.errorMessage);
                }
            })
    }


    componentDidMount() {
        this.getDieticianProfile();
    }

    handleEditSubmit = (e) => {
        e.preventDefault();
        const { email, firstName, lastName, countryCode, mobileNo, profilePic, speciality, about, experience, callTimeSlots } = this.state;

        let paramsObj = new FormData();
        paramsObj.append('firstName', firstName)
        paramsObj.append('lastName', lastName)
        paramsObj.append('countryCode', countryCode)
        paramsObj.append('mobileNo', mobileNo)
        paramsObj.append('profilePic', profilePic)
        paramsObj.append('speciality', speciality)
        paramsObj.append('about', about)
        paramsObj.append('experience', experience)


        if (email == "" || firstName == "" || lastName == "" || countryCode == "" || mobileNo == "" || profilePic == "" || speciality == "" || about == "" || experience == "") {
            this.setState({ validationError: "Field cannot be empty" })
            cogoToast.error('Field cannot be empty!')
        } else {
            this.setState({ validationError: "" })
            this.editProfileApi(paramsObj);
        }
    }

    editProfileApi = (params) => {
        this.setState({ loader: true })
        editDieticianProfile(params)
            .then((res) => {
                if (res.data.statusCode == 1) {
                    let response = res.data.responseData;
                    cogoToast.success(response.message);
                    this.setState({ isEdit: false, loader: false })
                } else if (res.data.statusCode == 0) {
                    cogoToast.error(res.data.error.errorMessage);
                    this.setState({ loader: false })
                }
            })
    }

    handleSlotChange = (fieldName, value) => {
        switch (value) {
            case 1:
                this.setState({ selectedSundaySlots: fieldName })
                break;
            case 2:
                this.setState({ selectedMondaySlots: fieldName })
                break;
            case 3:
                this.setState({ selectedTuesdaySlots: fieldName })
                break;
            case 4:
                this.setState({ selectedWednesdaySlots: fieldName })
                break;
            case 5:
                this.setState({ selectedThursdaySlots: fieldName })
                break;
            case 6:
                this.setState({ selectedFridaySlots: fieldName })
                break;
            case 7:
                this.setState({ selectedSaturdaySlots: fieldName })
                break;
        }
    }

    slotEditable = () => {
        this.setState({ isSlotEditable: !this.state.isSlotEditable })
    }

    submitSlots = (e) => {
        e.preventDefault()
        const { selectedSundaySlots,
            selectedMondaySlots,
            selectedTuesdaySlots,
            selectedWednesdaySlots,
            selectedThursdaySlots,
            selectedFridaySlots,
            selectedSaturdaySlots } = this.state;

        let sunday = [],
            monday = [],
            tuesday = [],
            wednesday = [],
            thursday = [],
            friday = [],
            saturday = [];

        selectedSundaySlots.map((slots) => {
            sunday.push(slots.value)
        })

        selectedMondaySlots.map((slots) => {
            monday.push(slots.value)
        })
        selectedTuesdaySlots.map((slots) => {
            tuesday.push(slots.value)
        })
        selectedWednesdaySlots.map((slots) => {
            wednesday.push(slots.value)
        })
        selectedThursdaySlots.map((slots) => {
            thursday.push(slots.value)
        })
        selectedFridaySlots.map((slots) => {
            friday.push(slots.value)
        })
        selectedSaturdaySlots.map((slots) => {
            saturday.push(slots.value)
        })

        let paramsObj = {
            timeSlots: {
                1: sunday,
                2: monday,
                3: tuesday,
                4: wednesday,
                5: thursday,
                6: friday,
                7: saturday
            }
        }

        addCallTimeSlots(paramsObj)
            .then(res => {
                let response = res.data
                if (response.statusCode == 1) {
                    this.getDieticianProfile();
                    this.setState({ isSlotEditable: false })
                    cogoToast.success(response.responseData.message)
                } else if (response.statusCode == 0) {
                    cogoToast.error(response.error.errorMessage)
                }
            })
    }



    render() {
        let {
            isEdit,
            email,
            firstName,
            lastName,
            countryCode,
            mobileNo,
            profilePic,
            speciality,
            about,
            experience,
            profilePicView,
            validationError,
            loader,
            callTimeSlots,
            isSlotEditable,
            selectedSundaySlots,
            selectedMondaySlots,
            selectedTuesdaySlots,
            selectedWednesdaySlots,
            selectedThursdaySlots,
            selectedFridaySlots,
            selectedSaturdaySlots,
        } = this.state;
        return (
            <React.Fragment>
                <Header />

                {loader && <Loader />}

                <div className="page_wrapper">

                    <div className="sub_header">
                        <h3>Profile</h3>
                        <span className="separator"></span>
                    </div>


                    <div className="admin_profile">
                        <div className="row">
                            <div className="col-lg-3"></div>

                            <div className="col-lg-6">
                                <div className="card_wrapper cm_window_hieght pt-3">

                                    <div className="profile_header">

                                        {isEdit ? <i className="fa fa-camera-retro cm_edit_pic" onClick={(e) => this.profilePic.click()} /> : null}

                                        <input
                                            type="file"
                                            className="form-control img_browse"
                                            name="file_input"
                                            onChange={this.handleFileChange}
                                            ref={(ref) => this.profilePic = ref}
                                        />

                                        <img src={profilePicView} alt="image" />
                                        <h2>{firstName} {lastName}</h2>
                                        <p><i className="fa fa-envelope"></i> {email}</p>

                                        {
                                            isEdit ? null : <button className="btn btn-info" onClick={this.handleEdit}>Edit My Profile</button>
                                        }

                                    </div>
                                    {
                                        isEdit ?
                                            (
                                                <form onSubmit={this.handleEditSubmit} className="profile_edit_wrapper">

                                                    <h3 className="text-center text-danger">{validationError}</h3>

                                                    <div className="row">
                                                        <div className="col-sm-6 form-group">
                                                            <label>First Name</label>
                                                            <input
                                                                type="text"
                                                                className="form-control"
                                                                name="firstName"
                                                                value={firstName}
                                                                onChange={this.handleChange}
                                                            />
                                                        </div>
                                                        <div className="col-sm-6 form-group">
                                                            <label>Last Name</label>
                                                            <input
                                                                type="text"
                                                                className="form-control"
                                                                name="lastName"
                                                                value={lastName}
                                                                onChange={this.handleChange}
                                                            />
                                                        </div>
                                                    </div>

                                                    <div className="row">
                                                        <div className="col-sm-6 form-group">
                                                            <label>Country Code</label>
                                                            <input
                                                                type="number"
                                                                className="form-control"
                                                                name="countryCode"
                                                                maxLength="3"
                                                                value={countryCode}
                                                                onChange={this.handleChange}
                                                            />
                                                        </div>
                                                        <div className="col-sm-6 form-group">
                                                            <label>Mobile No</label>
                                                            <input
                                                                type="number"
                                                                className="form-control"
                                                                name="mobileNo"
                                                                value={mobileNo}
                                                                onChange={this.handleChange}
                                                            />
                                                        </div>
                                                    </div>

                                                    <div className="row">
                                                        <div className="col-sm-6 form-group">
                                                            <label>Speciality</label>
                                                            <input
                                                                type="text"
                                                                className="form-control"
                                                                name="speciality"
                                                                value={speciality}
                                                                onChange={this.handleChange}
                                                            />
                                                        </div>
                                                        <div className="col-sm-6 form-group">
                                                            <label>Experience</label>
                                                            <input
                                                                type="number"
                                                                className="form-control"
                                                                name="experience"
                                                                min="1"
                                                                step="1"
                                                                value={experience}
                                                                onChange={this.handleChange}
                                                            />
                                                        </div>
                                                    </div>


                                                    <div className="row">
                                                        <div className="col-sm-6 form-group">
                                                            <label>About</label>
                                                            <textarea
                                                                className="form-control cm_hide_resize"
                                                                name="about"
                                                                value={about}
                                                                onChange={this.handleChange}
                                                            ></textarea>
                                                        </div>
                                                        <div className="col-sm-6 form-group btn_group">
                                                            <button className="btn btn-dark" onClick={this.handleEdit}>Cancel</button>
                                                            <div></div>
                                                            <button type="submit" className="btn btn-primary">Save</button>
                                                        </div>
                                                    </div>

                                                </form>
                                            )
                                            :
                                            (
                                                <div className="profile_info">
                                                    <h3>Information</h3>

                                                    <div className="profile_info_row">
                                                        <span className="icon_wrap">
                                                            <i className="fa fa-phone"></i>
                                                            &nbsp;&nbsp; mobile Number.
                                                    </span>

                                                        <p> {"+" + countryCode} {mobileNo ? mobileNo : 'N/A'}</p>
                                                    </div>

                                                    <div className="profile_info_row">
                                                        <span className="icon_wrap">
                                                            <i className="fa fa-black-tie"></i>
                                                            &nbsp;&nbsp;  Speciality
                                                    </span>

                                                        <p>{speciality ? speciality : 'N/A'}</p>
                                                    </div>

                                                    <div className="profile_info_row">
                                                        <span className="icon_wrap">
                                                            <i className="fa fa-hourglass"></i>
                                                            &nbsp;&nbsp;  Experience
                                                    </span>

                                                        <p>{experience ? experience : 'N/A'} year</p>
                                                    </div>

                                                    <div className="profile_info_row">
                                                        <span className="icon_wrap">
                                                            <i className="fa fa-info-circle"></i>
                                                            &nbsp;&nbsp;  About
                                                    </span>

                                                        <p>{about ? about : 'N/A'}</p>
                                                    </div>
                                                </div>
                                            )
                                    }



                                    <div className="profile_info_row">
                                        <span className="icon_wrap">
                                            <i className="fa fa-calendar"></i>
                                            &nbsp;&nbsp;  Time Slots:
                                            &nbsp;&nbsp;

                                            {isSlotEditable ? <>
                                                <button className="btn btn-dark mr-3" onClick={this.slotEditable}>Cancel</button>
                                                <button onClick={this.submitSlots} className="btn btn-primary">Save</button>
                                            </> :

                                                <i className="fa fa-pencil-square-o cur_pointer" onClick={this.slotEditable} />}
                                        </span>

                                        {isSlotEditable ?

                                            <React.Fragment>
                                                Sunday
                                            <Select
                                                    isMulti
                                                    isSearchable
                                                    value={selectedSundaySlots}
                                                    options={callSlots}
                                                    onChange={val => this.handleSlotChange(val, 1)}
                                                />

                                                Monday
                                                  <Select
                                                    isMulti
                                                    isSearchable
                                                    value={selectedMondaySlots}
                                                    options={callSlots}
                                                    onChange={val => this.handleSlotChange(val, 2)}
                                                />

                                                Tuesday
                                                 <Select
                                                    isMulti
                                                    isSearchable
                                                    value={selectedTuesdaySlots}
                                                    options={callSlots}
                                                    onChange={val => this.handleSlotChange(val, 3)}
                                                />

                                                Wednesday
                                                <Select
                                                    isMulti
                                                    isSearchable
                                                    value={selectedWednesdaySlots}
                                                    options={callSlots}
                                                    onChange={val => this.handleSlotChange(val, 4)}
                                                />

                                                Thursday
                                                <Select
                                                    isMulti
                                                    isSearchable
                                                    value={selectedThursdaySlots}
                                                    options={callSlots}
                                                    onChange={val => this.handleSlotChange(val, 5)}
                                                />

                                                Friday
                                                <Select
                                                    isMulti
                                                    isSearchable
                                                    value={selectedFridaySlots}
                                                    options={callSlots}
                                                    onChange={val => this.handleSlotChange(val, 6)}
                                                />

                                                Saturday
                                                <Select
                                                    isMulti
                                                    isSearchable
                                                    value={selectedSaturdaySlots}
                                                    options={callSlots}
                                                    onChange={val => this.handleSlotChange(val, 7)}
                                                />
                                            </React.Fragment>
                                            :
                                            <React.Fragment>
                                                <p className="cm_time_slot"><b>Sunday:</b> {
                                                    callTimeSlots && callTimeSlots[1].length ?
                                                        callTimeSlots && callTimeSlots[1].map((slots, i) => <span key={i}>{slots} </span>) : "No Slot Selected"}</p>

                                                <p className="cm_time_slot"><b>Monday:</b> {
                                                    callTimeSlots && callTimeSlots[2].length ?
                                                        callTimeSlots && callTimeSlots[2].map((slots, i) => <span key={i}>{slots} </span>) : "No Slot Selected"}</p>

                                                <p className="cm_time_slot"><b>Tuesday:</b> {
                                                    callTimeSlots && callTimeSlots[3].length ?
                                                        callTimeSlots && callTimeSlots[3].map((slots, i) => <span key={i}>{slots} </span>) : "No Slot Selected"}</p>

                                                <p className="cm_time_slot"><b>Wednesday: </b>{
                                                    callTimeSlots && callTimeSlots[4].length ?
                                                        callTimeSlots && callTimeSlots[4].map((slots, i) => <span key={i}>{slots} </span>) : "No Slot Selected"}</p>

                                                <p className="cm_time_slot"><b>Thursday:</b> {
                                                    callTimeSlots && callTimeSlots[5].length ?
                                                        callTimeSlots && callTimeSlots[5].map((slots, i) => <span key={i}>{slots} </span>) : "No Slot Selected"}</p>

                                                <p className="cm_time_slot"><b>Friday:</b> {
                                                    callTimeSlots && callTimeSlots[6].length ?
                                                        callTimeSlots && callTimeSlots[6].map((slots, i) => <span key={i}>{slots} </span>) : "No Slot Selected"}</p>

                                                <p className="cm_time_slot"><b>Saturday:</b> {
                                                    callTimeSlots && callTimeSlots[7].length ?
                                                        callTimeSlots && callTimeSlots[7].map((slots, i) => <span key={i}>{slots} </span>) : "No Slot Selected"}</p>
                                            </React.Fragment>
                                        }</div>

                                </div>
                            </div>

                            <div className="col-lg-3"></div>
                        </div>
                    </div>


                </div>


            </React.Fragment>
        )
    }
}

export default Profile