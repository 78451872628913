import React from 'react';
import FoodPlaceholder from '../../assets/img/FoodPlaceholder.jpg';

const DietPlanCard = ({dietList, totalCalories, itrate}) => (
    <div className="diet-plan-header" key={itrate} >
        <h2 className="diet-plan-header">Option {itrate + 1} Total Calories- {totalCalories} cal</h2>
        <div className="diet-plan-card-wrapper">
            {dietList.map((foodItem, index) => (
                <div className="diet-plan-card" key={index}>
                    <div className="food-hero-image">
                        <img src={foodItem.foodImage ? foodItem.foodImage : FoodPlaceholder} alt="" className="image" />
                        <div className="food-name-calori">
                            <h4 className="food-name">{foodItem.foodName}</h4>
                            <h6 className="food-calori">{foodItem.calorieContent} cal / {`${foodItem.quantity}.0 ${foodItem.unit}`}</h6>
                        </div>
                    </div>
                    <div className="food-content">
                        <div className="food-aminities">
                            <table className="table table-bordered">
                                <thead>
                                </thead>
                                <tbody>
                                    <tr>
                                        <td><p className="food-label">Protein</p></td>
                                        <td><p className="food-label">Fat</p></td>
                                        <td><p className="food-label">Carbs</p></td>
                                        <td><p className="food-label">Fibre</p></td>
                                    </tr>
                                    <tr>
                                        <td><p className="food-value"> {foodItem.proteinContent} gm</p></td>
                                        <td><p className="food-value">{foodItem.fatContent} gm</p></td>
                                        <td><p className="food-value">{foodItem.carbContent} gm</p></td>
                                        <td><p className="food-value">{foodItem.fibreContent} gm</p></td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            ))}
        </div>
    </div>
)

export default DietPlanCard;