import React, { Component } from 'react';
import dateFormat from 'dateformat';
import { connect } from 'react-redux'
import { getAllDietHistoryAction, getDietPlanViaAdminAction } from '../store/actions/dietHistory'

import DietHistoryHeader from '../components/dietHistory/DietHistoryHeader';
import DietHistoryBody from '../components/dietHistory/DietHistoryBody';
import Header from '../components/template/Header';

import { selectHistoryAction } from '../store/actions/dietHistory'
import { Loader } from '../components/Reuse';


class DietHistory extends Component {
    constructor(props) {
        super(props);
        this.state = {}
    }

    selectHistoryHandler = (dietPlanHistoryId) => {
        const { selectHistory } = this.props
        const { match: { params: { id: dietOfUserId } } } = this.props
        const params = {
            params: {
                dietOfUserId,
                dietPlanHistoryId
            }
        }
        selectHistory(params)
    }

    componentDidMount() {
        const { getDietHistory, selectHistory, selectCurrentDiet } = this.props
        const { match: { params: { id: dietOfUserId } } } = this.props
        const params = {
            params: {
                dietOfUserId
            }
        }
        getDietHistory(params)
        let param = {
            params: { dietOfUserId},
          };
        selectCurrentDiet(param)
        
    }

    getCurrentDiet = ()=>{
        const {selectCurrentDiet} = this.props
        const { match: { params: { id: dietOfUserId } } } = this.props
        let params = {
            params: { dietOfUserId},
          };
        selectCurrentDiet(params)
    }

    makeActive = (currenId) => {
        const { dietHistoryState: { selectedHistory, selectCurrentDiet } } = this.props
        return (selectedHistory && selectedHistory._id === currenId) ? "widget_history active" : "widget_history "
    }

    render() {
        const { loader, dietHistoryState: { dietHistory, currentDietPlan, selectedHistory } } = this.props
        const { match: { params: { id: dietOfUserId } } } = this.props

        return (
            <React.Fragment>
                {loader && <Loader />}
                <Header />

                <div className="page_wrapper">
                    <DietHistoryHeader
                        dietOfUserId={dietOfUserId}
                    />

                    <div className="users_list_wrapper">
                        {
                            dietHistory && dietHistory.length > 0
                                ? (

                                    <div className="row">
                                        <div className="col-xl-3 col-lg-4">
                                            <div className="card_wrapper cm_window_hieght">
                                                <div className="user_card_detail">
                                                    <h3>History</h3>
                                                    <div className="widget_history_heading">
                                                        <span className="widget_heading">Diet Plan</span>
                                                        <span className="widget_heading">Created On </span>
                                                    </div>
                                                    {currentDietPlan.map((history) => {
                                                        return (
                                                            <div className={this.makeActive(history._id)} onClick={()=>this.getCurrentDiet(history._id)}>
                                                                <span className="widget_label">{history.perDayCalories} calorie/day</span>
                                                                <span className="widget_data">{dateFormat(history.created, "d mmmm yyyy")}</span>
                                                            </div>
                                                        );
                                                    })}
                                                    {
                                                        dietHistory.map((history) => {
                                                            return <div className={this.makeActive(history.value)} onClick={() => this.selectHistoryHandler(history.value)}>
                                                                <span className="widget_label">{history.label} calorie/day</span>
                                                                <span className="widget_data">{dateFormat(history.created, "d mmmm yyyy")}</span>
                                                            </div>
                                                        })
                                                    }
                                                </div>
                                            </div>
                                        </div>

                                        <div className="col-xl-9 col-lg-8">
                                            <div className="card_wrapper cm_window_hieght pt-3">
                                                <div className="row mb-4">
                                                    <div className="col">
                                                        <h2>Diet Plan</h2>
                                                        <h6>Total calorie target</h6>
                                                    </div>
                                                </div>
                                                {
                                                    selectedHistory && <DietHistoryBody dietPlanDetails={selectedHistory} />
                                                }
                                            </div>
                                        </div>
                                    </div>

                                )
                                :
                                <div className="row">
                                    <div className="card_wrapper cm_window_hieght">
                                        <h6>No History data</h6>
                                    </div>
                                </div>
                        }
                    </div>
                </div>
            </React.Fragment>
        )
    }
}

const mapStateToProps = state => ({
    dietHistoryState: state.dietHistoryState
});

const mapDispatchToProps = dispatch => ({
    getDietHistory: (params) => dispatch(getAllDietHistoryAction(params)),
    selectHistory: (params) => dispatch(selectHistoryAction(params)),
    selectCurrentDiet: (params) => dispatch(getDietPlanViaAdminAction(params))
})
export default connect(mapStateToProps,
    mapDispatchToProps,
)(DietHistory);
