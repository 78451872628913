import React, { useState } from "react";
import { TextPopup } from "./TextPopup";

export const ReadMore = ({ children }) => {

    const [popup, setpUp] = useState(false);
    const text = children;
    let textLength = text.length > 180;
    const toggleReadMore = ()=>{
        setpUp(!popup);
    }
    return (
        <>
            {
                textLength ? text.slice(0, 180) : text
            }
            {
                textLength ?

                    <span onClick={toggleReadMore} className="read-me-wrapper">
                        ...read more
                    </span> :
                    ""
            }
            {
                popup ?
                    <TextPopup 
                        text={text}
                        toggleReadMore={toggleReadMore}
                        state={popup}
                    /> :
                    ""
            }
        </>
    );
};