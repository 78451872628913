import React from 'react';
import DatePicker from "react-datepicker";

const addFollowup = ({ selectDate, selectedDate, validationError, notes, handleChange }) => {
    return (
        <div>
            {validationError && <div className="alert alert-danger" role="alert">{validationError}</div>}
            <div className="form-group">
            <label>Select Date:</label>
                <div>
                    <DatePicker
                        startDate= {new Date()}
                        minDate= {new Date()}
                        className="form-control"
                        onChange={selectDate}
                        showTimeSelect
                        inline
                        showYearDropdown
                        selected={selectedDate}
                    />
                </div>
            </div>

            <div className="form-group">
                <label>Note:</label>
                <textarea className="form-control" rows="3" onChange={handleChange} value={notes}></textarea>
            </div>
        </div>
    );
};

export default addFollowup;