import React, { Component } from 'react';
import { Modal } from 'react-bootstrap';
import { addFitnessPlanTempelate } from '../../config/service/usersService';
import cogoToast from 'cogo-toast';


class SaveFitnessTempelate extends Component {

    constructor(props) {
        super(props);
        this.state = {
            tempelateTitle:'',
            fitnessExercise:[],
            fitnessPlanId:'',
        }
    }

    componentWillReceiveProps() {
        const {fitnessExercise,fitnessPlanId}=this.props;        
        this.setState({fitnessExercise,fitnessPlanId})
    }


    handleChange = (e) => {
        const { name, value } = e.target;
        this.setState({ [name]: value })
    }

    submitFitnessPlanTempelate=(e)=>{
        e.preventDefault();
        const {fitnessExercise,tempelateTitle,fitnessPlanId }=this.state;;
        let paramsObj={
            fitnessExercises:fitnessExercise,tempelateTitle,fitnessPlanId
        }
        if (!tempelateTitle) {
            this.setState({ validationError: "Enter tempelate title" })
        } else {
            this.setState({ validationError: "" })
            this.saveFitnessPlanTempelateApi(paramsObj)
        }
    }

    saveFitnessPlanTempelateApi = (params) => {
        addFitnessPlanTempelate(params)
            .then((res) => {
                let response = res.data
                if (response.statusCode == 1) {
                    cogoToast.success(response.responseData.message)
                    this.props.handleClose();
                    this.props.getUserFitnessPlan();
                } else if (response.statusCode == 0) {
                    this.setState({ validationError: response.error.errorMessage })
                }
            })
    }


    handleClose = () => {
        this.props.handleClose();
        this.setState({ validationError: '', tempelateTitle: '' })
    }

    render() {
        let { saveFitnessTempelatePopup } = this.props;
        let { validationError } = this.state
        return (
            <Modal
                show={saveFitnessTempelatePopup}
                onHide={this.handleClose}
                size="md"
                aria-labelledby="contained-modal-title-vcenter"
                centered
            >
                <Modal.Header>
                    <Modal.Title>Give a title to this template.</Modal.Title>
                </Modal.Header>

                <Modal.Body className="text-center">
                    <h4 className="text-center text-danger">{validationError}</h4>

                    <div className=" tmp_title">
                        <input type="text" className="form-control" id="tempelateTitle" name="tempelateTitle" onChange={this.handleChange} placeholder="Tempelate title (Ex: Plan for BMR 15-20)" />
                    </div>
                    <button className="btn btn-danger cm_danger" onClick={this.handleClose}>Cancel</button> &nbsp; &nbsp;
                <button className="btn btn-success" onClick={this.submitFitnessPlanTempelate} >Save</button>

                </Modal.Body>
            </Modal>
        )
    }
}

export default SaveFitnessTempelate;