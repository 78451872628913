var perDayCalories = [
    { label: "1200 Calories per day", value:1200 },
    { label: "1250 Calories per day", value:1250 },
    { label: "1300 Calories per day", value:1300 },
    { label: "1350 Calories per day", value:1350 },
    { label: "1400 Calories per day", value:1400 },
    { label: "1450 Calories per day", value:1450 },
    { label: "1500 Calories per day", value:1500 },
    { label: "1550 Calories per day", value:1550 },
    { label: "1600 Calories per day", value:1600 },
    { label: "1650 Calories per day", value:1650 },
    { label: "1700 Calories per day", value:1700 },
    { label: "1750 Calories per day", value:1750 },
    { label: "1800 Calories per day", value:1800 },
    { label: "1850 Calories per day", value:1850 },
    { label: "1900 Calories per day", value:1900 },
    { label: "1950 Calories per day", value:1950 },
    { label: "2000 Calories per day", value:2000 },
    { label: "2050 Calories per day", value:2050 },
    { label: "2100 Calories per day", value:2100 },
    { label: "2150 Calories per day", value:2150 },
    { label: "2200 Calories per day", value:2200 },
    { label: "2250 Calories per day", value:2250 },
    { label: "2300 Calories per day", value:2300 },
    { label: "2350 Calories per day", value:2350 },
    { label: "2400 Calories per day", value:2400 },
    { label: "2450 Calories per day", value:2450 },
    { label: "2500 Calories per day", value:2500 }]


    module.exports={
        perDayCalories
    }