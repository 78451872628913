import React, { Component } from 'react';
import Popup from "reactjs-popup"
import { forgotPassword } from '../../config/service/changePasswordService';
import { ReactComponent as Logo } from '../../assets/img/cult-logo.svg';
import { withRouter } from "react-router";

class ForgetPassword extends Component {
    constructor(props) {
        super(props);

        this.state = {
            email: '',
            mobileNo: '',
            showScssesMsg: false,
            errorMsg: '',
            responseMessage: '',
            userName: '',
            isEmail: false
        }
    }

    validateEmail = (email) => {
        return String(email)
            .toLowerCase()
            .match(
                /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
            );
    };

    validatPhoneNo = (phone) => {
        return String(phone)
            .match(
                /^[6-9]\d{9}$/
            );
    }

    handleValidetion() {
        const {userName} = this.state
        let validate = true;

        if (userName === '' || userName === undefined) {
            console.log('validate empty', userName)
            validate = false;
            this.setState({
                userNameErrorMsg: 'Please enter email or phone number'
            })
        } else {
            if (this.validateEmail(userName)) {
                console.log('validate email', userName)
                // this.userExist()
                this.setState({
                    userNameErrorMsg: '',
                })
            } else if (this.validatPhoneNo(userName)) {
                console.log('validate phone', userName)
                // this.userExist()
                this.setState({
                    userNameErrorMsg: '',
                })
            } else {
                validate = false;
                console.log('else')
                this.setState({
                    userNameErrorMsg: 'Please Enter a valid email or phone Number',
                })
            }
        }
        return validate
    }

    handleUser = (event) => {
        this.setState({
            userName: event.target.value
        })

        if (this.validateEmail(event.target.value)) {
            this.setState({
                email: event.target.value,
                errorMsg: '',
                isEmail: true
            })
        } else if (this.validatPhoneNo(event.target.value)) {
            this.setState({
                mobileNo: event.target.value,
                errorMsg: '',
                isEmail: false
            })
        } else {
            console.log('email or phone number not valid');
        }

    }

    // handleChange(event) {
    //     const re = /^[0-9\b]+$/;
    //     if (event.target.name === 'mobileNo' || re.test(event.target.value)) {
    //         this.setState({
    //             mobileNo: event.target.value.replace(/\D/g, ""),
    //             errorMsg: ''
    //         })
    //     } else {
    //         this.setState({
    //             email: event.target.value,
    //             errorMsg: ''
    //         })
    //     }

    // }

    // handleChange = event => this.setState({email: event.target.value, errorMsg: ''});

    handleSubmit = event => {
        const {email, mobileNo, isEmail, userName} = this.state
        event.preventDefault();
        if (this.handleValidetion()) {
            let obj;
            if (isEmail) {
                obj = {
                    email: userName,
                    role: 2
                }
            } else {
                obj = {
                    mobileNo: userName,
                    role: 2
                }
            }

            forgotPassword(obj)
                .then(res => {
                    if (res.data.statusCode == 1) {
                        // this.setState({ showScssesMsg: true, responseMessage: res.data.responseData.message });
                        // this.openModal();
                        localStorage.setItem('otpToken', res.data.responseData.accessToken);
                        localStorage.setItem('forgotPassword', true);
                        this.props.history.push('/verify-otp')

                    } else if(res.data.error.errorCode == 8 || res.data.error.errorCode == 10 || res.data.error.errorCode == 7 || res.data.error.errorCode == 113) {
                        this.setState({ errors: true, Loader: false, _statusCode: res.data.error.errorMessage })
                    }
                })
        }else{
            this.setState({ errors: true, Loader: false })
        }
    }


    openModal = () => this.setState({ showScssesMsg: true });

    closeModal = () => {
        this.setState({ showScssesMsg: false });
        this.props.history.push('/login/');
    }

    render() {
        const { errorMsg, userName } = this.state
        if (localStorage.getItem('accessToken')) {
            this.props.history.push('/dashboard')
        }
        return (
            <div className="login-wrapper">
                <div className="container h-100">
                    <div className="row h-100">
                        <div className="col-md-6 m-auto">
                            <div className="card">
                                <div className="card-body">
                                    <div className="card-title">
                                        <Logo />
                                        <h3>Forgot Password</h3>
                                    </div>
                                    <form onSubmit={this.handleSubmit}>
                                        <div className="form-group">
                                            <input
                                                className={`form-control mb-4 ${errorMsg ? 'is-invalid' : ''}`}
                                                type="text"
                                                id='userName'
                                                name="userName"
                                                placeholder="Phone or email"
                                                onChange={this.handleUser}
                                                value={userName}
                                                autoComplete="off"
                                            />
                                            {errorMsg ? <div className="cm_alert_danger">{errorMsg}</div> : null}
                                        </div>

                                        <div className="form-group">
                                            <div className="row">
                                                <div className="col"><button className="btn btn-primary">Send</button></div>
                                            </div>
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>



                <Popup
                    open={this.state.showScssesMsg}
                    closeOnDocumentClick
                    onClose={this.closeModal}
                >
                    <div className="cm_modal">
                        <span className="cm_modal_close" onClick={this.closeModal}>&times;</span>
                        <h3 className="text-center"> {this.state.email}</h3>
                        <p className="text-center">{this.state.responseMessage}</p>
                    </div>
                </Popup>


            </div>
        );
    }
}

export default withRouter(ForgetPassword);