export const nameRegex = (param) => {
    let pattern = /^[a-z ,.'-]+$/i
    let validated = new RegExp(pattern).test(param)
    let message = validated ? '' : 'Enter valid name, without any number or special character.'
    return { success: validated, message }
}


export const mobileRegex = (param) => {
    let pattern = /^([\+0]91)?\-?[5-9]{1}[0-9]{9}$/g
    let validated = new RegExp(pattern).test(param)
    let message = validated ? '' : 'Enter Valid Mobile No.'
    return { success: validated, message }
}


export const emailRegex = (param) => {
    let pattern = /[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?/g
    let validated = new RegExp(pattern).test(param)
    let message = validated ? '' : 'Enter Valid Email Address.'
    return { success: validated, message }
}

