import React, { Component } from 'react';
import { Modal } from 'react-bootstrap';
import DatePicker from "react-datepicker";
import Select from 'react-select';
import { editFitnessplan } from '../../config/service/usersService';
import cogoToast from 'cogo-toast';
import { addDays } from 'date-fns';

const daysOption = [{ label: "Sunday", value: 1 }, { label: "Monday", value: 2 }, { label: "Tuesday", value: 3 }, { label: "Wednesday", value: 4 }, { label: "Thursday", value: 5 }, { label: "Friday", value: 6 }, { label: "Saturday", value: 7 }];

class EditFitnessPlan extends Component {

    constructor(props) {
        super(props);
        this.state = {
            fitnessExerciseListing: [],
            validFrom: new Date(new Date().setHours(0, 0, 0, 0)),
            validTo: addDays(new Date(new Date().setHours(23, 59, 59, 0)), 1),
            fitnessExercise: [""],
            validationError: '',
            fitnessPlanId: '',
            recommendedTime: 0,
            recommendedCalorie: 0,
            recommendedDistance: 0,
            editSubmitDisable: true
        }
    }


    componentWillReceiveProps() {
        let { fitnessExercise, daysOption, fitnessPlanId, fitnessPlanDetails, recommendedTime,
            recommendedCalorie, recommendedDistance } = this.props;
        var fitnessExerciseListing = [];

        if (fitnessExercise) {
            fitnessExercise.map((exercise, index) => {
                let days = []

                exercise.days.map(day => {
                    daysOption.filter((days => days.value === day)).map(filteredDays => {
                        days.push(filteredDays);
                    })
                })

                fitnessExerciseListing.push({
                    label: exercise.exerciseTitle,
                    value: exercise._id,
                    description: exercise.description,
                    exerciseImage: exercise.exerciseImage,
                    repetitions: exercise.repetitions,
                    sets: exercise.sets,
                    time: exercise.time,
                    selectedDays: days,
                    days: exercise.days,
                    exerciseCategory: { label: exercise.exerciseCategory.categoryTitle },
                    exerciseId: exercise._id,
                    exerciseCategoryId: exercise.exerciseCategory._id,
                    exerciseIndex: index
                });
            })
        }
        this.setState({
            fitnessExerciseListing,
            fitnessPlanId,
            recommendedTime,
            recommendedCalorie,
            recommendedDistance,
        })
    }

    validFromChange = date => this.setState({ validFrom: new Date(new Date(date).setHours(0, 0, 0, 0)), editSubmitDisable: false });
    validToChange = date => this.setState({ validTo: new Date(new Date(date).setHours(23, 59, 59, 0)), editSubmitDisable: false });
    handleChange = (e) => this.setState({ [e.target.name]: e.target.value, editSubmitDisable: false });


    onExerciseCategorySelect = (data, index) => {
        const fitnessExerciseListing = Array.from(this.state.fitnessExerciseListing);
        fitnessExerciseListing[index] = {
            ...this.state.fitnessExerciseListing[index],
            exerciseCategoryId: data.value,
            exerciseCategory: { label: data.label }
        };
        this.setState({ fitnessExerciseListing, editSubmitDisable: false });
    }

    onExerciseSelect = (data, index) => {
        const fitnessExerciseListing = Array.from(this.state.fitnessExerciseListing);
        fitnessExerciseListing[index] = { ...this.state.fitnessExerciseListing[index], label: data.label, exerciseId: data.value, exerciseIndex: index };
        this.setState({ fitnessExerciseListing, editSubmitDisable: false });
    }


    handleFitnessPlanDetails = (event, data, index) => {
        this.setState({ editSubmitDisable: false })
        const fitnessExerciseListing = Array.from(this.state.fitnessExerciseListing);
        switch (event) {
            case "repetitions":
                fitnessExerciseListing[index] = { ...this.state.fitnessExerciseListing[index], repetitions: data.target.value };
                this.setState({ fitnessExerciseListing })
                break;

            case "sets":
                fitnessExerciseListing[index] = { ...this.state.fitnessExerciseListing[index], sets: data.target.value };
                this.setState({ fitnessExerciseListing })
                break;

            case "time":
                // var hms = data.target.value;   // your input string
                // var a = hms.split(':'); // split it at the colons
                // // minutes are worth 60 seconds. Hours are worth 60 minutes.
                // var time = ((a[0] ? a[0] : 0) * 3600) + ((a[1] ? a[1] : 0) * 60) + (a[2] ? a[2]*1 : 0);

                // fitnessExerciseListing[index] = { ...this.state.fitnessExerciseListing[index], time };
                // this.setState({ fitnessExerciseListing })
                fitnessExerciseListing[index] = { ...this.state.fitnessExerciseListing[index], time: data.target.value };
                this.setState({ fitnessExerciseListing })
                break;

            case "description":
                fitnessExerciseListing[index] = { ...this.state.fitnessExerciseListing[index], description: data.target.value };
                this.setState({ fitnessExerciseListing })
                break;

            case "daysSelected":
                let days = [];
                data && data.map((day, index) => (
                    days.push(day.value)
                ))
                fitnessExerciseListing[index] = { ...this.state.fitnessExerciseListing[index], days, selectedDays: data };
                this.setState({ fitnessExerciseListing })
                break;
            default:
                break;
        }
    }

    addMoreExercise = (e) => {
        const { fitnessExerciseListing } = this.state;
        fitnessExerciseListing.push("");
        this.setState({ fitnessExerciseListing })
    }

    removeSelectedExs = (index) => {
        let { fitnessExerciseListing } = this.state;
        fitnessExerciseListing.splice(index, 1);
        this.setState({ fitnessExerciseListing, editSubmitDisable: false })
    }

    submitEditFitnessPlan = (e) => {
        e.preventDefault();
        const { fitnessExerciseListing, fitnessPlanId, recommendedCalorie, recommendedTime, recommendedDistance } = this.state;

        let paramsObj = {
            fitnessPlanId,
            fitnessExercises: fitnessExerciseListing,
            recommendedCalorie,
            recommendedTime,
            recommendedDistance
        }

        if (!fitnessExerciseListing.length) {
            this.setState({ validationError: "Select atleast one exercise" })
        } else if (this.handleValidation()) {
            this.setState({ validationError: "Please fill mandatory fields marked as '*'" })
        } else if (this.checkDuplicacy()) {
            this.setState({ validationError: "Same exercise can't be added twice" })
        } else {
            this.setState({ validationError: "" })
            this.editFitnessPlanApi(paramsObj);
        }
    }

    checkDuplicacy = () => {
        const { fitnessExerciseListing } = this.state;
        var valueArr = fitnessExerciseListing.map(function (item) { return item.exerciseId });
        var isDuplicate = valueArr.some(function (item, idx) {
            return valueArr.indexOf(item) != idx
        });
        return isDuplicate;
    }

    handleValidation = () => {
        const { fitnessExerciseListing } = this.state;
        let notValid = false
        fitnessExerciseListing.map((exerciseObj) => {
            let { exerciseId, exerciseCategoryId, days } = exerciseObj;
            if (!exerciseId || !exerciseCategoryId) {
                notValid = true;
            } else if (!days) {
                notValid = true;
            } else if (!days.length) {
                notValid = true;
            }
        })
        return notValid;
    }

    editFitnessPlanApi = (params) => {
        editFitnessplan(params)
            .then(res => {
                let response = res.data
                if (response.statusCode == 1) {
                    this.props.getUserFitnessPlan();
                    this.handleClose();
                    cogoToast.success(response.responseData.message)
                } else if (response.statusCode == 0) {
                    this.setState({ validationError: response.error.errorMessage })
                }
            })
    }

    handleClose = () => {
        this.setState({ editSubmitDisable: true })
        const { handleClose, } = this.props;
        handleClose();
    }

    convertToMinSec = (time) => {
        time = time ? parseInt(time) :0
        let stringTime = new Date(time * 1000).toISOString().substr(11, 8);
        return stringTime
    }


    render() {
        let { editFitnessPlanPopUp, exerciseList, exerciseCategoryList, daysOption } = this.props;
        let { fitnessExerciseListing, validationError, editSubmitDisable, recommendedTime,
            recommendedCalorie, recommendedDistance, } = this.state

        return (
            <Modal
                show={editFitnessPlanPopUp}
                onHide={this.handleClose}
                size="lg"
                dialogClassName="cm_big_modal"
                aria-labelledby="contained-modal-title-vcenter"
                centered
            >
                <Modal.Header closeButton>
                    <Modal.Title>Update Exercise Plan</Modal.Title>
                </Modal.Header>

                <Modal.Body>
                    <form onSubmit={this.submitEditFitnessPlan}>
                        <div className="form_group_wrapper">
                            <h4>Fitness Exercises</h4>

                            <div className="row mb-5">
                                <div className="form-group col">
                                    <label>Recommended caloric output (Cal):</label>
                                    <input type="number" className="form-control" name="recommendedCalorie" min="0" onChange={this.handleChange} value={recommendedCalorie} />
                                </div>

                                <div className="form-group col">
                                    <label>Recommended workout time (mins):</label>
                                    <input type="number" className="form-control" name="recommendedTime" min="0" onChange={this.handleChange} value={recommendedTime} />
                                </div>

                                <div className="form-group col">
                                    <label>Recommended distance (Kms):</label>
                                    <input type="number" className="form-control" name="recommendedDistance" min="0" onChange={this.handleChange} value={recommendedDistance} />
                                </div>
                            </div>

                            {fitnessExerciseListing.map((data, index) => (
                                <div key={index}>

                                    <h4 className="cm_pos_rel">
                                        <b>Exercise {index + 1}</b>
                                        <span className="btn btn-danger" onClick={(val) => this.removeSelectedExs(index)} ><i className="fa fa-trash" /></span>
                                    </h4>

                                    <div className="row">
                                        <div className="form-group col">
                                            <label>Exercise Name*</label>
                                            <Select
                                                isSearchable
                                                value={data}
                                                options={exerciseList}
                                                onChange={(val) => this.onExerciseSelect(val, index)}
                                            />
                                        </div>
                                        <div className="form-group col">
                                            <label>Exercise Category *</label>
                                            <Select
                                                isSearchable
                                                value={data.exerciseCategory}
                                                options={exerciseCategoryList}
                                                onChange={(val) => this.onExerciseCategorySelect(val, index)}
                                            />
                                        </div>
                                        <div className="form-group col">
                                            <label>Repetitions</label>
                                            <input type="number" className="form-control"
                                                value={data.repetitions}
                                                name="repetitions" onChange={(val) => this.handleFitnessPlanDetails("repetitions", val, index)} min="1" max="50" />

                                        </div>
                                    </div>
                                    <div className="row">

                                        <div className="form-group col">
                                            <label>Sets</label>
                                            <input type="number"
                                                value={data.sets}
                                                className="form-control" name="sets" onChange={(val) => this.handleFitnessPlanDetails("sets", val, index)} min="1" max="50" />
                                        </div>

                                        <div className="form-group col">
                                            <label>Time</label>
                                            <input type="number"
                                                value={data.time}
                                                className="form-control" name="time" onChange={(val) => this.handleFitnessPlanDetails("time", val, index)} min="1" max="180" />

                                            {/* <input type="time"
                                                value={this.convertToMinSec(data.time)}
                                                className="form-control" name="time"
                                                onChange={(val) => this.handleFitnessPlanDetails("time", val, index)}
                                                step="1" min="00:00:00" /> */}
                                        </div>
                                        <div className="form-group col">
                                            <label>Description</label>
                                            <input type="text"
                                                value={data.description}
                                                className="form-control" name="description" onChange={(val) => this.handleFitnessPlanDetails("description", val, index)} />
                                        </div>
                                        <div className="form-group col">
                                            <label>Days *</label>
                                            <Select
                                                isSearchable
                                                isMulti
                                                value={data.selectedDays}
                                                options={daysOption}
                                                onChange={(val) => this.handleFitnessPlanDetails("daysSelected", val, index)}
                                            />
                                        </div>
                                    </div>
                                </div>
                            ))}

                            <div className="mb-5" onClick={this.addMoreExercise}>
                                <i className="fa fa-plus-square cur_pointer">
                                    Add more exercises</i>
                            </div>
                        </div>

                        <div className="form_group_wrapper">
                            <h4 className="text-center text-danger">{validationError}</h4>
                            <button className="btn btn-success" disabled={editSubmitDisable} type="submit">Update</button>
                        </div>
                    </form>
                </Modal.Body>
            </Modal>
        )
    }
}

export default EditFitnessPlan;