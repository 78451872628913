var callSlots = [
    { label: "09:00-09:15", value:"09:00-09:15" },
    { label: "09:16-09:30", value:"09:16-09:30" },
    { label: "09:31-09:45", value:"09:31-09:45" },
    { label: "09:46-10:00", value:"09:46-10:00" },
    { label: "10:01-10:15", value:"10:01-10:15" },
    { label: "10:16-10:30", value:"10:16-10:30" },
    { label: "10:31-10:45", value:"10:31-10:45" },
    { label: "10:46-11:00", value:"10:46-11:00" },
    { label: "11:01-11:15", value:"11:01-11:15" },
    { label: "11:16-11:30", value:"11:16-11:30" },
    { label: "11:31-11:45", value:"11:31-11:45" },
    { label: "11:46-12:00", value:"11:46-12:00" },
    { label: "12:01-12:15", value:"12:01-12:15" },
    { label: "12:16-12:30", value:"12:16-12:30" },
    { label: "12:31-12:45", value:"12:31-12:45" },
    { label: "12:46-13:00", value:"12:46-13:00" },
    { label: "13:01-13:15", value:"13:01-13:15" },
    { label: "13:16-13:30", value:"13:16-13:30" },
    { label: "13:31-13:45", value:"13:31-13:45" },
    { label: "13:46-14:00", value:"13:46-14:00" },
    { label: "14:01-14:15", value:"14:01-14:15" },
    { label: "14:16-14:30", value:"14:16-14:30" },
    { label: "14:31-14:45", value:"14:31-14:45" },
    { label: "14:46-15:00", value:"14:46-15:00" },
    { label: "15:01-15:15", value:"15:01-15:15" },
    { label: "15:16-15:30", value:"15:16-15:30" },
    { label: "15:31-15:45", value:"15:31-15:45" },
    { label: "15:46-16:00", value:"15:46-16:00" },
    { label: "16:01-16:15", value:"16:01-16:15" },
    { label: "16:16-16:30", value:"16:16-16:30" },
    { label: "16:31-16:45", value:"16:31-16:45" },
    { label: "16:46-17:00", value:"16:46-17:00" },
    { label: "17:01-17:15", value:"17:01-17:15" },
    { label: "17:16-17:30", value:"17:16-17:30" },
    { label: "17:31-17:45", value:"17:31-17:45" },
    { label: "17:46-18:00", value:"17:46-18:00" },
    { label: "18:01-18:15", value:"18:01-18:15" },
    { label: "18:16-18:30", value:"18:16-18:30" },
    { label: "18:31-18:45", value:"18:31-18:45" },
    { label: "18:46-19:00", value:"18:46-19:00" }]
    module.exports={
        callSlots
    }