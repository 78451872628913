import React, { Component } from 'react';
import DatePicker from "react-datepicker";
import { userDailyDiet } from '../../config/service/usersService';
import { Loader } from '../Reuse';
import cogoToast from 'cogo-toast';
import FoodPlaceholder from '../../assets/img/FoodPlaceholder.jpg';

const CardWrapper = props => <div className="col mb-5">{props.children}</div>;

class DietTrack extends Component {
    constructor(props) {
        super(props);
        this.state = {
            selectedDate: new Date().setHours(0, 0, 0, 0),
            loader: false,
            morningSnacksList: [],
            breakfastList: [],
            lunchList: [],
            dinnerList: [],
            midMorningDietList: [],
            midEveningDietList: [],
            bedTimeDietList: [],
            dietTargets: {},
        }
    }

    componentDidMount() {
        this.getUserDailyDiet();
    }

    getUserDailyDiet = (params) => {
        const { userId } = this.props
        const { selectedDate } = this.state;       

        let paramsObj = {
            params: {
                dietOfUserId: userId,
                currentDate: new Date(selectedDate).setHours(0,0,0,0),
            }
        }
        userDailyDiet(paramsObj)
            .then((res) => {
                let response = res.data
                if (response.statusCode == 1) {                    
                    const { morningSnacksEaten, breakfastEaten, midMorningDietEaten, lunchEaten, midEveningDietEaten, dinnerEaten, bedTimeDietEaten, dietTargets } = response.responseData.result;

                    this.setState({
                        morningSnacksList: morningSnacksEaten.length ? morningSnacksEaten[0].morningSnacksEatenObject : [],
                        breakfastList: breakfastEaten.length ? breakfastEaten[0].breakfastEatenObject : [],
                        midMorningDietList: midMorningDietEaten.length ? midMorningDietEaten[0].midMorningDietEatenObject : [],
                        lunchList: lunchEaten.length ? lunchEaten[0].lunchEatenObject : [],
                        midEveningDietList: midEveningDietEaten.length ? midEveningDietEaten[0].midEveningDietEatenObject : [],
                        dinnerList: dinnerEaten.length ? dinnerEaten[0].dinnerEatenObject : [],
                        bedTimeDietList: bedTimeDietEaten.length ? bedTimeDietEaten[0].bedTimeDietEatenObject : [],
                        dietTargets:dietTargets?dietTargets:{},
                        dietPlanDetails: {},
                        loader: false
                    })
                } else if (response.statusCode == 0) {
                    this.setState({ loader: false })
                    cogoToast.error(response.error.errorMessage)
                }
            })
    }

    handleClose = () => {
        this.props.handleClose();
        this.setState({ validationError: '', })
    }
    handleDateChange = date => this.setState({ selectedDate: new Date(date).setHours(10, 0, 0, 0) }, () => {
        this.getUserDailyDiet();
    });

    calculateTotalCalories=(foods)=>{
        let totalCalorie=0;
        if(foods.length){
            foods.map(foodItem=>{
                totalCalorie = totalCalorie + foodItem.calorieContent
            })
            return totalCalorie
        }        
    }


    render() {
        let { morningSnacksList, breakfastList, midMorningDietList, lunchList, midEveningDietList, dinnerList, bedTimeDietList, dietPlanDetails, selectedDate, validationError, loader, dietTargets } = this.state
        return (

            <div className="inner_scroll">
                <div className="row">
                    <div className="col-5">
                        <h1>Diet Track</h1>
                        <h6>Per day calorie target- {dietTargets.perDayCalories} cal</h6>
                        </div>

                    <div className="col-6 cm_datepicker text-right">
                        <label>Select Date</label>
                        <DatePicker
                            className="form-control text-center"
                            selected={selectedDate}
                            onChange={this.handleDateChange}
                            showMonthDropdown
                            showYearDropdown
                            withPortal
                            todayButton="Go to today"

                        />
                    </div>
                </div>



                <div className="mt-3">
                    {loader && <Loader />}
                    {!morningSnacksList.length && !breakfastList.length && !lunchList.length && !dinnerList.length &&!midMorningDietList.length&&!midEveningDietList.length&&!bedTimeDietList.length?
                        <div className="row mb-5">
                            <div className="col"><h2 className="cm_not_found text-center">No diet tracked!</h2></div>
                        </div> :

                        <div>
                            <CardWrapper>
                                <h3>Morning Snacks</h3>

                                <div className="row">
                                <div className="col-2">
                                <h6>Calorie target- {dietTargets.recommendedMorningSnacksCalories} cal</h6>
                                </div>
                                <div className="col-2">
                                <h6>Calorie taken- {this.calculateTotalCalories(morningSnacksList)} cal</h6>
                                </div>
                                </div>

                                {!morningSnacksList.length ? <p className="text-danger">No food tracked</p> :
                                    <div className="row">
                                        {morningSnacksList.map((foodItem, i) => (
                                            <div className="p-3" key={i} >
                                                <div className="about_food">
                                                    <img src={foodItem.foodImage?foodItem.foodImage:FoodPlaceholder} alt="" />

                                                    <div className="food_content_bg">
                                                        <h4>{foodItem.foodName}</h4>
                                                        <div className="row">
                                                            <div className="col"> <h6>{foodItem.calorieContent} cal</h6></div>
                                                            <div className="col"> <h6>Qty. {foodItem.quantity ? foodItem.quantity : "-"}</h6></div>
                                                        </div>
                                                    </div>

                                                </div>
                                            </div>
                                        ))}
                                    </div>}
                            </CardWrapper>

                            <CardWrapper>
                                <h3>Breakfast</h3>

                                <div className="row">
                                <div className="col-2">
                                <h6>Calorie target- {dietTargets.recommendedBreakfastCalories} cal</h6>
                                </div>
                                <div className="col-2">
                                <h6>Calorie taken- {this.calculateTotalCalories(breakfastList)} cal</h6>
                                </div>
                                </div>

                                {!breakfastList.length ? <p className="text-danger">No food tracked</p> :
                                    <div className="row">
                                        {breakfastList.map((foodItem, i) => (
                                            <div className="p-3" key={i}>
                                                <div className="about_food">
                                                    <img src={foodItem.foodImage?foodItem.foodImage:FoodPlaceholder} alt="" />
                                                    <div className="food_content_bg">
                                                        <h4>{foodItem.foodName}</h4>
                                                        <div className="row">
                                                            <div className="col"> <h6>{foodItem.calorieContent} cal</h6></div>
                                                            <div className="col"> <h6>Qty. {foodItem.quantity ? foodItem.quantity : "-"}</h6></div>
                                                        </div>

                                                    </div>
                                                </div>
                                            </div>
                                        ))}
                                    </div>}
                            </CardWrapper>

                            <CardWrapper>
                                <h3>Mid Morning Diet</h3>

                                <div className="row">
                                <div className="col-2">
                                <h6>Calorie target- {dietTargets.recommendedMidMorningCalories} cal</h6>
                                </div>
                                <div className="col-2">
                                <h6>Calorie taken- {this.calculateTotalCalories(midMorningDietList)} cal</h6>
                                </div>
                                </div>


                                {!midMorningDietList.length ? <p className="text-danger">No food tracked</p> :
                                    <div className="row">
                                        {midMorningDietList.map((foodItem, i) => (
                                            <div className="p-3" key={i} >
                                                <div className="about_food">
                                                    <img src={foodItem.foodImage?foodItem.foodImage:FoodPlaceholder} alt="" />

                                                    <div className="food_content_bg">
                                                        <h4>{foodItem.foodName}</h4>
                                                        <div className="row">
                                                            <div className="col"> <h6>{foodItem.calorieContent} cal</h6></div>
                                                            <div className="col"> <h6>Qty. {foodItem.quantity ? foodItem.quantity : "-"}</h6></div>
                                                        </div>
                                                    </div>

                                                </div>
                                            </div>
                                        ))}
                                    </div>}
                            </CardWrapper>

                            <CardWrapper>
                                <h3>Lunch</h3>

                                <div className="row">
                                <div className="col-2">
                                <h6>Calorie target- {dietTargets.recommendedLunchCalories} cal</h6>
                                </div>
                                <div className="col-2">
                                <h6>Calorie taken- {this.calculateTotalCalories(lunchList)} cal</h6>
                                </div>
                                </div>

                                {!lunchList.length ? <p className="text-danger">No food tracked</p> :
                                    <div className="row">
                                        {lunchList.map((foodItem, i) => (
                                            <div className="p-3" key={i}>
                                                <div className="about_food">
                                                    <img src={foodItem.foodImage?foodItem.foodImage:FoodPlaceholder} alt="" />
                                                    <div className="food_content_bg">
                                                        <h4>{foodItem.foodName}</h4>
                                                        <div className="row">
                                                            <div className="col"> <h6>{foodItem.calorieContent} cal</h6></div>
                                                            <div className="col"> <h6>Qty. {foodItem.quantity ? foodItem.quantity : "-"}</h6></div>
                                                        </div>

                                                    </div>
                                                </div>
                                            </div>
                                        ))}
                                    </div>}
                            </CardWrapper>

                            <CardWrapper>
                                <h3>Mid Evening Diet</h3>
                                <div className="row">
                                <div className="col-2">
                                <h6>Calorie target- {dietTargets.recommendedMidEveningCalories} cal</h6>
                                </div>
                                <div className="col-2">
                                <h6>Calorie taken- {this.calculateTotalCalories(midEveningDietList)} cal</h6>
                                </div>
                                </div>

                                {!midEveningDietList.length ? <p className="text-danger">No food tracked</p> :
                                    <div className="row">
                                        {midEveningDietList.map((foodItem, i) => (
                                            <div className="p-3" key={i} >
                                                <div className="about_food">
                                                    <img src={foodItem.foodImage?foodItem.foodImage:FoodPlaceholder} alt="" />
                                                    <div className="food_content_bg">
                                                        <h4>{foodItem.foodName}</h4>
                                                        <div className="row">
                                                            <div className="col"> <h6>{foodItem.calorieContent} cal</h6></div>
                                                            <div className="col"> <h6>Qty. {foodItem.quantity ? foodItem.quantity : "-"}</h6></div>
                                                        </div>
                                                    </div>

                                                </div>
                                            </div>
                                        ))}
                                    </div>}
                            </CardWrapper>


                            <CardWrapper>
                                <h3>Dinner</h3>
                                <div className="row">
                                <div className="col-2">
                                <h6>Calorie target- {dietTargets.recommendedDinnerCalories} cal</h6>
                                </div>
                                <div className="col-2">
                                <h6>Calorie taken- {this.calculateTotalCalories(dinnerList)} cal</h6>
                                </div>
                                </div>

                                {!dinnerList.length ? <p className="text-danger">No food tracked</p> :
                                    <div className="row">
                                        {dinnerList.map((foodItem, i) => (
                                            <div className="p-3" key={i}>
                                                <div className="about_food">
                                                    <img src={foodItem.foodImage?foodItem.foodImage:FoodPlaceholder} alt="" />
                                                    <div className="food_content_bg">
                                                        <h4>{foodItem.foodName}</h4>
                                                        <div className="row">
                                                            <div className="col"> <h6>{foodItem.calorieContent} cal</h6></div>
                                                            <div className="col"> <h6>Qty. {foodItem.quantity ? foodItem.quantity : "-"}</h6></div>
                                                        </div>

                                                    </div>
                                                </div>
                                            </div>
                                        ))}
                                    </div>
                                }
                            </CardWrapper>

                            <CardWrapper>
                                <h3>Bed Time Diet</h3>
                                <div className="row">
                                <div className="col-2">
                                <h6>Calorie target- {dietTargets.recommendedBedTimeCalories} cal</h6>
                                </div>
                                <div className="col-2">
                                <h6>Calorie taken- {this.calculateTotalCalories(bedTimeDietList)} cal</h6>
                                </div>
                                </div>
                                            
                                {!bedTimeDietList.length ? <p className="text-danger">No food tracked</p> :
                                    <div className="row">
                                        {bedTimeDietList.map((foodItem, i) => (
                                            <div className="p-3" key={i} >
                                                <div className="about_food">
                                                    <img src={foodItem.foodImage?foodItem.foodImage:FoodPlaceholder} alt="" />
                                                    <div className="food_content_bg">
                                                        <h4>{foodItem.foodName}</h4>
                                                        <div className="row">
                                                            <div className="col"> <h6>{foodItem.calorieContent} cal</h6></div>
                                                            <div className="col"> <h6>Qty. {foodItem.quantity ? foodItem.quantity : "-"}</h6></div>
                                                        </div>
                                                    </div>

                                                </div>
                                            </div>
                                        ))}
                                    </div>}
                            </CardWrapper>

                        </div>}
                </div>




            </div>
        )
    }
}

export default DietTrack;