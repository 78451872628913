import React, { Component } from 'react';
import { Modal } from 'react-bootstrap';
import DatePicker from "react-datepicker";
import Select from 'react-select';
import { saveDietPlanTempelate } from '../../config/service/usersService';
import cogoToast from 'cogo-toast';


class SaveTempelate extends Component {

    constructor(props) {
        super(props);

        this.state = {
            dietPlanDetails: {},
            morningSnacksDietCalorie: 0,
            templateTitle: '',
            morningSnacksListing: [],
            breakfastListing: [],
            lunchListing: [],
            dinnerListing: [],
            midMorningDietListing: [],
            midEveningDietListing: [],
            bedTimeDietListing: [],

            dietPlanDetails: [],
            morningSnacksDietCalorie: 0,
            breakfastDietCalorie: 0,
            lunchDietCalorie: 0,
            dinnerDietCalorie: 0,
            midMorningDietCalorie: 0,
            midEveningDietCalorie: 0,
            bedTimeDietCalorie: 0,
        }
    }


    componentWillReceiveProps() {
    }


    handleChange = (e) => {
        const { name, value } = e.target;
        this.setState({ [name]: value })
    }

    submitDietPlanTempelate = (e) => {
        e.preventDefault();
        const {templateTitle, } = this.state;

        let { morningSnacksList, breakfastList, midMorningDietList, lunchList, midEveningDietList, dinnerList, bedTimeDietList, dietPlanDetails } = this.props;       


        let paramsObj = {
            dietPlanId: dietPlanDetails.dietPlanId,
            morningSnacksOptions: morningSnacksList,
            breakfastOptions: breakfastList,
            midMorningOptions: midMorningDietList,
            lunchOptions: lunchList,
            midEveningOptions: midEveningDietList,
            dinnerOptions: dinnerList,
            bedTimeOptions: bedTimeDietList,
            perDayCalories:dietPlanDetails.perDayCalories,
            recommendedMorningSnacksCalories: dietPlanDetails.recommendedMorningSnacksCalories,
            recommendedBreakfastCalories: dietPlanDetails.recommendedBreakfastCalories,
            recommendedMidMorningCalories: dietPlanDetails.recommendedMidMorningCalories,
            recommendedLunchCalories: dietPlanDetails.recommendedLunchCalories,
            recommendedMidEveningCalories: dietPlanDetails.recommendedMidEveningCalories,
            recommendedDinnerCalories: dietPlanDetails.recommendedDinnerCalories,
            recommendedBedTimeCalories: dietPlanDetails.recommendedBedTimeCalories,
            morningSnacksDietTime: dietPlanDetails.morningSnacksDietTime,
            breakfastDietTime: dietPlanDetails.breakfastDietTime,
            midMorningDietTime: dietPlanDetails.midMorningDietTime,
            lunchDietTime: dietPlanDetails.lunchDietTime,
            midEveningDietTime: dietPlanDetails.midEveningDietTime,
            dinnerDietTime: dietPlanDetails.dinnerDietTime,
            bedTimeDietTime: dietPlanDetails.bedTimeDietTime,
            templateTitle
        }

        if (!templateTitle) {
            this.setState({ validationError: "Enter template title" })
        } else {
            this.setState({ validationError: "" })
            this.saveDietPlanTempelateApi(paramsObj)
        }
    }

    saveDietPlanTempelateApi = (params) => {
        saveDietPlanTempelate(params)
            .then((res) => {
                let response = res.data
                if (response.statusCode == 1) {
                    cogoToast.success(response.responseData.message)
                    this.props.handleClose();
                    this.props.getUserDietPlan();
                    this.props.getDietTempelateLabels();

                } else if (response.statusCode == 0) {
                    this.setState({ validationError: response.error.errorMessage })
                }
            })
    }

    handleClose = () => {
        this.props.handleClose();
        this.setState({ validationError: '', templateTitle: '' })
    }

    render() {
        let { saveDietPlanTempelatePopUp } = this.props;
        let { validationError } = this.state
        return (
            <Modal
                show={saveDietPlanTempelatePopUp}
                onHide={this.handleClose}
                size="md"
                aria-labelledby="contained-modal-title-vcenter"
                centered
            >
                <Modal.Header>
                    <Modal.Title>Give a title to this template.</Modal.Title>
                </Modal.Header>

                <Modal.Body className="text-center">
                    <h4 className="text-center text-danger">{validationError}</h4>

                    <div className=" tmp_title">
                        <input type="text" className="form-control" id="tempelateTitle" name="templateTitle" onChange={this.handleChange} placeholder="Tempelate title (Ex: Plan for BMR 15-20)" />
                    </div>
                    <button className="btn btn-danger cm_danger" onClick={this.handleClose}>Cancel</button> &nbsp; &nbsp;
                <button className="btn btn-success" onClick={this.submitDietPlanTempelate} >Save</button>

                </Modal.Body>
            </Modal>
        )
    }
}

export default SaveTempelate;