import React from 'react';  
import {NavLink} from 'react-router-dom'

const DietHistoryHeader = ({ dietOfUserId }) => {
    return (
        <>
            <div className="sub_header">
                <h3>
                        <span className="diet_plan_link">
                            <NavLink to={{
                                    pathname: `/user/${dietOfUserId}`,
                                    activeTab: 'dietPlan',
                                }} > Diet Plan  </NavLink>
                            <i className="fa fa-angle-double-right" aria-hidden="true"></i>  
                             <span > Diet History</span>
                        </span>
                       
                    </h3>
                       
            </div>
        </>
    );
};

export default DietHistoryHeader;