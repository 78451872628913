import React, { Component } from 'react';
import Header from '../template/Header';
import { getCallRequests, changeRequestStatus } from '../../config/service/callSchedulingService';
import cogoToast from 'cogo-toast';
import { Loader, MiniLoader } from '../Reuse';
import moment from 'moment';
import { Modal } from 'react-bootstrap';
import DatePicker from "react-datepicker";
import { Dropdown } from 'react-bootstrap';
import Pagination from "react-js-pagination";
import { Link } from 'react-router-dom';



class CallScheduling extends Component {

    constructor(props) {
        super(props)
        this.state = {
            callSchedules: [],
            acceptOrRejectPopup: false,
            tempRequestStatus: "",
            tempRequestId: "",
            date: "",
            search: "",
            filterStatus: null,
            nameSortType: 1,
            sortField: 'created',
            sortType: 0,
            loader: false,
            pageNo: 1,
            count: 30,
            selectedDate: "",
            totalRequestCount: 100, //will be changed according to api data
            activeFilter: null,
            dateFrom:"",
            dateTo:"",
            loader:false,
        }
    }

    componentDidMount() {
        this.getCallRequests()
    }

    handlePageChange = pageNo => {
        this.setState({ pageNo, loader: true }, () => this.getCallRequests());
    }

    getCallRequests = () => {
        const { date, miniLoader, search, filterStatus, sortField, sortType, count, pageNo,dateFrom,dateTo } = this.state

        let paramsObj = { params: { date, search, filterStatus, sortField, sortType, count, pageNo: pageNo - 1, dateFrom, dateTo } }
        this.setState({
            ...(miniLoader)?{miniLoader: true}: {loader: true}
        })
       
        getCallRequests(paramsObj)
            .then(res => {
                let response = res.data;
                if (response.statusCode == 1) {
                    this.setState({ callSchedules: response.responseData.result, totalRequestCount: response.responseData.totalRequestCount, loader: false, miniLoader:false })
                } else if (response.statusCode == 0) {
                    this.setState({ loader: false, miniLoader: false })
                    cogoToast.error(response.error.errorMessage)
                }
            })
    }

    changeRequestStatus = (request, requestStatus) => {
        this.setState({ tempRequestStatus: requestStatus, tempRequestId: request._id, acceptOrRejectPopup: true })
    }

    submitChangeRequestStatus = (e) => {
        e.preventDefault();
        const { tempRequestId, tempRequestStatus } = this.state

        this.setState({ loader: true })

        let paramsObj = {
            requestId: tempRequestId,
            requestStatus: tempRequestStatus
        }

        changeRequestStatus(paramsObj)
            .then(res => {
                let response = res.data;
                if (response.statusCode == 1) {
                    this.getCallRequests()
                    this.handleClose()
                    this.setState({ loader: false })
                    cogoToast.success(response.responseData.message)

                } else if (response.statusCode == 0) {
                    this.setState({ loader: false })
                    cogoToast.error(response.error.errorMessage)
                }
            })
    }


    getDataByDate = (date) => {
        this.setState({ dateFrom: new Date(date).setHours(0, 0, 0, 0),dateTo: new Date(date).setHours(23, 59, 59, 999), selectedDate: date }, () => {
            this.getCallRequests();
        })
    }

    handleClose = () => {
        this.setState({
            acceptOrRejectPopup: false,
            tempRequestStatus: "",
            tempRequestId: "",
        })
    }

    handleSearch = (e) => {
        this.setState({ search: e.target.value, miniLoader: true  }, () => this.getCallRequests())
    }

    handleFilter = (filterStatus) => {
        this.setState({ filterStatus, activeFilter: filterStatus }, () => this.getCallRequests())
    }

    handSort = (field) => {

        let { sortType } = this.state

        switch (field) {
            case 'firstName':
                this.setState({ sortType: sortType == 1 ? -1 : 1, sortField: 'firstName' }, () => { this.getCallRequests() })
                break;

            case 'created':
                this.setState({ sortType: sortType == 1 ? -1 : 1, sortField: 'created' }, () => { this.getCallRequests() })
                break;

            case 'callDate':
                this.setState({ sortType: sortType == 1 ? -1 : 1, sortField: 'callDate' }, () => { this.getCallRequests() })
                break;
        }
    }

    render() {
        let { callSchedules, miniLoader, acceptOrRejectPopup, tempRequestStatus, loader, count, pageNo, totalRequestCount, selectedDate,
            activeFilter } = this.state

        return (
            <React.Fragment>
                <Header />

                {loader && <Loader />}

                <div className="page_wrapper">
                    <div className="chat_wrapper">
                        <div className="row">
                            <div className="col-lg">
                                <div className="card_wrapper cm_window_hieght pt-0 pb-0">

                                    <div className="form-group text-center cm_cent">
                                        <label>Select Date</label>
                                        <DatePicker
                                            className="form-control"
                                            onChange={this.getDataByDate}
                                            selected={selectedDate}
                                            placeholderText={"Go to particular date"}
                                        />
                                    </div>

                                    <div className="time_slot_search">
                                       
                                        <input
                                            type="text"
                                            placeholder="Search by Name"
                                            className="form-control"
                                            onChange={this.handleSearch}
                                        />
                                        {
                                            miniLoader ? <MiniLoader /> : null
                                         }
                                    </div>



                                    <div className="call_schedules_body text-center">
                                        <table className="table table-bordered ">

                                            <thead>
                                                <tr>
                                                    <th scope="col">#</th>
                                                    <th scope="col">Request by &nbsp;&nbsp;
                                                    <i className="fa fa-sort cur_pointer" onClick={() => this.handSort('firstName')} />
                                                    </th>
                                                    <th scope="col">Call Date &nbsp;&nbsp;
                                                    <i className="fa fa-sort cur_pointer" onClick={() => this.handSort('callDate')} />
                                                    </th>
                                                    <th scope="col">Time Slot</th>
                                                    <th scope="col">Requested On &nbsp;&nbsp;
                                                    <i className="fa fa-sort cur_pointer" onClick={() => this.handSort('created')} />
                                                    </th>
                                                    <th scope="col">Action
                                                    <span className="cm_filter">

                                                            <Dropdown>
                                                                <Dropdown.Toggle id="dropdown-basic">Filter <i className="fa fa-filter" /></Dropdown.Toggle>

                                                                <Dropdown.Menu>


                                                                    <Dropdown.Item
                                                                        active={activeFilter == null ? true : false}
                                                                        onClick={() => this.handleFilter(null)}>&nbsp;&nbsp; All</Dropdown.Item>

                                                                    <Dropdown.Item
                                                                        active={activeFilter == 0 ? true : false}
                                                                        onClick={() => this.handleFilter(0)}>&nbsp;&nbsp;
                                                                    Pending</Dropdown.Item>

                                                                    <Dropdown.Item
                                                                        active={activeFilter == 1 ? true : false}
                                                                        onClick={() => this.handleFilter(1)}>
                                                                        &nbsp;&nbsp; Accepted</Dropdown.Item>

                                                                    <Dropdown.Item
                                                                        active={activeFilter == 2 ? true : false}
                                                                        onClick={() => this.handleFilter(2)}>&nbsp;&nbsp; Rejected</Dropdown.Item>

                                                                    <Dropdown.Item
                                                                        active={activeFilter == 3 ? true : false}
                                                                        onClick={() => this.handleFilter(3)}>&nbsp;&nbsp; Cancelled</Dropdown.Item>

                                                                    <Dropdown.Item
                                                                        active={activeFilter == 4 ? true : false}
                                                                        onClick={() => this.handleFilter(4)}>&nbsp;&nbsp; Done</Dropdown.Item>
                                                                </Dropdown.Menu>
                                                            </Dropdown>
                                                        </span>

                                                    </th>

                                                </tr>
                                            </thead>
                                            <tbody>
                                                {callSchedules.map((details, i) => (
                                                    <tr>
                                                        <th scope="row">{((i + 1) + ((pageNo - 1) * count))}</th>
                                                        <td>
                                                            <Link to={'user/' + details.userDetails._id}>{details.userDetails.firstName + " " + details.userDetails.lastName}</Link>

                                                        </td>
                                                        <td>{moment(details.callDate).format("Do MMM YYYY")}</td>
                                                        <td>
                                                            <span className="cm_badge">{details.callTimeSlot}</span></td>
                                                        <td>{moment(details.created).format("Do MMM YYYY")}</td>


                                                        <td>
                                                            {details.callDate < new Date().setHours(23, 59, 59, 999) ? "Expired" :
                                                                details.status == 0 ?
                                                                    <div className="row">
                                                                        <div className="col">
                                                                            <i className="fa fa-check-circle cm_accept"
                                                                                onClick={(val) => this.changeRequestStatus(details, 1)}
                                                                            />
                                                                        </div>
                                                                        <div className="col">
                                                                            <i className="fa fa-times-circle-o cm_reject"
                                                                                onClick={(val) => this.changeRequestStatus(details, 2)}
                                                                            />
                                                                        </div>
                                                                    </div>
                                                                    : details.status == 1 ?
                                                                        <div>
                                                                    <button className="btn btn-success" onClick={(val) => this.changeRequestStatus(details, 4)}>
                                                                      Done </button>  &nbsp;
                                                                                
                                                                        <button className="btn btn-primary" onClick={(val) => this.changeRequestStatus(details, 3)}>Cancel</button>
                                                                        </div>
                                                                        : details.status == 3 ? "Cancelled" : details.status == 4 ? "Done" : "Rejected"}</td>
                                                    </tr>
                                                ))}

                                            </tbody>
                                        </table>
                                        {!callSchedules.length ? <p className="text-danger">No Requests Found!</p> : ""}

                                        <Pagination
                                            activePage={pageNo}
                                            itemsCountPerPage={count}
                                            totalItemsCount={totalRequestCount}
                                            pageRangeDisplayed={3}
                                            onChange={this.handlePageChange}
                                        />
                                    </div>


                                </div>
                            </div>


                        </div>
                    </div>
                </div>

                <Modal
                    show={acceptOrRejectPopup}
                    onHide={this.handleClose}
                    size="md"
                    aria-labelledby="contained-modal-title-vcenter"
                    centered
                >
                    <Modal.Header>
                        <Modal.Title>Are you sure, you want to
                            {tempRequestStatus === 1 ? " accept" : tempRequestStatus === 2 ? " reject" : tempRequestStatus === 3 ? " cancel" : " done"} this request?</Modal.Title>
                    </Modal.Header>

                    <Modal.Body className="text-center">
                        <button className="btn btn-danger cm_danger" onClick={this.handleClose}>No</button> &nbsp; &nbsp;
                        <button className="btn btn-success" onClick={this.submitChangeRequestStatus} >Yes</button>
                    </Modal.Body>
                </Modal>


            </React.Fragment>
        )
    }
}

export default CallScheduling;