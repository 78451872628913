import React from 'react';  
import FoodPlaceholder from '../../assets/img/FoodPlaceholder.jpg';
import FoodCard from './FoodCard'
const CardWrapper = props => <div className="col mb-5">{props.children}</div>;

const DietHistoryBody = ({ dietPlanDetails }) => {
    const  {
        morningSnacksOptions,
        breakfastOptions,
        lunchOptions,
        dinnerOptions,
        midEveningOptions,
        midMorningOptions,
        bedTimeOptions,
        perDayCalories,
        recommendedMorningSnacksCalories,
        recommendedBreakfastCalories,
        recommendedMidMorningCalories,
        recommendedLunchCalories,
        recommendedMidEveningCalories,
        recommendedDinnerCalories,
        recommendedBedTimeCalories,
        morningSnacksDietTime,
        breakfastDietTime,
        midMorningDietTime,
        lunchDietTime,
        midEveningDietTime,
        dinnerDietTime,
        bedTimeDietTime
       
    } = dietPlanDetails || {} 
    let morningSnacksList= morningSnacksOptions || []
    let breakfastList = breakfastOptions || []
    let midMorningDietList = midMorningOptions || []
    let lunchList = lunchOptions || []
    let midEveningDietList = midEveningOptions || []
    let dinnerList  =dinnerOptions ||[]
    let bedTimeDietList  =bedTimeOptions ||[]
    
    return (
        <>
    
            <CardWrapper>
                    <h3 className="cm_pos_rel">Pre-Breakfast</h3>
                    <h6>Calorie target- {recommendedMorningSnacksCalories} cal&nbsp;&nbsp;&nbsp;  Timing - {morningSnacksDietTime}</h6>
                  
                    {!morningSnacksList.length ?
                        <p className="text-danger">No food allotted</p> :
                        <div className="row">
                            {morningSnacksList.map((options, i) => (
                                <div className="col-sm-12" key={i} >
                                    <span className="pull_center"> Option {i + 1} &nbsp;&nbsp;&nbsp; Total Calories- {options.morningSnacksDietCalorie ? options.morningSnacksDietCalorie : ""} cal</span>

                                    <div className="row">
                                        {options.morningSnacksDiet.map((foodItem, index) => (
                                            <div className="p-3" key={index}>
                                                <FoodCard foodItem={foodItem} />
                                            </div>
                                        ))}
                                    </div>
                                </div>
                            ))}
                        </div>}
                </CardWrapper>

       
                <CardWrapper>
                    <h3 className="cm_pos_rel">Breakfast</h3>
                    <h6>Calorie target- {recommendedBreakfastCalories} cal&nbsp;&nbsp;&nbsp;  Timing - {breakfastDietTime}</h6>
                    
                    {!breakfastList.length ? <p className="text-danger">No food allotted</p> :
                        <div className="row">
                            {breakfastList.map((options, i) => (
                                <div className="col-sm-12" key={i} >
                                    <span className="pull_center"> Option {i + 1} &nbsp;&nbsp;&nbsp; Total Calories- {options.breakfastDietCalorie ? options.breakfastDietCalorie : ""} cal</span>
                                    <div className="row">
                                        {options.breakfastDiet.map((foodItem, index) => (
                                            <div className="p-3" key={index}>
                                                <FoodCard foodItem={foodItem} />
                                            </div>
                                        ))}
                                    </div>
                                </div>
                            ))}
                        </div>}
                </CardWrapper>


                <CardWrapper>
                    <h3 className="cm_pos_rel">Mid Morning</h3>
                    <h6>Calorie target- {recommendedMidMorningCalories} cal&nbsp;&nbsp;&nbsp;  Timing - {midMorningDietTime}</h6>
                    &nbsp;&nbsp;&nbsp;

                    {!midMorningDietList.length ? <p className="text-danger">No food allotted</p> :
                        <div className="row">
                            {midMorningDietList.map((options, i) => (
                                <div className="col-sm-12" key={i} >

                                    <span className="pull_center">Option {i + 1}  &nbsp;&nbsp;&nbsp; Total Calories- {options.midMorningDietCalorie ? options.midMorningDietCalorie : ""} cal</span>

                                    <div className="row">
                                        {options.midMorningDiet.map((foodItem, index) => (
                                            <div className="p-3" key={index}>
                                                    <FoodCard foodItem={foodItem} />
                                            </div>
                                        ))}
                                    </div>
                                </div>
                            ))}
                        </div>}
                </CardWrapper>


                <CardWrapper>
                    <h3 className="cm_pos_rel">Lunch</h3>  <h6>Calorie target- {recommendedLunchCalories} cal&nbsp;&nbsp;&nbsp;  Timing - {lunchDietTime}</h6>
                    &nbsp;&nbsp;&nbsp;
                    {!lunchList.length ? <p className="text-danger">No food allotted</p> :
                        <div className="row">
                            {lunchList.map((options, i) => (
                                <div className="col-sm-12" key={i} >

                                    <span className="pull_center">Option {i + 1}&nbsp;&nbsp;&nbsp; Total Calories- {options.lunchDietCalorie ? options.lunchDietCalorie : ""} cal</span>

                                    <div className="row">
                                        {options.lunchDiet.map((foodItem, index) => (
                                            <div className="p-3" key={index}>
                                                 <FoodCard foodItem={foodItem} />
                                            </div>
                                        ))}
                                    </div>
                                </div>
                            ))}
                        </div>}
                </CardWrapper>
              
                <CardWrapper>
                    <h3 className="cm_pos_rel">Mid Evening</h3>
                    <h6>Calorie target- {recommendedMidEveningCalories} cal&nbsp;&nbsp;&nbsp;  Timing - {midEveningDietTime}</h6>
                    &nbsp;&nbsp;&nbsp;
                    {!midEveningDietList.length ? <p className="text-danger">No food allotted</p> :
                        <div className="row">
                            {midEveningDietList.map((options, i) => (
                                <div className="col-sm-12" key={i} >
                                    <span className="pull_center">Option {i + 1}&nbsp;&nbsp;&nbsp; Total Calories- {options.midEveningDietCalorie ? options.midEveningDietCalorie : ""} cal</span>
                                    <div className="row">
                                        {options.midEveningDiet.map((foodItem, index) => (
                                            <div className="p-3" key={index}>
                                                    <FoodCard foodItem={foodItem} />
                                            </div>
                                        ))}
                                    </div>
                                </div>
                            ))}
                        </div>}
                </CardWrapper>


                <CardWrapper>
                    <h3 className="cm_pos_rel">Dinner</h3>
                    <h6>Calorie target- {recommendedDinnerCalories} cal&nbsp;&nbsp;&nbsp;  Timing - {dinnerDietTime}</h6>
                    &nbsp;&nbsp;&nbsp;

                    {!dinnerList.length ? <p className="text-danger">No food allotted</p> :
                        <div className="row">
                            {dinnerList.map((options, i) => (
                                <div className="col-sm-12" key={i} >

                                    <span className="pull_center">Option {i + 1}&nbsp;&nbsp;&nbsp; Total Calories- {options.dinnerDietCalorie ? options.dinnerDietCalorie : ""} cal</span>

                                    <div className="row">
                                        {options.dinnerDiet.map((foodItem, index) => (
                                            <div className="p-3" key={index}>
                                                 <FoodCard foodItem={foodItem} />
                                            </div>
                                         ))}
                                    </div>
                                </div>
                            ))}
                        </div>
                    }
                </CardWrapper>

                <CardWrapper> 
                    <h3 className="cm_pos_rel">Bed Time</h3>
                    <h6>Calorie target- {recommendedBedTimeCalories} cal&nbsp;&nbsp;&nbsp;  Timing - {bedTimeDietTime}</h6>
                    &nbsp;&nbsp;&nbsp;
                    {!bedTimeDietList.length ? <p className="text-danger">No food allotted</p> :
                        <div className="row">
                            {bedTimeDietList.map((options, i) => (
                                <div className="col-sm-12" key={i} >

                                    <span className="pull_center">Option {i + 1}&nbsp;&nbsp;&nbsp; Total Calories- {options.bedTimeDietCalorie ? options.bedTimeDietCalorie : ""} cal</span>

                                    <div className="row">
                                        {options.bedTimeDiet.map((foodItem, index) => (
                                            <div className="p-3" key={index}>
                                                    <FoodCard foodItem={foodItem} />
                                            </div>
                                        ))}
                                    </div>
                                </div>
                            ))}
                        </div>}
                </CardWrapper>
        </>
    );
};

export default DietHistoryBody;