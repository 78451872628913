import React from 'react';
import Select from 'react-select'
const AssignDietician = ({ validationError, selectDietician, dieticianList }) => {
    return (
        <div>
            {validationError && <div className="alert alert-danger" role="alert">{validationError}</div>}
            <div className="form-group">
                <label>Dietician</label>
                <Select
                    isSearchable
                    options={dieticianList}
                    placeholder="Select a dietician"
                    onChange={(val) => selectDietician(val)}
                    className="mt-1 mb-1"
                />
            </div>
        </div>
    );
};

export default AssignDietician;