import axios from 'axios';
import API from '../../config/Api';

export function getAllUsers(params) {
    return axios.get(API.ADMIN_GET_USERS, params)
}
export function getAllUsersLabels(params) {
    return axios.get(API.ADMIN_GET_USERS_LABELS, params)
}
export function getUserLabelsByCsv(params) {
    return axios.post(API.ADMIN_GET_USERS_LABELS_BY_CSV, params)
}
