import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import { createStore, applyMiddleware } from 'redux';
import thunk from 'redux-thunk';

import "react-datepicker/dist/react-datepicker.css";
import '../node_modules/bootstrap/dist/css/bootstrap.min.css';
import './index.scss';
import './assets/styles/style.scss';
import App from './App';
import * as serviceWorker from './serviceWorker';
import * as firebase from "firebase/app";
import "firebase/performance";
import setting from './config/settings'

import rootReducer from './store/reducers';
import {fetchMiddleware, loggerMiddleware} from './store/middlewares'

const store = createStore(rootReducer, applyMiddleware(thunk,  fetchMiddleware, loggerMiddleware));

// Initialize Firebase
firebase.initializeApp(setting.firebaseConfig);
const perf = firebase.performance();
ReactDOM.render(
    <Provider store={store}>
        <App />
    </Provider>, 
    document.getElementById('root'));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
