
import { FETCH_USERS } from '../actions/users'

const defaultState = {
  status: 'UNINIT',
  users: [],
  totalUserCount:0,
  loader: false, 
  miniLoader: false
}

export default (state = defaultState, action) => {
  switch (action.type) {
    case `${FETCH_USERS}_REQUEST`:
    {
      const {
        isSearch
      } = action
      return {...state, 
        status : 'PENDING',
        totalUserCount:0,
        ...(isSearch)?{miniLoader: true}: {loader: true},
        users: [],
      }
    }

    case `${FETCH_USERS}_SUCCESS`:
    {
      let { 
        responseData : {
          users,
          totalCount: totalUserCount
        }
       } = action.res
      return {...state, 
        status : 'SUCCESS',
        totalUserCount,
        loader: false,
        miniLoader: false,
        users
      }
    }

    case `${FETCH_USERS}_FAILURE`:
    {
      let { 
        responseData : {
          users,
          totalCount: totalUserCount,
        }
       } = action.res
      return {...state, 
        status : 'FAILURE',
        totalUserCount,
        loader: false,
        miniLoader: false,
        users
      }
    }
    default:
      return state
  }
}
