
import { ADD_DIETITIAN_FOLLOWUP, GET_DIETITIAN_FOLLOWUP, EDIT_DIETITIAN_FOLLOWUP } from '../actions/followups'

const defaultState = {
  status: 'UNINIT',
  followups: [],
  loader: false
}

export default (state = defaultState, action) => {
  switch (action.type) {
    case `${ADD_DIETITIAN_FOLLOWUP}_REQUEST`:
    {
      return {...state, 
        status : 'PENDING',
        loader: true
      }
    }

    case `${ADD_DIETITIAN_FOLLOWUP}_SUCCESS`:
    {
      return {...state, 
        status : 'SUCCESS',
        loader: false    
      }
    }

    case `${ADD_DIETITIAN_FOLLOWUP}_FAILURE`:
    {
      return {...state, 
        status : 'FAILURE'
      }
    }
    /** 
     * EDIT_DIETITIAN_FOLLOWUP
     */
    case `${EDIT_DIETITIAN_FOLLOWUP}_REQUEST`:
      {
        return {...state, 
          status : 'PENDING',
          loader: true
        }
      }
  
      case `${EDIT_DIETITIAN_FOLLOWUP}_SUCCESS`:
      {
        return {...state, 
          status : 'SUCCESS',
          loader: false    
        }
      }
  
      case `${EDIT_DIETITIAN_FOLLOWUP}_FAILURE`:
      {
        return {...state, 
          status : 'FAILURE'
        }
      }
  

    /** 
     * GET_DIETITIAN_FOLLOWUP
     */
    case `${GET_DIETITIAN_FOLLOWUP}_REQUEST`:
    {
      return {...state, 
        status : 'PENDING',
        loader: true,
        followups:[]
      }
    }
    
    case `${GET_DIETITIAN_FOLLOWUP}_SUCCESS`:
    {
      const {responseData : {
        result
      }} = action.res
      const followups  = result.map((followup)=>{
        return {
          _id: followup._id,
          title: `${followup.users.firstName}  ${followup.users.lastName}`,
          desc: followup.notes,
          start: new Date(followup.followupDate) ,
          end: new Date(followup.followupDate) ,
          // end: moment(followup.followupDatej).add(30, 'm').toDate(),
          users: followup.users
        }
      })
      return {...state, 
        status : 'SUCCESS',
        loader: false ,
        followups
      }
    }

    case `${GET_DIETITIAN_FOLLOWUP}_FAILURE`:
    {
      return {...state, 
        status : 'FAILURE'
      }
    }
    
    default:
      return state
  }
}
