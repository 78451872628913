import axios from 'axios';
import API from '../../config/Api';

export function getAllHistory(params) {
    return axios.get(API.GET_ALL_HISTORY_BY_ADMIN, params)
}

export function getCurrentDietPlan(params) {
    return axios.get(API.GET_USER_DIET_PLAN, params)
}

export function getDietPlanHistory(params) {
    return axios.get(API.GET_SINGLE_PLAN_HISTORY_BY_ADMIN, params)
}

