import { addDietitianUserFollowup, getFollowupsByAdmin, editDietitianUserFollowup } from '../service/followupsService'

export const ADD_DIETITIAN_FOLLOWUP = 'ADD_DIETITIAN_FOLLOWUP'
export const GET_DIETITIAN_FOLLOWUP = 'GET_DIETITIAN_FOLLOWUP'
export const EDIT_DIETITIAN_FOLLOWUP = 'EDIT_DIETITIAN_FOLLOWUP'

export const addDietitianUserFollowupAction = (params) => ({
  type: ADD_DIETITIAN_FOLLOWUP,
  promise: addDietitianUserFollowup(params)
})

export const getFollowupsByAdminAction = (params) => ({
  type: GET_DIETITIAN_FOLLOWUP,
  promise: getFollowupsByAdmin(params)
})

export const editDietitianUserFollowupAction = (params) => ({
  type: EDIT_DIETITIAN_FOLLOWUP,
  promise: editDietitianUserFollowup(params)
})
