import React, { useState } from "react";
import ClickOutside from "../../utils/ClickCouside";
import './index.scss';

export const TextPopup = ({ text, toggleReadMore }) => {
    return (
        <div className="text-custom-popup-wrapper">
            <ClickOutside onClick={toggleReadMore}>
                <div className="text-custom-popup">
                    <div className="heading-wrapper">
                        <h2 className="text-heading" >Description</h2>
                        <i className="fa fa-times cursor-pointer" onClick={toggleReadMore}></i>
                    </div>
                    <div className="full-description">{text}</div>
                </div>
            </ClickOutside>
        </div>
    );
};