import React, { Component } from 'react';
import Select from 'react-select';
import { getFitnessTempelateLabels, getFitnessTempelate, addFitnessPlan } from '../../config/service/usersService';
import cogoToast from 'cogo-toast';
import { Modal } from 'react-bootstrap';
import { Loader } from '../Reuse';
import ExercisePlaceholder from '../../assets/img/ExercisePlaceholder.png';
import FitnessPlanCard from '../common/FitnessPlan';

const daysName = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'];
const CardWrapper = props => <div className="col mb-5">{props.children}</div>;


class FitnessPlanTempelate extends Component {

    constructor(props) {
        super(props);
        this.state = {
            fitnessTemplateLabels: [],
            fitnessExercise: [],
            validationError: '',
            loader: false,
            assignTemplatePopup: false,
        }
    }

    componentDidMount() {
        this.getFitnessTempelateLabels();
    }

    validFromChange = date => this.setState({ validFrom: new Date(new Date(date).setHours(0, 0, 0, 0)) });
    validToChange = date => this.setState({ validTo: new Date(new Date(date).setHours(23, 59, 59, 0)) });


    getFitnessTempelateLabels = () => {
        this.setState({ loader: true });
        getFitnessTempelateLabels()
            .then(res => {
                let response = res.data;
                if (response.statusCode == 1) {
                    this.setState({ loader: false, fitnessTemplateLabels: response.responseData.result })
                } else if (response.statusCode == 0) {
                    cogoToast.error(response.error.errorMessage);
                    this.setState({ loader: false })
                }
            })
    }

    handleClose = () => {
        this.setState({ assignTemplatePopup: false, validationError: '' })
        this.props.handleClose();
    }


    getFitnessTempelate = (e) => {
        let paramsObj = {
            params: {
                tempelateId: e.value
            }
        }
        this.setState({ loader: true })
        getFitnessTempelate(paramsObj)
            .then((res) => {
                if (res.data.statusCode == 1) {
                    this.setState({
                        fitnessExercise: res.data.responseData.result.fitnessExerciseObject,
                        // fitnessPlanDetails: res.data.responseData.result.fitnessPlanDetails,
                        fitnessPlanId: res.data.responseData.result._id,
                        loader: false
                    })
                } else if (res.data.statusCode == 0) {
                    this.setState({ loader: false })
                }
            })
    }



    assignToUser = () => {
        this.setState({ assignTemplatePopup: true })
    }

    handleClose = () => {
        this.setState({
            assignTemplatePopup: false,
        })
    }



    submitAssignToUser = (e) => {
        e.preventDefault();
        const { fitnessExercise } = this.state
        fitnessExercise.map((exercise, index) => {
            exercise.exerciseIndex = index
        })

        let paramsObj = {
            fitnessPlanUserId: this.props.userDetails._id,
            fitnessExercises: fitnessExercise,
            savedAsTempelate: true,
        };

        this.setState({ validationError: "" })
        addFitnessPlan(paramsObj)
            .then((res) => {
                let response = res.data
                if (response.statusCode == 1) {
                    this.handleClose();
                    this.props.getUserFitnessPlan();
                    this.props.handleTabChange('ExercisePlan');
                    cogoToast.success(response.responseData.message)
                } else if (response.statusCode == 0) {
                    this.setState({ validationError: response.error.errorMessage })
                }
            })
    }

    render() {
        let { fitnessTemplateLabels, fitnessExercise, loader, assignTemplatePopup, validationError } = this.state
        return (

            <div>
                {loader && <Loader />}
                <div className="mb-5">
                    <label className="cm_template">You can select a template from already saved ones and assign it to a user.</label>
                    <Select
                        isSearchable
                        options={fitnessTemplateLabels}
                        onChange={(val) => this.getFitnessTempelate(val)}
                        placeholder="Select a template."
                        noOptionsMessage={() => "Ooops! No result found."}
                        className="diet-custom-styled-select"
                        classNamePrefix="diet"
                    />
                </div>


                {fitnessExercise.length ?
                    <div className="row mb-5">
                        <div className="col"><h2>Fitness Plan Template</h2></div>
                        <div className="col text-right"><button className="btn btn-info" onClick={this.assignToUser}>Assign to user
                        <i className="fa fa-user-plus ml-1"></i>
                        </button>
                        </div>
                    </div>
                    : null}


                <div className="row">
                    {
                        fitnessExercise.map((item,key) => (
                            <FitnessPlanCard item={item} key={key} />
                        ))
                    }
                </div>


                <Modal
                    show={assignTemplatePopup}
                    onHide={this.handleClose}
                    size="md"
                    aria-labelledby="contained-modal-title-vcenter"
                    centered
                >
                    <Modal.Body className="text-center">
                        <Modal.Title>This action will replace user's existing fitness plan. Are you sure you want to assign this template to user?</Modal.Title>
                        <div className="form_group_wrapper">
                            <h4 className="text-center text-danger">{validationError}</h4>
                            <button className="btn btn-danger cm_danger" onClick={this.handleClose}>Cancel</button> &nbsp; &nbsp;
                        <button className="btn btn-success" onClick={this.submitAssignToUser} >Assign</button>
                        </div>
                    </Modal.Body>
                </Modal>


            </div>

        )
    }
}

export default FitnessPlanTempelate;