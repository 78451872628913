import React from 'react';
import Select from 'react-select'
import Dropzone from 'react-dropzone'
import { Button, Spinner } from 'react-bootstrap'

const BulkMessage =({users, selectUsers, isUploading, handleMessageChange, handleFileDrop, submitFile, message, validationError, multiselectRef })=> {
                return (
                   <div> 
                        {validationError && <div className="alert alert-danger" role="alert">{validationError}</div>}
                       <div className="form-group">
                           <Dropzone multiple={false}
                                     onDrop={(acceptedFiles, rejectedFiles) => handleFileDrop(acceptedFiles, rejectedFiles)}
                                     accept='.xlsx, .xls, .csv'
                           >
                               {({ getRootProps, getInputProps, isDragActive, acceptedFiles }) => (
                                   <section>
                                       <div {...getRootProps()} className="dropzone text-center"> <input {...getInputProps()} />
                                           <i className="fa fa-cloud-upload" />
                                           {isDragActive ? <p>Drop it here!</p> : acceptedFiles.length ? <p>{acceptedFiles[0].name}</p> : <p>Drag file or click to upload</p>}
                                       </div>
                                   </section>
                               )}
                           </Dropzone>
                           <Button variant="success" onClick={() => submitFile()} disabled={validationError || isUploading} size="sm">
                               {isUploading ? <><Spinner animation="border" variant="warning" size="sm" /> Uploading..</> : 'Upload'}
                           </Button>
                       </div>
                        <div className="form-group">
                            <label>Category</label>
                            <Select
                                ref={multiselectRef}
                                isSearchable
                                isMulti
                                options={users}
                                placeholder="Select users"
                                onChange={(val) => selectUsers(val)}
                                className="mt-1 mb-1"
                            />
                        </div>
                        <div className="form-group">
                            <label>Note:</label>
                            <textarea className="form-control" rows="3" onChange={handleMessageChange} value={message}></textarea>
                        </div>
                    </div>
               
        
                )
}

export default BulkMessage;
