import React, { Component } from 'react';
import HOC from '../../HOC';
import Popup from "reactjs-popup"
import { resetPassword } from '../../config/service/adminService';
import { withRouter } from "react-router";
import {ReactComponent as Logo} from "../../assets/img/cult-logo.svg";

class ResetPassword extends Component {
    constructor(props) {
        super()
        this.state = {
            oldPassword: '',
            newPassword: '',
            reEnterPassword: '',
            error: false,
            blankError: false,
            isShowPassword: false,
            isShowPassword2: false,
            isShowPassword3: false,
            sucsessPopup: false,
            resetPasswordMsg: 'Password changed successfully',
            weekPassword: ''

        }

        this.handleSubmit = this.handleSubmit.bind(this);
        this.ShowPassword = this.ShowPassword.bind(this);
        this.ShowPassword2 = this.ShowPassword2.bind(this);
        this.ShowPassword3 = this.ShowPassword3.bind(this);
        this.closeModal = this.closeModal.bind(this);
    }

    ShowPassword() { this.setState({ isShowPassword: !this.state.isShowPassword }) }
    ShowPassword2() { this.setState({ isShowPassword2: !this.state.isShowPassword2 }) }
    ShowPassword3() { this.setState({ isShowPassword3: !this.state.isShowPassword3 }) }

    validateStrongPassword = (password) => {
        console.log('test-=-=-=-=-=', password)
        return String(password)
            .match(
                /^(?=.*\d)(?=.*[!@#$%^&*])(?=.*[a-z])(?=.*[A-Z]).{8,}$/
            );
    }

    handleChange(event) {
        this.setState({
            [event.target.name]: event.target.value,
            blankError: false
        }, () => {
            if (this.state.newPassword !== this.state.reEnterPassword) {
                this.setState({ error: true })
            } else { this.setState({ error: false }) }
        });

    }

    closeModal() {
        this.setState({ sucsessPopup: false,  newPassword:'', oldPassword:'', reEnterPassword:'' });
    }

    handleSubmit(e) {
        const accessToken = localStorage.getItem('otpToken');
        e.preventDefault();
        const { newPassword, oldPassword, reEnterPassword } = this.state

        if (newPassword ==''||reEnterPassword=='') {
            this.setState({ sucsessPopup: true, resetPasswordMsg: 'All fields are mandatory' })
        } else if ((newPassword === reEnterPassword)) {
            console.log('test--------->>>>====', newPassword)
            console.log('verifyPassword', this.validateStrongPassword(newPassword))

                resetPassword(newPassword, accessToken)
                    .then(res => {
                        if (res.data.statusCode === 1) {
                            this.setState({ sucsessPopup: true, resetPasswordMsg: 'Password changed successfully', blankError: false })
                            this.props.history.push('/login');
                            localStorage.clear();
                        }else {
                            this.setState({ weekPassword: res.data.error.errorMessage, sucsessPopup: true })
                        }
                    })
                    .catch((error)=>{
                        console.log('error', error)
                    })

        } else {
            this.setState({ blankError: true })
        }
    }






    render() {
        return (
            <div className="login-wrapper">
                <div className="container h-100">
                    <div className="row h-100">
                        <div className="col-md-6 m-auto">
                            <div className="card">
                                <div className="card-body">
                                    <div className="card-title">
                                        <Logo />
                                        <h3>Change Password</h3>
                                    </div>
                                    <form onSubmit={this.handleSubmit}>
                                        <div className="form-group">
                                        <label htmlFor="newPassword" className="col-form-label">New Password</label>
                                            <div className="form-group cm_show_pass">
                                            <input
                                            type={this.state.isShowPassword2 ? 'text' : 'password'}
                                            name="newPassword"
                                            className={`form-control ${this.state.blankError ? 'is-invalid' : ''}`}
                                            onChange={(event) => this.handleChange(event)}
                                            value={this.state.newPassword}
                                        />
                                        <i
                                            className={`fa fa-eye${this.state.isShowPassword2 ? '' : '-slash'}`}
                                            onClick={this.ShowPassword2}
                                            aria-hidden="true"></i>

                                        {this.state.error ? <span className="cm_error">Confirm password doesn't match</span> : null}
                                    </div>
                                        </div>
                                        <div className="form-group">
                                        <label htmlFor="staticEmail" className="col-form-label">Confirm Password</label>
                                        <div className="form-group cm_show_pass">
                                        <input
                                            type={this.state.isShowPassword3 ? 'text' : 'password'}
                                            className={`form-control ${this.state.blankError ? 'is-invalid' : ''}`}
                                            onChange={(event) => this.handleChange(event)}
                                            name="reEnterPassword"
                                            value={this.state.reEnterPassword}
                                        />
                                        <i
                                            className={`fa fa-eye${this.state.isShowPassword3 ? '' : '-slash'}`}
                                            onClick={this.ShowPassword3}
                                            aria-hidden="true"></i>
                                        {this.state.error ? <span className="cm_error">Confirm password doesn't match</span> : null}
                                    </div>
                                        </div>
                                        <div className="form-group">
                                            <div className="row">
                                                <div className="col"><button className="btn btn-primary">Reset Password</button></div>
                                            </div>
                                </div>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <Popup
                    open={this.state.sucsessPopup}
                    closeOnDocumentClick
                    onClose={this.closeModal}
                >
                    <div className="cm_modal">
                        <span className="cm_modal_close" onClick={this.closeModal}>
                            &times;
                    </span>
                        <p className="text-center">{this.state.weekPassword}</p>

                    </div>
                </Popup>
           </div>

        );
    }
}

export default withRouter(ResetPassword);