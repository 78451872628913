import React, { Component } from 'react';
import Header from '../template/Header';

import { changePassword } from '../../config/service/adminService'
import cogoToast from 'cogo-toast';

class ChangePassword extends Component {
    constructor(props) {
        super(props);

        this.state = {
            showPass: false,
            showPass2: false,
            showPass3: false,
            oldPassword: '',
            newPassword: '',
            confirmPassword: '',
            oldPasswordMsg: '',
            newPasswordMsg: '',
            confirmPasswordMsg: '',
            resMsg: ''
        }
    }

    handleChange = e => {
        this.setState({
            [e.target.name]: e.target.value,
            [e.target.name + 'Msg']: '',
            resMsg: ''
        })

        if(e.target.name=='oldPassword'){
            this.setState({newPasswordMsg:''})
        }
    }

    handleValidation = (e) => {
        let validate = true;
        let { oldPassword, newPassword, confirmPassword } = this.state;

        if (oldPassword === ''|| oldPassword.length<6) {
            this.setState({ oldPasswordMsg: 'Old Password must contain atleast 6 characters' })
            validate = false
        } else
        if (newPassword === ''|| newPassword.length<6) {
            this.setState({ newPasswordMsg: 'New Password must contain atleast 6 characters' })
            validate = false
        }else

        if (newPassword === oldPassword) {
            this.setState({ newPasswordMsg: 'New and old password cannot be same' })
            validate = false
        }else

        if (confirmPassword === '') {
            this.setState({ confirmPasswordMsg: "Confirm password cannot be empty" })
            validate = false
        }else

        if (confirmPassword !== newPassword) {
            this.setState({ confirmPasswordMsg: "Confirm password doesn't match" })
            validate = false
        }else{
            this.setState({ confirmPasswordMsg:'',oldPasswordMsg:'',newPasswordMsg:'' })
        }

        return validate
    }


    handleShowPass = () => this.setState({ showPass: !this.state.showPass });
    handleShowPass2 = () => this.setState({ showPass2: !this.state.showPass2 });
    handleShowPass3 = () => this.setState({ showPass3: !this.state.showPass3 });


    handleSubmit = e => {
        e.preventDefault();

        if (this.handleValidation()) {
            let { newPassword, oldPassword } = this.state
            let obj = {
                newPassword, oldPassword
            }

            changePassword(obj)
                .then(res => {
                    if (res.data.statusCode == 1) {
                        cogoToast.success(res.data.responseData.message)                   
                        this.setState({
                            oldPassword: '',
                            newPassword: '',
                            confirmPassword: ''
                        })
                    } else if (res.data.statusCode == 0) {
                        cogoToast.error(res.data.error.errorMessage)
                    }
                })


        }
    }



    render() {
        let {
            showPass,
            showPass2,
            showPass3,
            oldPassword,
            newPassword,
            confirmPassword,
            oldPasswordMsg,
            newPasswordMsg,
            confirmPasswordMsg,
            resMsg
        } = this.state;
        return (
            <React.Fragment>
                <Header />

                <div className="page_wrapper">

                    <div className="sub_header">
                        <h3>Change Password</h3>
                        <span className="separator"></span>
                    </div>


                    <div className="admin_profile">
                        <div className="row">
                            <div className="col-lg-3"></div>

                            <div className="col-lg-6">
                                <div className="card_wrapper cm_window_hieght pt-3">

                                    <form className="chnage_password_form" onSubmit={this.handleSubmit}>

                                        <div className="form-group">
                                            <label>Enter Old Password</label>
                                            <input
                                                type={showPass ? 'text' : 'password'}
                                                name="oldPassword"
                                                value={oldPassword}
                                                className="form-control"
                                                onChange={this.handleChange}
                                            />
                                            <i className={`fa fa-eye${showPass ? '' : '-slash'}`} onClick={this.handleShowPass}></i>

                                            {oldPasswordMsg && <span className="error_msg">{oldPasswordMsg}</span>}
                                        </div>

                                        <div className="form-group">
                                            <label>Enter New Password</label>
                                            <input
                                                type={showPass2 ? 'text' : 'password'}
                                                className="form-control"
                                                value={newPassword}
                                                name="newPassword"
                                                onChange={this.handleChange}
                                            />
                                            <i className={`fa fa-eye${showPass2 ? '' : '-slash'}`} onClick={this.handleShowPass2}></i>

                                            {newPasswordMsg && <span className="error_msg">{newPasswordMsg}</span>}
                                        </div>

                                        <div className="form-group">
                                            <label>Enter Confirm Password</label>
                                            <input
                                                type={showPass3 ? 'text' : 'password'}
                                                className="form-control"
                                                name="confirmPassword"
                                                value={confirmPassword}
                                                onChange={this.handleChange}
                                            />
                                            <i className={`fa fa-eye${showPass3 ? '' : '-slash'}`} onClick={this.handleShowPass3}></i>

                                            {confirmPasswordMsg && <span className="error_msg">{confirmPasswordMsg}</span>}
                                        </div>

                                        <div className="form-group">
                                            <br />
                                            <button type="submit" className="btn btn-primary btn-block">Submit</button>
                                        </div>


                                        {
                                            resMsg ? <h3 className="text-center">{resMsg}</h3> : null
                                        }

                                    </form>


                                </div>
                            </div>

                            <div className="col-lg-3"></div>
                        </div>
                    </div>


                </div>


            </React.Fragment>
        )
    }
}

export default ChangePassword