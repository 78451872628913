import axios from 'axios';
import API from '../Api';


export function getUsers(params) {
    return axios.get(API.ADMIN_GET_USERS, params)
}

export function getAllUsers(params) {
    return axios.get(API.GET_ALL_USERS, params)
}

export function getUserDetails(params) {
    return axios.get(API.GET_USER_DETAILS, params)
}

export function getSelectedTimeSlots(params) {
    return axios.get(API.GET_SELECTED_TIME_SLOTS, params)
}

export function getTotalUsersCount() {
    return axios.get(API.GET_TOTAL_USERS)
}

export function getUserAnswers(params) {
    return axios.get(API.GET_USER_ANSWERS,params)
}

export function getQuestionById(params) {
    return axios.get(API.GET_QUESTION_BY_ID,params)
}

export function updateUserAnswer(params) {
    return axios.post(API.UPDATE_USER_ANSWER,params)
}


export function getDieticianQuestions(params) {
    return axios.get(API.GET_DIETICIAN_QUESTIONS,params)
}

export function getUserDietPlan(params) {
    return axios.get(API.GET_USER_DIET_PLAN,params)
}

export function addDietPlan(params) {
    // return axios.post(API.ADD_DIET_PLAN,params)
    return axios.post(API.CHANGE_DIET_PLAN,params)
}

export function editDietPlan(params) {
    // return axios.post(API.EDIT_DIET_PLAN,params)
    return axios.post(API.CHANGE_DIET_PLAN,params)
}

export function deleteDietPlan(params) {
    return axios.post(API.DELETE_DIET_PLAN,params)
}

export function getFitnessExercises(params) {
    return axios.get(API.GET_EXERCISES,params)
}

export function getExerciseCategories(params) {
    return axios.get(API.GET_EXERCISE_CATEGORIES,params)
}

export function addFitnessPlan(params) {
    return axios.post(API.ADD_FITNESS_PLAN,params)
}


export function editFitnessplan(params) {
    return axios.post(API.EDIT_FITNESS_PLAN,params)
}

export function getAllFoods(params) {
    return axios.get(API.GET_ALL_FOODS,params)
}

export function getUserFitnessplanAdmin(params) {
    return axios.get(API.GET_USER_FITNESS_PLAN,params)
}

export function deleteFitnessPlan(params) {
    return axios.post(API.DELETE_FITNESS_PLAN,params)
}

export function getUserLogs(params) {
    return axios.get(API.GET_USER_LOGS,params)
}

export function addUserLogs(params) {
    return axios.post(API.ADD_USER_LOGS,params)
}

export function saveDietPlanTempelate(params) {
    return axios.post(API.SAVE_DIET_PLAN_TEMPELATE,params)
}


export function getDietTempelateLabels(params) {
    return axios.get(API.GET_DIET_TEMPELATE_LABELS,params)
}


export function getDietPlanTempelate(params) {
    return axios.get(API.GET_DIET_PLAN_TEMPELATE,params)
}

export function userStats(params) {
    return axios.post(API.USER_STATS,params)
}

export function getUserTarget(params) {
    return axios.post(API.GET_USER_TARGET,params)
}


export function userDailyDiet(params) {
    return axios.get(API.USER_DAILY_DIET,params)
}


export function getUsersMessages(params) {
    return axios.get(API.GET_USER_MESSAGES,params)
}

export function getFitnessTempelateLabels(params) {
    return axios.get(API.GET_FITNESS_TEMPELATE_LABELS,params)
}


export function getFitnessTempelate(params) {
    return axios.get(API.GET_FITNESS_TEMPELATE,params)
}

export function addFitnessPlanTempelate(params) {
    return axios.post(API.ADD_FITNESS_TEMPELATE,params)
}

export function addCallTimeSlots(params) {
    return axios.post(API.ADD_CALL_TIME_SLOTS,params)
}

export function getAllTimeSlots(params) {
    return axios.get(API.GET_ALL_TIME_SLOTS,params)
}

export function submitTimeSlotsViaAdmin(params) {
    return axios.post(API.EDIT_TIME_SLOTS_BY_ADMIN,params)
}

export function sendOtp(params) {
    return axios.post(API.SEND_OTP,params)
}

export function getPendingApproveDietPlan(params) {
    return axios.get(API.GET_PREVIOUS_ASSIGN_PENDING_DIET_PLAN,params)
}

export function reviewDietPlan(params) {
    return axios.post(API.REVIEW_DIET_PLAN,params)
}

export function sendEmailToPdf(params) {
    return axios.get(API.SEND_EMAIL_TO_PDF_DIET_PLAN,params)
}

export function getFitnessMessages(params) {
    return axios.get(API.GET_FITNESS_MESSAGES,params)
}

export function updateDietPlanEndDate(params) {
    return axios.post(API.UPDATE_DIET_PLAN_END_DATE,params)
}

export function getAvailableTimeSlotsOfExpert(params) {
    return axios.get(API.GET_AVAILABLE_TIMESLOTS_OF_EXPERT, params)
}