import axios from 'axios';
import API from '../Api';


export function getCallRequests(params) {
    return axios.get(API.GET_CALL_REQUESTS, params)
}

export function changeRequestStatus(params) {
    return axios.post(API.CHANGE_REQUEST_STATUS, params)
}

export function bookTimeSlotsOfExpert(params) {
    return axios.post(API.BOOK_TIME_SLOT_OF_EXPERT, params)
}