import { getAllUsersLabels } from '../service/usersService'

export const FETCH_USERS_LABELS = 'FETCH_USERS_LABELS'
export const UPDATE_FOLLOWUP_COUNT = 'UPDATE_FOLLOWUP_COUNT'


export const getAllUsersLabelsAction = (params) => ({
  type: FETCH_USERS_LABELS,
  promise: getAllUsersLabels(params)
})

export const getFollowupNotificationsAction = payload => (dispatch => dispatch({ type: UPDATE_FOLLOWUP_COUNT, payload}))