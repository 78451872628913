import axios from 'axios';
import API from '../Api';


export function login(params) {
    return axios.post(API.ADMIN_DIETICIAN_LOGIN, params)
}


export function POST(params) {
    return axios.post(API.ADMIN_DIETICIAN_LOGIN1, params)
}
export function reSendOtp(token) {
    return axios({
        method: 'get',
        url: `${API.RESEND_OTP}`,
        headers: {
            Authorization: API.AUTH,
            accessToken : token
        }
    })
}

export function verifyOTP(otp, token) {
    return axios({
        method: 'post',
        url: `${API.VERIFY_OTP}`,
        data: {otp:otp},
        headers: {
            Authorization: API.AUTH,
            accessToken : token
        }
    })
}

export function resetPassword(password, token) {
    return axios({
        method: 'post',
        url: `${API.RESET_PASSWORD}`,
        data: {newPassword:password},
        headers: {
            Authorization: API.AUTH,
            accessToken : token
        }
    })
}

export function logout(params) {
    return axios.post(API.ADMIN_DIETICIAN_LOGOUT, params)
}

export function checkSession(params) {
    return axios.post(API.CHECK_SESSION, params)
}


export function changePassword(params) {
    return axios.post(API.CHANGE_PASSWORD, params)
}

export function forgotPassword(params) {
    return axios.post(API.FORGOT_PASSWORD, params)
}

export function getDieticianProfile() {
    return axios.get(API.GET_DIETICIAN_PROFILE)
}


export function editDieticianProfile(params) {
    return axios.post(API.EDIT_DIETICIAN_PROFILE,params)
}

export function updateAllotedCalls(params) {
    return axios.post(API.UPDATE_ALLOTED_CALLS,params)
}

export function editUserProfile(params) {
    return axios.post(API.EDIT_USER_PROFILE,params)
}

export function getTrainersByAvailableSlotV2(params) {
    return axios.get(API.GET_TRAINERS_BY_AVAILABLE_SLOT_V2, params)
}

export function getUserFreeSessionsLeft(params) {
    return axios.get(API.GET_USER_FREE_SESSION_LEFT, params)
}

export function getUserPaymentDueSession(params) {
    return axios.get(API.GET_USER_PAYMENT_DUE_SESSIONS, params)
}