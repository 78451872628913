import axios from 'axios';
import API from '../../config/Api';

export function addDietitianUserFollowup(params) {
    return axios.post(API.ADD_DIETITIAN_FOLLOWUP_USER, params)
}
export function getFollowupsByAdmin(params) {
    return axios.get(API.GET_DIETITIAN_FOLLOWUP_USER, params)
}

export function editDietitianUserFollowup(params) {
    return axios.post(API.EDIT_DIETITIAN_FOLLOWUP_USER, params)
}
