import { combineReducers } from 'redux'
import usersState from './users'
import dietHistoryState from './dietHistory'
import followupsState from './followups'
import helpVideosState from './helpVideos'
import usersLabelsState from './usersLabels'
import dieticiansState from './dieticians'

const rootReducer = combineReducers({
    usersState,
    dietHistoryState,
    followupsState,
    helpVideosState,
    usersLabelsState,
    dieticiansState
})

export default rootReducer