export const calculateBMI = ({ height, weight }) => {
    let bmiCategory;
    const userHeight = parseFloat(height) / 100 //in metres
    const userWeight = parseFloat(weight)
    
    let BMI = (userWeight / Math.pow(userHeight, 2)).toFixed(2)
    if (BMI <= 15) {
        bmiCategory = 'Very Severely underweight'
    } else if (BMI <= 16) {
        bmiCategory = 'Severely underweight'
    } else if (BMI <= 18.5) {
        bmiCategory = 'Underweight'
    } else if (BMI <= 25) {
        bmiCategory = 'Healthy'
    } else if (BMI <= 30) {
        bmiCategory = 'Overweight'
    } else if (BMI <= 35) {
        bmiCategory = 'Moderately obese'
    } else if (BMI <= 40) {
        bmiCategory = 'Severely obese'
    } else if (BMI > 40) {
        bmiCategory = 'Very severely obese'
    }
    return (BMI + " (" + bmiCategory + ")")
}

export const calculateBMR = ({ height, weight, age, gender }) => {
    let BMR
    const userHeight = parseFloat(height)
    const userWeight = parseFloat(weight)
    const userAge = parseFloat(age)
    switch (gender) {
        case 'Male':
            BMR = (66.5 + (13.75 * userWeight) + (5.003 * userHeight) - (6.755 * userAge))
            break;
        case 'Female':
            BMR = 655.1 + (9.563 * userWeight) + (1.850 * userHeight) - (4.676 * userAge)
            break;
        default:
            BMR = (66.5 + (13.75 * userWeight) + (5.003 * userHeight) - (6.755 * userAge))
            break;
    }
    return BMR.toFixed(2)
}


export const convertFtToCm = (value) => {
    value = Number((value * 30.48).toFixed(1))
    return value
}

export const convertLbsToKg = (value) => {
    value = Number((value * 0.45359237).toFixed(1))
    return value
}


export const convertCmToFt = (value) => {
    value = Number((value / 30.48).toFixed(1))
    return value
}

export const convertKgToLbs = (value) => {
    value = Number((value * 2.205).toFixed(1))
    return value
}
