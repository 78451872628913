
import React from 'react';
import { Button, Modal } from 'react-bootstrap';

const Popup = ({ showPopup, oncloseModal, title, children, onSubmitModal, reSchedule, onOpenRescheduleModal }) => {
    return (
        <Modal show={showPopup} onHide={oncloseModal} centered>
            <Modal.Header closeButton>
                <Modal.Title>{title}</Modal.Title>
            </Modal.Header>

            <Modal.Body>{children}</Modal.Body>

            <Modal.Footer>
                {reSchedule && <Button variant="success" onClick={onOpenRescheduleModal}>Reschedule</Button>}
                {oncloseModal && <Button variant="danger" onClick={oncloseModal}>Close</Button>}
                {onSubmitModal && <Button variant="success" onClick={() => onSubmitModal()}>Submit</Button>}
            </Modal.Footer>
        </Modal>
    );
};

export default Popup;