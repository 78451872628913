import React, { Component } from 'react';
// import DatePicker from "react-datepicker";
import Select from 'react-select';
import { userStats, getUserTarget } from '../../config/service/usersService';
import cogoToast from 'cogo-toast';
import { Bar, Line } from 'react-chartjs-2';
import "chartjs-plugin-annotation";
import { DatePicker } from "@material-ui/pickers";
import moment from 'moment'


class UserStats extends Component {

    constructor(props) {
        super(props);
        this.state = {
            AllCountData: {},
            sessionExpired: false,
            calorieChart: '',
            userId: this.props.userDetails._id || this.props.userId,
            stepsChartLabels: [],
            stepsChartCounts: [],
            calorieChartLabels: [],
            calorieChartCounts: [],
            distanceChartLabels: [],
            distanceChartCounts: [],
            heartRateChartLabels: [],
            heartRateChartCounts: [],
            maxHeartRateChartCounts: [],
            minHeartRateChartCounts: [],
            waterIntakeChartLabels: [],
            waterIntakeChartCounts: [],
            sleepChartLabels: [],
            sleepChartCounts: [],
            weightChartLabels: [],
            weightChartCounts: [],
            bloodPressureChartLabels: [],
            sysolicBloodPressureChartCounts: [],
            diatolicBloodPressureChartCounts: [],
            sleepTarget: 0,
            bloodPressureTarget: 0,
            stepsTarget: 0,
            calorieTarget: 0,
            distanceTarget: 0,
            weightTarget: 0,
            waterIntakeTarget: 0,
            yearSelected: false,
            monthSelected: false,
            daySelected: false,
            weekSelected: false,
            currentDate: new Date(),

        }
    }


    getTargetOfUser(params) {
        let paramsObj = {
            userId: this.state.userId,
        }
        let self = this;
        getUserTarget(paramsObj)
            .then(function (res) {
                if (res.data.statusCode === 1) {
                    let target = res.data.responseData;
                    self.setState({
                        sleepTarget: target ? target.sleepData : '',
                        stepsTarget: target ? target.steps : '',
                        calorieTarget: target ? target.burnedCalorie : '',
                        bloodPressureTarget: target ? target.bloodPressure : '',
                        waterIntakeTarget: target ? target.waterIntake : '',
                        distanceTarget: target ? target.distance : '',
                        weightTarget: target ? target.weight : '',
                    })
                } else if (res.data.statusCode === 0) {
                    cogoToast.error(res.data.error.errorMessage)
                }
            })
    }


    handleDateChange(type, params) {
        this.setState({
            currentDate: params._d
        }, () => {
            this.getLabels(type)
        })
    }

    getLabels = (Label) => {
        let params
        let self
        switch (Label) {
            case 'years':
                this.setState({ yearSelected: true, monthSelected: false, daySelected: false, weekSelected: false })
                params = {
                    graphType: 3,
                    userId: this.state.userId,
                    currentDate: this.state.currentDate
                }
                self = this;
                userStats(params)
                    .then(function (result) {

                        const { responseData } = result.data;

                        if (result.data.statusCode == 1) {
                            const stepsChartLabels = responseData.monthlyStepsCount.weeklyData
                            const stepsChartCounts = responseData.monthlyStepsCount.countData

                            const calorieChartLabels = responseData.monthlyCalorieCount.weeklyData
                            const calorieChartCounts = responseData.monthlyCalorieCount.countData

                            const distanceChartLabels = responseData.monthlyDistanceCount.weeklyData
                            const distanceChartCounts = responseData.monthlyDistanceCount.countData


                            const heartRateChartLabels = responseData.monthlyHeartRateCount.weeklyData
                            const maxHeartRateChartCounts = responseData.monthlyHeartRateCount.countData
                            const minHeartRateChartCounts = responseData.monthlyHeartRateCount.count1Data

                            const waterIntakeChartLabels = responseData.monthlyWaterIntakeCount.weeklyData
                            const waterIntakeChartCounts = responseData.monthlyWaterIntakeCount.countData

                            const sleepChartLabels = responseData.monthlySleepCount.weeklyData
                            const sleepChartCounts = responseData.monthlySleepCount.countData

                            const weightChartLabels = responseData.monthlyWeightCount.weeklyData
                            const weightChartCounts = responseData.monthlyWeightCount.countData


                            const bloodPressureChartLabels = responseData.monthlyBloodPressureCount.weeklyData
                            const sysolicBloodPressureChartCounts = responseData.monthlyBloodPressureCount.sysolicCountData
                            const diatolicBloodPressureChartCounts = responseData.monthlyBloodPressureCount.diatolicCountData




                            self.setState({
                                stepsChartCounts, stepsChartLabels, calorieChartLabels, calorieChartCounts, distanceChartCounts, distanceChartLabels,
                                heartRateChartLabels, waterIntakeChartLabels, waterIntakeChartCounts, sleepChartLabels, sleepChartCounts,
                                weightChartLabels, weightChartCounts, bloodPressureChartLabels, sysolicBloodPressureChartCounts, diatolicBloodPressureChartCounts,
                                maxHeartRateChartCounts, minHeartRateChartCounts
                            })
                        }
                    })
                break;
            case 'months':
                this.setState({ monthSelected: true, yearSelected: false, daySelected: false, weekSelected: false })

                params = {
                    graphType: 2,
                    userId: this.state.userId,
                    currentDate: this.state.currentDate

                }
                self = this;
                userStats(params)
                    .then(function (result) {

                        if (result.data.statusCode == 1) {
                            const stepsChartLabels = result.data.responseData.wholeMonthStepsCount.weeklyData
                            const stepsChartCounts = result.data.responseData.wholeMonthStepsCount.countData

                            const calorieChartLabels = result.data.responseData.wholeMonthCalorieCount.weeklyData
                            const calorieChartCounts = result.data.responseData.wholeMonthCalorieCount.countData

                            const distanceChartLabels = result.data.responseData.wholeMonthDistanceCount.weeklyData
                            const distanceChartCounts = result.data.responseData.wholeMonthDistanceCount.countData

                            const heartRateChartLabels = result.data.responseData.wholeMonthHeartRateCount.weeklyData
                            const maxHeartRateChartCounts = result.data.responseData.wholeMonthHeartRateCount.countData
                            const minHeartRateChartCounts = result.data.responseData.wholeMonthHeartRateCount.count1Data

                            const waterIntakeChartLabels = result.data.responseData.wholeMonthWaterIntakeCount.weeklyData
                            const waterIntakeChartCounts = result.data.responseData.wholeMonthWaterIntakeCount.countData

                            const sleepChartLabels = result.data.responseData.wholeMonthSleepCount.weeklyData
                            const sleepChartCounts = result.data.responseData.wholeMonthSleepCount.countData

                            const weightChartLabels = result.data.responseData.wholeMonthWeightCount.weeklyData
                            const weightChartCounts = result.data.responseData.wholeMonthWeightCount.countData



                            const bloodPressureChartLabels = result.data.responseData.wholeMonthBloodPressureCount.weeklyData
                            const sysolicBloodPressureChartCounts = result.data.responseData.wholeMonthBloodPressureCount.sysolicCountData
                            const diatolicBloodPressureChartCounts = result.data.responseData.wholeMonthBloodPressureCount.diatolicCountData



                            self.setState({
                                stepsChartCounts, stepsChartLabels, calorieChartLabels, calorieChartCounts, distanceChartCounts, distanceChartLabels,
                                heartRateChartLabels, maxHeartRateChartCounts,
                                minHeartRateChartCounts, waterIntakeChartLabels, waterIntakeChartCounts, sleepChartLabels, sleepChartCounts,
                                weightChartLabels, weightChartCounts, bloodPressureChartLabels, sysolicBloodPressureChartCounts, diatolicBloodPressureChartCounts
                            })
                        }
                    })
                break;
            case 'weeks':
                this.setState({ yearSelected: false, monthSelected: false, daySelected: false, weekSelected: true })

                params = {
                    graphType: 1,
                    userId: this.state.userId,
                    currentDate: this.state.currentDate

                }
                self = this;
                userStats(params)
                    .then(function (result) {

                        if (result.data.statusCode == 1) {
                            const stepsChartLabels = result.data.responseData.dayWiseStepsCount.weeklyData
                            const stepsChartCounts = result.data.responseData.dayWiseStepsCount.countData

                            const calorieChartLabels = result.data.responseData.dayWiseCalorieCount.weeklyData
                            const calorieChartCounts = result.data.responseData.dayWiseCalorieCount.countData

                            const distanceChartLabels = result.data.responseData.dayWiseDistanceCount.weeklyData
                            const distanceChartCounts = result.data.responseData.dayWiseDistanceCount.countData

                            const heartRateChartLabels = result.data.responseData.dayWiseHeartRateCount.weeklyData
                            const maxHeartRateChartCounts = result.data.responseData.dayWiseHeartRateCount.countData
                            const minHeartRateChartCounts = result.data.responseData.dayWiseHeartRateCount.count1Data

                            const waterIntakeChartLabels = result.data.responseData.dayWiseWaterIntakeCount.weeklyData
                            const waterIntakeChartCounts = result.data.responseData.dayWiseWaterIntakeCount.countData

                            const sleepChartLabels = result.data.responseData.dayWiseSleepCount.weeklyData
                            const sleepChartCounts = result.data.responseData.dayWiseSleepCount.countData

                            const weightChartLabels = result.data.responseData.dayWiseWeightCount.weeklyData
                            const weightChartCounts = result.data.responseData.dayWiseWeightCount.countData

                            const bloodPressureChartLabels = result.data.responseData.dayWiseBloodPressureCount.weeklyData
                            const sysolicBloodPressureChartCounts = result.data.responseData.dayWiseBloodPressureCount.sysolicCountData
                            const diatolicBloodPressureChartCounts = result.data.responseData.dayWiseBloodPressureCount.diatolicCountData


                            self.setState({
                                stepsChartCounts,
                                stepsChartLabels,
                                calorieChartLabels,
                                calorieChartCounts,
                                distanceChartLabels,
                                distanceChartCounts,
                                heartRateChartLabels,
                                maxHeartRateChartCounts,
                                minHeartRateChartCounts,
                                waterIntakeChartLabels,
                                waterIntakeChartCounts,
                                sleepChartLabels,
                                sleepChartCounts,
                                weightChartLabels,
                                weightChartCounts,
                                bloodPressureChartLabels,
                                sysolicBloodPressureChartCounts,
                                diatolicBloodPressureChartCounts
                            })
                        }
                    })
                break;
            case 'days':
                this.setState({ yearSelected: false, monthSelected: false, daySelected: true, weekSelected: false })

                params = {
                    graphType: 0,
                    userId: this.state.userId,
                    currentDate: this.state.currentDate
                }
                self = this;
                userStats(params)
                    .then(function (result) {
                        if (result.data.statusCode == 1) {
                            const stepsChartLabels = result.data.responseData.hourStepsCount.weeklyData
                            const stepsChartCounts = result.data.responseData.hourStepsCount.countData

                            const calorieChartLabels = result.data.responseData.hourCalorieCount.weeklyData
                            const calorieChartCounts = result.data.responseData.hourCalorieCount.countData

                            const distanceChartLabels = result.data.responseData.hourDistanceCount.weeklyData
                            const distanceChartCounts = result.data.responseData.hourDistanceCount.countData

                            const heartRateChartLabels = result.data.responseData.hourHeartRateCount.weeklyData
                            const maxHeartRateChartCounts = result.data.responseData.hourHeartRateCount.countData
                            const minHeartRateChartCounts = result.data.responseData.hourHeartRateCount.count1Data

                            const waterIntakeChartLabels = result.data.responseData.hourWaterIntakeCount.weeklyData
                            const waterIntakeChartCounts = result.data.responseData.hourWaterIntakeCount.countData

                            const sleepChartLabels = result.data.responseData.hourSleepCount.weeklyData
                            const sleepChartCounts = result.data.responseData.hourSleepCount.countData


                            const weightChartLabels = result.data.responseData.hourWeightCount.weeklyData
                            const weightChartCounts = result.data.responseData.hourWeightCount.countData

                            const bloodPressureChartLabels = result.data.responseData.hourBloodPressureCount.weeklyData
                            const sysolicBloodPressureChartCounts = result.data.responseData.hourBloodPressureCount.sysolicCountData
                            const diatolicBloodPressureChartCounts = result.data.responseData.hourBloodPressureCount.diatolicCountData


                            self.setState({
                                stepsChartCounts, stepsChartLabels, calorieChartLabels, calorieChartCounts, distanceChartLabels, distanceChartCounts, heartRateChartLabels, waterIntakeChartLabels, waterIntakeChartCounts, sleepChartLabels, sleepChartCounts, weightChartLabels, weightChartCounts,
                                bloodPressureChartLabels, sysolicBloodPressureChartCounts, diatolicBloodPressureChartCounts, maxHeartRateChartCounts,
                                minHeartRateChartCounts
                            })
                        }
                    })
                break;
            default:
                this.setState({
                    data: {
                        ...this.state.data,
                        labels: ['Monday', 'Tuesday', 'Wednesday', 'Thirsday', 'Friday', 'Saturday', 'Sunday'],
                        datasets: [{ data: [70, 59, 80, 81, 56, 55, 40] }]
                    }
                })
        }

    }

    componentDidMount() {
        let userId = this.props.userDetails._id || this.props.userId;
        this.setState({ userId }, () => {
            this.getTargetOfUser()
            this.getLabels('years');
        })
    }

    render() {

        const { stepsChartCounts, stepsChartLabels, calorieChartCounts, calorieChartLabels, distanceChartCounts, distanceChartLabels, heartRateChartLabels, heartRateChartCounts, waterIntakeChartLabels, waterIntakeChartCounts, sleepChartLabels, sleepChartCounts, weightChartLabels, weightChartCounts,
            bloodPressureChartLabels, sysolicBloodPressureChartCounts, diatolicBloodPressureChartCounts, minHeartRateChartCounts, maxHeartRateChartCounts, daySelected, yearSelected, monthSelected, data, calorieChart, sleepTarget, calorieTarget, stepsTarget, bloodPressureTarget, distanceTarget, waterIntakeTarget, weightTarget, weekSelected, currentDate } = this.state


        var stepsGraph = {
            labels: stepsChartLabels,
            datasets: [
                {
                    label: 'Steps',
                    backgroundColor: 'rgba(244, 89, 42, 0.5)',
                    borderColor: '#000',
                    borderWidth: 1,
                    hoverBackgroundColor: '#f45729',
                    hoverBorderColor: '#000',
                    data: stepsChartCounts,

                },
                {
                    label: 'Target',
                    backgroundColor: 'red',
                    borderColor: 'red',
                    borderWidth: 1,
                    data: []
                }

            ],

        };

        var calorieGraph = {
            labels: calorieChartLabels,
            datasets: [
                {
                    label: 'Calories',
                    backgroundColor: 'rgba(244, 89, 42, 0.5)',
                    borderColor: '#000',
                    borderWidth: 1,
                    hoverBackgroundColor: '#f45729',
                    hoverBorderColor: '#000',
                    data: calorieChartCounts
                },
                {
                    label: 'Target',
                    backgroundColor: 'red',
                    borderColor: 'red',
                    borderWidth: 1,
                    data: []
                }
            ]
        };

        var distanceGraph = {
            labels: distanceChartLabels,
            datasets: [
                {
                    label: 'Distance',
                    backgroundColor: 'rgba(244, 89, 42, 0.5)',
                    borderColor: '#000',
                    borderWidth: 1,
                    hoverBackgroundColor: '#f45729',
                    hoverBorderColor: '#000',
                    data: distanceChartCounts
                },
                {
                    label: 'Target',
                    backgroundColor: 'red',
                    borderColor: 'red',
                    borderWidth: 1,
                    data: []
                }
            ]
        };

        var heartRateGraph = {
            labels: heartRateChartLabels,
            datasets: [
                {
                    label: 'Min Heart Rate',
                    backgroundColor: 'rgba(244, 89, 42, 0.5)',
                    borderColor: '#000',
                    borderWidth: 1,
                    hoverBackgroundColor: '#f45729',
                    hoverBorderColor: '#000',
                    data: minHeartRateChartCounts
                },
                {
                    label: 'Max Heart Rate',
                    backgroundColor: '#FF3636',
                    borderColor: '#000',
                    borderWidth: 1,
                    hoverBackgroundColor: '#f45729',
                    hoverBorderColor: '#000',
                    data: maxHeartRateChartCounts
                },

            ]
        };

        var waterIntakeGraph = {
            labels: waterIntakeChartLabels,
            datasets: [
                {
                    label: 'Water Intake',
                    backgroundColor: 'rgba(244, 89, 42, 0.5)',
                    borderColor: '#000',
                    borderWidth: 1,
                    hoverBackgroundColor: '#f45729',
                    hoverBorderColor: '#000',
                    data: waterIntakeChartCounts
                },
                {
                    label: 'Target',
                    backgroundColor: 'red',
                    borderColor: 'red',
                    borderWidth: 1,
                    data: []
                }
            ]
        };

        var sleepGraph = {
            labels: sleepChartLabels,
            datasets: [
                {
                    label: 'Sleep',
                    backgroundColor: 'rgba(244, 89, 42, 0.5)',
                    borderColor: '#000',
                    borderWidth: 1,
                    hoverBackgroundColor: '#f45729',
                    hoverBorderColor: '#000',
                    data: sleepChartCounts
                },
                {
                    label: 'Target',
                    backgroundColor: 'red',
                    borderColor: 'red',
                    borderWidth: 1,
                    data: []
                }
            ]
        };

        var weightGraph = {
            labels: weightChartLabels,
            datasets: [
                {
                    label: 'Weight',
                    backgroundColor: 'rgba(244, 89, 42, 0.5)',
                    borderColor: '#000',
                    borderWidth: 1,
                    hoverBackgroundColor: '#f45729',
                    hoverBorderColor: '#000',
                    data: weightChartCounts
                },
                {
                    label: 'Target',
                    backgroundColor: 'red',
                    borderColor: 'red',
                    borderWidth: 1,
                    data: []
                }
            ]
        };

        var bloodPressureGraph = {
            labels: bloodPressureChartLabels,
            datasets: [
                {
                    label: 'Diatolic Blood Pressure',
                    backgroundColor: 'rgba(0, 0, 0, 0.24)',
                    borderColor: '#000',
                    borderWidth: 1,
                    hoverBackgroundColor: '#d53a0b',
                    hoverBorderColor: '#000',
                    data: diatolicBloodPressureChartCounts
                },
                {
                    label: 'Sysolic Blood Pressure',
                    // backgroundColor: 'rgb(150, 148, 148)',
                    borderColor: '#000',
                    borderWidth: 1,
                    hoverBackgroundColor: '#f45729',
                    hoverBorderColor: '#000',
                    data: sysolicBloodPressureChartCounts
                },

            ]
        };



        return (
            <div className="text-center graph_scroll">
                <h1>Stats</h1>

                <div className="radio_wrapper">
                    <label>
                        1 Week
                        <input
                            type="radio"
                            value="weeks"
                            name="chartView"
                            onChange={() => this.getLabels('weeks')}
                        />
                        <span></span>
                    </label>
                    <label>
                        1 Month
                        <input
                            type="radio"
                            value="months"
                            name="chartView"
                            onChange={() => this.getLabels('months')}
                        />
                        <span></span>
                    </label>
                    <label>
                        1 Year
                        <input
                            defaultChecked={true}
                            type="radio"
                            value="years"
                            name="chartView"
                            onChange={() => this.getLabels('years')}
                        />
                        <span></span>
                    </label>
                </div>


                <div>

                    {yearSelected ? <div className="cm_slect_year_wrapper">
                        <span>        <DatePicker
                            views={["year"]}
                            label="Select Year"
                            value={currentDate}
                            color='success'
                            maxDate={new Date()}
                            id='year'
                            onChange={this.handleDateChange.bind(this, 'years')}
                        /></span>
                    </div> : <div></div>}

                    {monthSelected ? <div className="cm_slect_year_wrapper">
                        <span>
                            <DatePicker
                                views={["year", "month"]}
                                label="Select Month"
                                minDate={moment().subtract(5, 'y').format('YYYY-MM-DD')}
                                maxDate={new Date().setMonth(11)}
                                value={currentDate}
                                onChange={this.handleDateChange.bind(this, 'months')}
                            />
                        </span>
                    </div> : <div></div>}

                    {daySelected ? <div className="cm_slect_year_wrapper">
                        <span>
                            <DatePicker
                                label="Select Day"
                                value={currentDate}
                                onChange={this.handleDateChange.bind(this, 'days')}
                                animateYearScrolling
                            />
                        </span>
                    </div> : <div></div>}

                    {weekSelected ? <div className="cm_slect_year_wrapper">
                        <span>
                            <DatePicker
                                label="Select initial date of week"
                                value={currentDate}
                                onChange={this.handleDateChange.bind(this, 'weeks')}
                                animateYearScrolling
                            />
                        </span>
                    </div> : <div></div>}

                </div>



                <div>
                    <h3 className="text-center">Steps</h3>
                    <Bar
                        data={stepsGraph}
                        width={50}
                        height={12}
                        options={{
                            responsive: true,
                            annotation: {
                                annotations: [
                                    {
                                        // drawTime: "afterDatasetsDraw",
                                        // id: "hline",
                                        type: "line",
                                        mode: "horizontal",
                                        scaleID: "y-axis-0",
                                        value: stepsTarget,
                                        borderColor: "red",
                                        borderWidth: 1,
                                        label: {
                                            backgroundColor: "red",
                                            content: stepsTarget,
                                            enabled: true,
                                            yAdjust: 10
                                        }
                                    }
                                ]
                            },
                            scales: {
                                yAxes: [{
                                    ticks: {
                                        beginAtZero: true,
                                        display: true
                                    }
                                }]
                            }
                        }}

                    />
                </div>

                <div>
                    <h3 className="text-center">Calories</h3>
                    <Bar
                        data={calorieGraph}
                        width={50}
                        height={12}
                        options={{
                            responsive: true,
                            annotation: {
                                annotations: [
                                    {
                                        // drawTime: "afterDatasetsDraw",
                                        // id: "hline",
                                        type: "line",
                                        mode: "horizontal",
                                        scaleID: "y-axis-0",
                                        value: calorieTarget,
                                        borderColor: "red",
                                        borderWidth: 1,
                                        label: {
                                            backgroundColor: "red",
                                            content: calorieTarget,
                                            enabled: true,
                                            yAdjust: 10

                                        }
                                    }
                                ]
                            },
                            scales: {
                                yAxes: [{
                                    ticks: {
                                        beginAtZero: true,
                                        display: true
                                    }
                                }]
                            }
                        }}
                    />
                </div>


                <div>
                    <h3 className="text-center">Weight</h3>
                    <Bar
                        data={weightGraph}
                        width={50}
                        height={12}
                        options={{
                            responsive: true,
                            annotation: {
                                annotations: [
                                    {
                                        // drawTime: "afterDatasetsDraw",
                                        // id: "hline",
                                        type: "line",
                                        mode: "horizontal",
                                        scaleID: "y-axis-0",
                                        value: weightTarget,
                                        borderColor: "red",
                                        borderWidth: 1,
                                        label: {
                                            backgroundColor: "red",
                                            content: weightTarget,
                                            enabled: true,
                                            yAdjust: 10

                                        }
                                    }
                                ]
                            },
                            scales: {
                                yAxes: [{
                                    ticks: {
                                        beginAtZero: true,
                                        display: true
                                    }
                                }]
                            }
                        }}
                    />
                </div>

                <div>

                    <h3 className="text-center">Blood Pressure</h3>
                    <Line
                        data={bloodPressureGraph}
                        width={50}
                        height={12}
                        options={{
                            scales: {
                                yAxes: [{
                                    ticks: {
                                        beginAtZero: true,
                                        display: true
                                    }
                                }]
                            }
                        }}
                    />
                </div>


                <div>
                    <h3 className="text-center">Sleep</h3>
                    <Bar
                        data={sleepGraph}
                        width={50}
                        height={12}
                        options={{
                            responsive: true,
                            annotation: {
                                annotations: [
                                    {
                                        // drawTime: "afterDatasetsDraw",
                                        // id: "hline",
                                        type: "line",
                                        mode: "horizontal",
                                        scaleID: "y-axis-0",
                                        value: sleepTarget,
                                        borderColor: "red",
                                        borderWidth: 1,
                                        label: {
                                            backgroundColor: "red",
                                            content: sleepTarget,
                                            enabled: true,
                                            yAdjust: 10

                                        }
                                    }
                                ]
                            },
                            scales: {
                                yAxes: [{
                                    ticks: {
                                        beginAtZero: true,
                                        display: true
                                    }
                                }]
                            }
                        }}
                    />
                </div>


                <div>
                    <h3 className="text-center">Water Intake</h3>
                    <Bar
                        data={waterIntakeGraph}
                        width={50}
                        height={12}
                        options={{
                            responsive: true,
                            annotation: {
                                annotations: [
                                    {
                                        // drawTime: "afterDatasetsDraw",
                                        // id: "hline",
                                        type: "line",
                                        mode: "horizontal",
                                        scaleID: "y-axis-0",
                                        value: waterIntakeTarget,
                                        borderColor: "red",
                                        borderWidth: 1,
                                        label: {
                                            backgroundColor: "red",
                                            content: waterIntakeTarget,
                                            enabled: true,
                                            yAdjust: 10,
                                        }
                                    }
                                ]
                            },
                            scales: {
                                yAxes: [{
                                    ticks: {
                                        beginAtZero: true,
                                        display: true
                                    }
                                }]
                            }
                        }}
                    />
                </div>


                <div>
                    <h3 className="text-center">Heart Rate</h3>
                    <Bar
                        data={heartRateGraph}
                        width={50}
                        height={12}
                        options={{
                            scales: {
                                yAxes: [{
                                    ticks: {
                                        beginAtZero: true,
                                        display: true
                                    }
                                }]
                            }
                        }}
                    />
                </div>


            </div>
        )
    }
}

export default UserStats;