import { getAllHistory, getDietPlanHistory, getCurrentDietPlan } from '../service/dietHistoryService'

export const FETCH_DIET_HISTORY = 'FETCH_DIET_HISTORY'
export const SELECT_DIET_HISTORY = 'SELECT_DIET_HISTORY'
export const FETCH_CURRENT_DIET = 'FETCH_CURRENT_DIET'

export const getAllDietHistoryAction = (params) => ({
  type: FETCH_DIET_HISTORY,
  promise: getAllHistory(params)
})

export const getDietPlanViaAdminAction = (params) => ({
  type: FETCH_CURRENT_DIET,
  promise: getCurrentDietPlan(params)
})

export const selectHistoryAction = (params) =>({
  type: SELECT_DIET_HISTORY,
  promise: getDietPlanHistory(params)
})