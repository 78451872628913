
import { FETCH_ALL_DIETICIAN } from '../actions/dieticians'

const defaultState = {
  status: 'UNINIT',
  dieticiansList: [],
  loader: false
}

export default (state = defaultState, action) => {
  switch (action.type) {
    case `${FETCH_ALL_DIETICIAN}_REQUEST`:
    {
      return {...state, 
        status : 'PENDING',
        loader: true,
        dieticiansList: [],
      }
    }

    case `${FETCH_ALL_DIETICIAN}_SUCCESS`:
    {
      let { 
        responseData : {
          dieticiansList
        }
       } = action.res
      const dieticians = dieticiansList.filter((dietician)=>{ 
        return dietician.status == 1
        }).map((dietician)=>{
          return {
          label: `${dietician.firstName} ${dietician.lastName}`,
          value: dietician._id
        }
      })
      return {...state, 
        status : 'SUCCESS',
        loader: false,
        dieticiansList: dieticians
      }
    }

    case `${FETCH_ALL_DIETICIAN}_FAILURE`:
    {
      let { 
        responseData : {
          dieticiansList
        }
       } = action.res
      return {...state, 
        status : 'FAILURE',
        loader: false,
        dieticiansList
      }
    }
    default:
      return state
  }
}
