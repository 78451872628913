import React, { Component } from 'react';
import { Modal } from 'react-bootstrap';
import Select from 'react-select';
import { getAllTimeSlots, submitTimeSlotsViaAdmin } from '../../config/service/usersService';
import cogoToast from 'cogo-toast';


class UpdateTimeSlots extends Component {
    constructor(props) {
        super(props);
        this.state = {
            allTimeSlots: [],
            selectedTimeSlots: []
        }
    }

    componentDidMount() {
        this.getAllTimeSlots()
        this.selectedTimeSlots()
    }


    getAllTimeSlots = () => {
        getAllTimeSlots()
            .then(res => {
                let response = res.data
                if (response.statusCode == 1) {
                    let allTimeSlots = response.responseData.timeSlots
                    allTimeSlots.map(timeSlot => {
                        timeSlot.label = timeSlot.timeSlot
                        timeSlot.value = timeSlot._id
                    })
                    this.setState({ allTimeSlots })
                } else if (response.statusCode == 0) {
                    this.setState({ validationError: response.error.errorMessage })
                }
            })
    }

    selectedTimeSlots = () => {
        let { selectedTimeSlots } = this.props
        selectedTimeSlots.map(timeSlot => {
            timeSlot.label = timeSlot.timeSlot
            timeSlot.value = timeSlot._id
        })
        this.setState({ selectedTimeSlots })
    }


    handleChange = (e) => {
        const { name, value } = e.target;
        this.setState({ [name]: value })
    }


    handleClose = () => {
        this.setState({ validationError: '' })
        this.props.getSelectedTimeSlots();
        this.props.handleClose();
    }

    changeTimeSlots = (timeSlots) => {

        if (timeSlots && timeSlots.length) {
            timeSlots.map(slot => {
                var t = this.convertTime12to24(slot.timeSlot);
                slot.timeSlotMilliseconds = Number(t.split(':')[0]) * 60 + Number(t.split(':')[1]) * 1000;
            })

            timeSlots.sort((a, b) => (a.timeSlotMilliseconds > b.timeSlotMilliseconds) ? 1 : ((b.timeSlotMilliseconds > a.timeSlotMilliseconds) ? -1 : 0));
        }
        
        //To sort time slots in ascending order.
        this.setState({ selectedTimeSlots: timeSlots })
    }

    convertTime12to24 = (time12h) => {
        const [time, modifier] = time12h.split(' ');
        let [hours, minutes] = time.split(':');
        if (hours === '12') {
            hours = '00';
        }
        if (modifier === 'pm') {
            hours = parseInt(hours, 10) + 12;
        }
        return `${hours}:${minutes}`;
    }

    submitTimeSlots = () => {
        let { selectedTimeSlots } = this.state
        const { userId } = this.props

        let timeSlotIds = []

        if (!selectedTimeSlots || (selectedTimeSlots && !selectedTimeSlots.length)) {
            this.setState({ validationError: 'Please select atleast one time slot.' })
        } else {
            selectedTimeSlots.map(timeSlot => {
                timeSlotIds.push(timeSlot.value)
            })
            let params = {
                timeSlotIds,
                reqOfUserId: userId
            }

            submitTimeSlotsViaAdmin(params)
                .then(res => {
                    let response = res.data
                    if (response.statusCode == 1) {
                        cogoToast.success(response.responseData.message)
                        this.handleClose();
                    } else if (response.statusCode == 0) {
                        this.setState({ validationError: response.error.errorMessage })
                    }
                })
        }
    }

    render() {
        let { editTimeSlotsPopup } = this.props;
        let { validationError, allTimeSlots, selectedTimeSlots } = this.state

        return (
            <Modal
                show={editTimeSlotsPopup}
                onHide={this.handleClose}
                size="md"
                aria-labelledby="contained-modal-title-vcenter"
                centered
            >
                <Modal.Header>
                    <Modal.Title>Update Time Slots</Modal.Title>
                </Modal.Header>

                <Modal.Body className="text-center">
                    <h6 className="text-center text-danger">{validationError}</h6>
                    <div className=" tmp_title">
                        <Select
                            isSearchable
                            isMulti
                            options={allTimeSlots}
                            value={selectedTimeSlots}
                            onChange={(val) => this.changeTimeSlots(val)}
                        />
                    </div>
                    <button className="btn btn-danger cm_danger" onClick={this.handleClose}>Cancel</button> &nbsp; &nbsp;
                <button className="btn btn-success" onClick={this.submitTimeSlots} >Update</button>
                </Modal.Body>
            </Modal>
        )
    }
}

export default UpdateTimeSlots;