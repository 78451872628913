
import { FETCH_DIET_HISTORY, SELECT_DIET_HISTORY, FETCH_CURRENT_DIET } from '../actions/dietHistory'

const defaultState = {
  status: 'UNINIT',
  dietHistory: [],
  currentDietPlan: [],
  totalRecords:0,
  selectedHistory: null,
  loader: false
}

export default (state = defaultState, action) => {
  switch (action.type) {
    case `${FETCH_DIET_HISTORY}_REQUEST`:
    {
      return {...state, 
        status : 'PENDING',
        totalRecords:0,
        loader: true,
        dietHistory: [],
        currentDietPlan:[]
      }
    }

    case `${FETCH_DIET_HISTORY}_SUCCESS`:
    {
      let { 
        responseData : {
          dietHistory,
          currentDietPlan,
          totalRecords
        }
       } = action.res
      return {...state, 
        status : 'SUCCESS',
        dietHistory,
        totalRecords,
        currentDietPlan,
        loader: false    
      }
    }

    case `${FETCH_DIET_HISTORY}_FAILURE`:
    {
      let { 
        responseData : {
          dietHistory,
          currentDietPlan,
          totalRecords
        }
       } = action.res
      return {...state, 
        status : 'FAILURE',
        dietHistory,
        currentDietPlan,
        totalRecords
      }
    }
    case `${SELECT_DIET_HISTORY}_REQUEST`:
    {
      return {...state, 
        status : 'PENDING',
        loader: true,
        selectedHistory:{},
      }
    }

    case `${SELECT_DIET_HISTORY}_SUCCESS`:
    {
      let { 
        responseData : {
          result
        }
       } = action.res
      return {...state, 
        status : 'SUCCESS',
        selectedHistory: result,
        loader: false    
      }
    }

    case `${SELECT_DIET_HISTORY}_FAILURE`:
    {
      let { 
        responseData : {
          result
        }
       } = action.res
      return {...state, 
        status : 'FAILURE',
        selectedHistory: result,
      }
    } 

    case `${FETCH_CURRENT_DIET}_REQUEST`:
    {
      return {...state, 
        status : 'PENDING',
        loader: true,
        selectedHistory:{},
      }
    }

    case `${FETCH_CURRENT_DIET}_SUCCESS`:
    {
      let { 
        responseData : {
          result
        }
       } = action.res
      return {...state, 
        status : 'SUCCESS',
        selectedHistory: result,
        loader: false    
      }
    }

    case `${FETCH_CURRENT_DIET}_FAILURE`:
    {
      let { 
        responseData : {
          result
        }
       } = action.res
      return {...state, 
        status : 'FAILURE',
        selectedHistory: result,
      }
    } 
    default:
      return state
  }
}
