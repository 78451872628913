import React, { Component } from 'react';
import dateFormat from 'dateformat';
import { Link, Redirect } from 'react-router-dom';
import Pagination from "react-js-pagination";
import { connect } from 'react-redux'
import cogoToast from 'cogo-toast';

import moment from 'moment';
import { Button } from 'react-bootstrap';
import Header from '../components/template/Header';
import { Loader } from '../components/Reuse';
import UsersListHeader from '../components/users/UsersHeader'
import notificationSound from '../assets/sounds/notificationSound.mp3';
import setting from '../config/settings/'

import { getAllUsersAction } from '../store/actions/users'
import { addDietitianUserFollowupAction } from '../store/actions/followups'
import { checkUnreadMessageAction } from '../store/actions/message'
import { getAllDieticiansAction } from '../store/actions/dieticians'
import { assignDietician as assignDieticianService } from '../store/service/dieticianService'
import { updateDietPlanEndDate } from '../config/service/usersService';

import io from 'socket.io-client';

import Popup from '../components/common/Popup'
import AddFollowup from '../components/followups/addFollowup'
import AssignDietician from '../components/users/AssignDietician'
import DatePicker from "react-datepicker";

const socketUrl = (setting.api.socket)
const count = 15

const initialState = {
    search: '',
    pageNo: 1,
    filterDietPlan: '',
    sortField: '',
    sortType: '',
    filterUsers: '',
    startDate: '',
    endDate: '',
    dateFrom: '',
    dateTo: '',
    assignToUserId: '',
    selectedDietician: '',
    isUnreadOnly: false,
    unreadMessageObject: {},
    initialFilterField: '',
    initialFilterValue: null,
    dateFilter: 'diet',
    dietPlanEndDate: null
   
}

class Users extends Component {
    constructor(props) {
        super(props);
        this.state = {
            ...initialState,
            messageForUserId: [],
            showAddFollowupPopup: false,
            viewFollowupPopup: false,
            newMessageUserId: '',
            selectedDate: new Date(),
            dietPlanFilter: null,
            selectedTime: null
        }
    }

    componentWillMount() {
        this.handleRealTimeMessage();
    }

    handleRealTimeMessage = () => {
        let senderId = localStorage.getItem('userId')
        let accessToken = localStorage.getItem('accessToken')
        var socket = io.connect(socketUrl, {
            transports: ['websocket'],
            query: { accessToken: accessToken, role: 2 }
        });

        socket.on('connection', (res) => {

        })
        socket.on('Received_Messages', (res) => {
            let audio = new Audio(notificationSound);
            audio.play();

            let messageForUserId = Array.from(this.state.messageForUserId)
            if (messageForUserId.indexOf(res.senderId) === -1) {
                messageForUserId.push(res.senderId)
                this.setState({ messageForUserId, newMessageUserId: res.senderId })
            }
        })
        this.setState({ socket })
    }


    handleSearch = e => {
        this.props.history.push(`/users/1`)
        this.setState({ ...initialState, search: e.target.value, pageNo: 1 }, () => this.getAllUsers())
    }

    handleDateRange = (dateFrom, dateTo, dietPlanFilter) => {
        dateFrom = moment(dateFrom).startOf('day').valueOf();
        dateTo = moment(dateTo).endOf('day').valueOf();
        this.setState({ ...initialState, dateFrom, dateTo, dietPlanFilter }, () => this.getAllUsers());
    }

    handleDietPlanAssigned = (dateFrom, dateTo, dietPlanFilter) => {
        dateFrom = dateFrom ? moment(dateFrom).startOf('day').valueOf() : '';
        dateTo = dateTo ? moment(dateTo).endOf('day').valueOf() : '';
        this.setState({ ...initialState, dietPlanFilter, dateFrom, dateTo }, () => this.getAllUsers());
    }

    handleRefreshDateRange = () => {

        this.setState({ ...initialState, dateFrom: '', dateTo: '' }, () => this.getAllUsers());
    }


    clearFilter = () => {
        this.setState({ ...initialState }, () => this.getAllUsers())
    }

    handlePageChange = pageNumber => {
        this.props.history.push(`/users/${pageNumber}`)
        this.setState({ pageNo: pageNumber }, () => this.getAllUsers());
    }

    handleFilterDietPlanApproveReject = e => {
        this.setState({ ...initialState, filterDietPlan: e.value }, () => this.getAllUsers());
    }

    handleSort = e => {
        const { sortType } = this.state;
        this.setState({ ...initialState, sortField: e, sortType: sortType == 1 ? -1 : 1 }, () => this.getAllUsers());
    }


    handleSortByStatus = e => {
        this.setState({ ...initialState, filterUsers: e, }, () => this.getAllUsers());
    }

    selectDateRange = (startDate, endDate) => {
        startDate = moment(startDate._d).startOf('day').valueOf();
        endDate = moment(endDate._d).endOf('day').valueOf();
        this.setState({ ...initialState, startDate, endDate }, () => this.getAllUsers());
    }

    handleCancelDateRange = () => {
        this.setState({ ...initialState, startDate: '', endDate: '' }, () => this.getAllUsers());
    }

    getUnreadMessageUser = () => {
        this.setState({ ...initialState, isUnreadOnly: !this.state.isUnreadOnly }, () => this.getAllUsers());
    }

    getAllUsers = () => {
        const { getUsers } = this.props
        let { search, pageNo, isUnreadOnly, filterDietPlan, sortField, sortType, filterUsers, startDate, endDate, dateFrom, dateTo, dietPlanFilter , dateFilter} = this.state;

        let params = {
            params: {
                search,
                pageNo: pageNo - 1,
                count,
                filterDietPlan,
                sortField,
                sortType,
                filterUsers,
                isUnreadOnly,
                startDate,
                endDate,
                role: 2,
                dateFrom,
                dateTo,
                dietPlanFilter,
                dateFilter
            }
        }
        getUsers(params, search)
    }

    handleViewEvent = (event) => {
        this.setState({ holidayObject: event, viewFollowupPopup: true })
    }

    openAddFollowupPopup = (selectedUserId) => {
        this.setState({ selectedUserId, showAddFollowupPopup: true })
    }

    closeModal = () => {
        this.setState({
            showAddFollowupPopup: false,
            selectedDate: new Date(),
            viewFollowupPopup: false,
            notes: ''
        })
    }

    selectDate = (date) => {
        this.setState({ selectedDate: new Date(date) })
    }

    addFollowupHandler = () => {
        let { selectedDate, notes, selectedUserId } = this.state;
        let { addFollowup } = this.props
        if (!selectedDate) {
            this.setState({ validationError: "Please select date." })
        } else if(moment(selectedDate).milliseconds(0).valueOf() % 1800000 ) {
            this.setState({ validationError: "Please select time." });
        }else if(!notes) {
            this.setState({ validationError: "Please write some notes." })

        } else {
            if (selectedUserId) {
                this.setState({ validationError: "" }, () => {
                    let params = {
                        followupDate: selectedDate,
                        notes,
                        userId: selectedUserId
                    }
                    addFollowup(params).then(({ res }) => {
                        if (res.data.statusCode == 1) {
                            cogoToast.success(res.data.responseData.message)
                        } else if (res.data.statusCode == 0) {
                            cogoToast.error(res.data.error.errorMessage)
                        }
                        this.closeModal()
                    }).catch((e) => {
                        console.log(e)
                        this.setState({ validationError: 'Oops something went wrong' })
                        this.closeModal()
                    })
                })
            }
        }
    }

    notesChangeHandler = (e) => {
        this.setState({ notes: e.target.value })
    }

    handleNewMessage = (newMessageUserId) => {
        if (newMessageUserId) {
            this.props.history.push({
                pathname: `/user/${newMessageUserId}`,
                activeTab: 'message'
            })
        }
    }
    componentDidMount() {
        let {
            match: {
                params: {
                    pageNo
                }
            }
        } = this.props
        pageNo = pageNo ? pageNo : 1
        this.setState({ pageNo: parseInt(pageNo) }, () => {
            this.getAllUsers()
            this.checkUnreadMessageHandler()
        })
    }


    openAssignDieticianPopup = (userID) => {
        const {
            getAllDieticians,
            dieticiansState: {
                dieticiansList
            } } = this.props

        this.setState({ assignDieticianPopup: !this.state.assignDieticianPopup, assignToUserId: userID }, () => {
            if (this.state.assignDieticianPopup && dieticiansList && dieticiansList.length === 0) {
                getAllDieticians()
            }
        })
    }

    closeAssignDieticianPopup = () => {
        this.setState({ assignDieticianPopup: false, assignToUserId: '' })
    }

    selectDeitician = (ditician) => {
        this.setState({
            selectedDietician: ditician.value
        })
    }

    assignDietition = () => {
        const { assignToUserId, selectedDietician } = this.state
        let params = {
            dieticianId: selectedDietician,
            assignToUserId
        }
        if (!selectedDietician) {
            this.setState({ validationError: "Please select a dietician" })
        } else {
            this.setState({ validationError: "" })
            assignDieticianService(params)
                .then((res) => {
                    if (res.data.statusCode == 1) {
                        this.getAllUsers()
                        this.closeAssignDieticianPopup();
                        cogoToast.success(res.data.responseData.message)
                    } else if (res.data.statusCode == 0) {
                        cogoToast.error(res.data.error.errorMessage)
                    }
                })
        }
    }

    checkUnreadMessageHandler = () => {
        let { checkUnreadMessage } = this.props
        checkUnreadMessage()
            .then(({ res }) => {
                if (res.data.statusCode == 1) {
                    let { responseData } = res.data
                    if (responseData.result) {
                        this.setState({ unreadMessageObject: responseData.result })
                    }
                } else if (res.data.statusCode == 0) {
                    cogoToast.error(res.data.error.errorMessage)
                }
            })
    }
    handleSelectDietPlanEndDate = (date, userId, index) => {
        const dateIsoString = new Date(date).toISOString();
         let {
            usersState: {
                users
            }
        } = this.props;

        users[index].dietPlan.dietPlanEndDate = dateIsoString ;
        const endDate =  moment( new Date(date)).valueOf();
          const params = {
              userId,
              dietPlanEndDate: endDate.toString()
        }
        this.setState({ dietPlanEndDate: date })
        updateDietPlanEndDate(params)
        .then(res => {
            let response = res.data;
            if (response.statusCode == 1) {
                
                cogoToast.success(response.responseData.message)
                
            }
            else if (response.statusCode == 0) {
                console.log(response.error.errorMessage)
                // this.setState({ validationError: response.error.errorMessage, editLoader: false })
            }
        })
          
      }

    render() {
        let {
            startDate, isUnreadOnly, newMessageUserId,
            endDate, search, sortType, messageForUserId, pageNo, showAddFollowupPopup, assignDieticianPopup, selectedDate, validationError, notes, unreadMessageObject, fromDate, toDate, initialFilterField, initialFilterValue, dietPlanEndDate } = this.state;
        let {
            usersState: {
                users,
                loader,
                totalUserCount,
                miniLoader
            },
            dieticiansState: {
                dieticiansList
            }
        } = this.props;
        return (
            <React.Fragment>
                {loader && <Loader />}
                <Header />

                <div className="page_wrapper">
                    <div className="user-card-wrapper">
                        <div className="card_wrapper py-0">
                            <UsersListHeader
                                totalUserCount={totalUserCount}
                                miniLoader={miniLoader}
                                search={search}
                                handleSearch={this.handleSearch}
                                handleSort={this.handleSort}
                                handleFilterDietPlanApproveReject={this.handleFilterDietPlanApproveReject}
                                clearFilter={this.clearFilter}
                                sortType={sortType}
                                dateRange={{ startDate, endDate }}
                                handleUnreadUser={this.getUnreadMessageUser}
                                isUnreadOnly={isUnreadOnly}
                                selectDateRange={this.selectDateRange}
                                handleCancelDateRange={this.handleCancelDateRange}
                                newMessageUserId={newMessageUserId}
                                handleNewMessage={this.handleNewMessage}
                                unreadMessageObject={unreadMessageObject}
                                filterDateObjet={{ fromDate, toDate }}
                                handleDateRange={this.handleDateRange}
                                handleRefreshDateRange={this.handleRefreshDateRange}
                                initialFilterField={initialFilterField}
                                initialFilterValue={initialFilterValue}
                                handleDietPlanAssigned={this.handleDietPlanAssigned}

                            />
                            <div className="table-wrapper">
                                <div className="call_schedules_body m-0 p-0 text-center table-responsive">
                                    <table className="table table-bordered ">
                                        <thead>
                                            <tr>
                                                <th scope="col">#</th>
                                                <th scope="col">Name</th>
                                                <th scope="col">Mobile No. </th>
                                                <th scope="col">Email</th>
                                                <th scope="col">Gender </th>
                                                <th scope="col">Date Of Birth </th>
                                                <th scope="col">Diet Plan Date </th>
                                                <th scope="col">Diet Plan End Date </th>
                                                {/* <th scope="col">Alloted calls </th> */}
                                                {/* <th scope="col">Change Dietician </th> */}
                                                <th scope="col">Status </th>
                                                {/* <th scope="col">Coupon applied on </th> */}
                                                <th scope="col">Sign up Date </th>
                                                <th scope="col">Followup</th>
                                                <th scope="col">Action </th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {users.map((details, i) => (                                   
                                                <tr key={i}>
                                                    <th scope="row">{((i + 1) + ((pageNo - 1) * count))}</th>
                                                    <td>{details.firstName + " " + details.lastName}</td>
                                                    <td>{"+" + details.fullMobileNo}</td>

                                                    <td>{details.email}</td>

                                                    <td>{details.gender == 1 ? "Male" : details.gender == 2 ? "Female" : "Not Set"}</td>

                                                    <td>{details.dob ? dateFormat(details.dob, "d mmmm yyyy") : 'Not Set'}</td>

                                                    <td>{details.dietPlanDate ? dateFormat(details.dietPlanDate, "d mmmm yyyy") :
                                                        <Link to={'/user/' + details._id + '/dietPlan'} tab="dietplan" title='View User'>
                                                            <span className="btn btn-success">Assign</span>
                                                        </Link>}</td>
                                                    <td> {details && details.dietPlanDate  ? <DatePicker
                                                        selected={details?.dietPlan?.dietPlanEndDate ? new Date(dateFormat(details.dietPlan.dietPlanEndDate, "dd mmmm yyyy")) : null}
                                                        onChange={(date) => this.handleSelectDietPlanEndDate(date, details._id, i)}
                                                        placeholderText="diet plan end  date"
                                                        dateFormat="do MMM yyyy"
                                                        minDate={new Date()}
                                                        className="mr-3 form-control"
                                                    /> : 'Not Assigned'
                                                   
                                                    }       
                                                    </td>

                                                    {/* <td>{details.allotedCalls ? details.allotedCalls : "-"}</td> */}

                                                    {/* <td><Button variant='info' onClick=
                                {this.openAssignDieticianPopup.bind(this, details._id)} size='sm' >Change Dietician</Button> </td> */}

                                                    <td>{details.status ? <span className="btn btn-success">
                                                        Active </span> :
                                                        <span className="btn btn-primary">Inactive</span>}</td>

                                                    {/* <td>{details.couponAppliedOn ? dateFormat(details.couponAppliedOn, "d mmmm yyyy") : "-"}</td> */}

                                                    <td>{dateFormat(details.created, "d mmmm yyyy")}</td>
                                                    <td>
                                                        <span className="followup-icon" onClick={() => { this.openAddFollowupPopup(details._id) }}>
                                                            <i className="fa fa-bell" />
                                                        </span>
                                                    </td>
                                                    <td>
                                                        <div className="card_btns">
                                                            <Link to={{
                                                                pathname: '/user/' + details._id,
                                                                activeTab: 'message',
                                                            }}
                                                                className="btn btn_primary" disabled={!details.status} title='View Messages' >
                                                                <i className="fa fa-envelope" />
                                                                {messageForUserId.includes(details._id) || (details.Messages && details.Messages.length ? details.Messages.length : null) ?
                                                                    <i className="fa fa-commenting cm_msg_noti" /> : null}
                                                            </Link>
                                                            <Link to={'/user/' + details._id} className="btn btn_primary" title='View User'>
                                                                <i className="fa fa-eye" />
                                                            </Link>


                                                        </div>
                                                    </td>
                                                </tr>
                                            ))}

                                        </tbody>
                                    </table>

                                    {users.length ? <Pagination
                                        activePage={pageNo}
                                        itemsCountPerPage={count}
                                        totalItemsCount={totalUserCount}
                                        pageRangeDisplayed={3}
                                        onChange={this.handlePageChange}
                                    /> : <p className="text-danger">No Users Found!</p>}

                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <Popup
                    showPopup={showAddFollowupPopup}
                    oncloseModal={this.closeModal}
                    title='Add Followup'
                    onSubmitModal={this.addFollowupHandler}
                >
                    <AddFollowup
                        selectDate={this.selectDate}
                        selectedDate={selectedDate}
                        validationError={validationError}
                        notes={notes}
                        handleChange={this.notesChangeHandler}
                    />
                </Popup>

                <Popup
                    showPopup={assignDieticianPopup}
                    oncloseModal={this.closeAssignDieticianPopup}
                    title='Assign Dietician'
                    onSubmitModal={this.assignDietition}
                >
                    <AssignDietician
                        validationError={validationError}
                        selectDietician={this.selectDeitician}
                        dieticianList={dieticiansList}
                    />
                </Popup>
            </React.Fragment>
        )
    }
}

const mapStateToProps = state => ({
    usersState: state.usersState,
    dieticiansState: state.dieticiansState
});

const mapDispatchToProps = dispatch => ({
    getUsers: (params, search) => dispatch(getAllUsersAction(params, search)),
    getAllDieticians: (params) => dispatch(getAllDieticiansAction(params)),
    addFollowup: (params) => dispatch(addDietitianUserFollowupAction(params)),
    checkUnreadMessage: (params) => dispatch(checkUnreadMessageAction(params))
})
export default connect(mapStateToProps,
    mapDispatchToProps,
)(Users);
