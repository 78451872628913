
const fetchMiddleware =  (store) => {
      return (next) => (action) => {
        const { promise, type, data, ...rest } = action
  
        if (!promise) return next(action)
  
        const SUCCESS = `${type}_SUCCESS`
        const REQUEST = `${type}_REQUEST`
        const FAILURE = `${type}_FAILURE`
  
        next({ ...rest, type: REQUEST })
  
        return promise
          .then((res) => {
            return res.json ? res.json() : res
          })
          .then((res) => {
            next({
              ...rest,
              res:res.data,
              data,
              type: SUCCESS
            })
            return {
              status: true,
              res,
              error: {}
            }
          })
          .catch((error) => {
            next({
              ...rest,
              error,
              data,
              type: FAILURE
            })
        
            return {
              status: false,
              res: {},
              error
            }
          })
      }
    }
export default  fetchMiddleware 