import React, { useEffect, useState } from 'react'
import Header from "../template/Header";
import { Switch, Route, Redirect, NavLink } from 'react-router-dom';
import UserLogin from './UserLogin';
import DietLog from './DietLog';
import AllNotification from './AllNotification';
import './notification.scss';
import UserMessages from './UserMessages';
import { getNotifications ,markFollowupNotificationRead} from '../../config/service/notificationService';
import cogoToast from 'cogo-toast';
import { markReadNotifications } from '../common/commonFunctions'

const Notification = () => {
    const [notifications, setNotifications] = React.useState([]);
    const [totalNotification, setTotalNotification] = useState(null)
    const [pageNo, setPageNo] = useState(0);
    const [hasMore, setHasMore] = useState(true);

    const fetchData = () => {
        const params = { 
            params: { 
                pageNo: pageNo,
                count: 10,
                notificationType: ""
            } 
    }
        getNotifications(params)
            .then((res) => {
                if (res?.data?.statusCode == 1) {
                    setNotifications([]);
                    setNotifications(res?.data?.responseData?.result);
                    setTotalNotification(res?.data?.responseData?.totalCount)
                    setHasMore(notifications?.length + 1 < res.data?.responseData?.totalCount)
                    //cogoToast.success(res.responseData.message)
                } else if (res?.data?.statusCode == 0) {
                    //cogoToast.error(res.data.error.errorMessage)
                }
            })
    }

    const markFollowupNotificationReadRecord = () => {
        const params = { 
             notificationType: 1
        }
        markReadNotifications(params)
    }


    useEffect(() => {
        markFollowupNotificationReadRecord();
        fetchData();
    }, []);

    const fetchMoreData = () => {
        let pageNoLocal = pageNo + 1;
        const params = { 
            params: { 
                pageNo: pageNoLocal,
                count: 10,
                notificationType: 53
            } 
    }
        
        getNotifications(params)
            .then((res) => {
                if (res?.data?.statusCode == 1) {
                    setNotifications(currentArray => [...currentArray, ...res?.data?.responseData?.result]);
                    setTotalNotification(res?.data?.responseData?.totalCount)
                    setPageNo(pageNoLocal);
                    setHasMore(notifications?.length + 1 < res.data?.responseData?.totalCount)
                    //cogoToast.success(res.responseData.message)
                } else if (res?.data?.statusCode == 0) {
                    //cogoToast.error(res.data.error.errorMessage)
                }
            })
        
    };

    return (
        <>
            <Header />
            <div className="page_wrapper notification-wrapper-page">
                <div className="pt-4">
                    <div className="sub_header card_wrapper p-2 text-left">
                        <h3 className="w-100">Notification</h3>
                    </div>
                </div>
                <div className="row">
                    <div className="col-md-3 ">
                        <div className="card_wrapper p-0">
                            <ul className="list-unstyled notification-sidebar mb-0">
                            <li className="notification-nav-item">
                                    <NavLink className="notification-link" to="/notification/all-notification">
                                        All Notification
                                    </NavLink>
                                </li>
                                <li className="notification-nav-item">
                                    <NavLink className="notification-link" to="/notification/user-login">
                                        User Login
                                    </NavLink>
                                </li>
                                <li className="notification-nav-item">
                                    <NavLink className="notification-link" to="/notification/diet-log">
                                        Diet Log
                                    </NavLink>
                                </li>
                                <li className="notification-nav-item">
                                    <NavLink className="notification-link" to="/notification/user-messages">
                                        User Messages
                                    </NavLink>
                                </li>
                                
                            </ul>
                        </div>
                    </div>
                    <div className="col-md-9">
                        <Switch>
                            {/* <Route  exact path="/notification" component={UserLogin} /> */}
                            <Route exact path="/notification/user-login" ><UserLogin data={notifications} totalCount={totalNotification} fetchMoreData={fetchMoreData} hasMore={hasMore}  /></Route>
                            <Route exact path="/notification/diet-log"  > <DietLog data={notifications} totalCount={totalNotification} fetchMoreData={fetchMoreData} hasMore={hasMore}  /> </Route>
                            <Route exact path="/notification/user-messages" > <UserMessages data={notifications} totalCount={totalNotification} fetchMoreData={fetchMoreData} hasMore={hasMore}  /> </Route>
                            <Route exact path="/notification/all-notification" > <AllNotification data={notifications} totalCount={totalNotification} fetchMoreData={fetchMoreData} hasMore={hasMore}  /> </Route>
                            <Redirect exact from="/notification" to="/notification/all-notification" />
                        </Switch>
                    </div>
                </div>

            </div>
        </>
    )
}

export default Notification