import React, { Component } from 'react';
import Select from 'react-select';
import { getDietPlanTempelate, addDietPlan, getPendingApproveDietPlan, reviewDietPlan } from '../../config/service/usersService';
import cogoToast from 'cogo-toast';
import { Button, Modal, FormControl, Form, FormGroup, FormLabel, Spinner, Row } from 'react-bootstrap';
import DatePicker from "react-datepicker";
import { Loader } from '../Reuse';
import { addDays } from 'date-fns';
import FoodPlaceholder from '../../assets/img/FoodPlaceholder.jpg';
import EditDietPlan from './EditDietPlan';
import Popup from "reactjs-popup"
import moment from 'moment';
import profilePicPlaceholder from '../../assets/img/profilePicPlaceholder.png'
import DietPlanCard from '../common/DietPlan';

const CardWrapper = props => <div className="col mb-5">{props.children}</div>;


class DietPlanTemplate extends Component {

    constructor(props) {
        super(props);
        this.state = {
            dietTemplateLabels: [],
            morningSnacksList: [],
            breakfastList: [],
            lunchList: [],
            dinnerList: [],
            midMorningDietList: [],
            midEveningDietList: [],
            bedTimeDietList: [],
            dietPlanDetails: {},
            assignTemplatePopup: false,
            validFrom: new Date(new Date().setHours(0, 0, 0, 0)),
            validTo: addDays(new Date(new Date().setHours(23, 59, 59, 0)), 1),
            validationError: '',
            loader: false,
            openEditPopUp: false,
            foundAlreadyPendingApproveDietPlan: {},
            isFoundAlreadyPendingApproveDietPlan: false,
            showAllRejectMsgPopup: false,
            addNotesForDietPlanReviewPopup: false,
            notes: ''
        }
    }

    componentWillReceiveProps() {
        const { dietTemplateLabels } = this.props
        this.setState({ dietTemplateLabels })
    }

    componentDidMount() {
        this.getPendingApproveDietPlan()
    }

    handleClose = () => {
        this.setState({ assignTemplatePopup: false, validationError: '', openEditPopUp: false, showAllRejectMsgPopup: false, addNotesForDietPlanReviewPopup: false })
        this.props.handleClose();
    }



    validFromChange = date => this.setState({ validFrom: new Date(new Date(date).setHours(0, 0, 0, 0)) });
    validToChange = date => this.setState({ validTo: new Date(new Date(date).setHours(23, 59, 59, 0)) });

    getPendingApproveDietPlan = () => {
        let paramsObj = {
            params: {
                dietOfUserId: this.props.userId
            }
        };

        getPendingApproveDietPlan(paramsObj)
            .then((res) => {
                let response = res.data
                if (response.statusCode == 1) {
                    var result = response.responseData.result;
                    if (result) {
                        const { morningSnacksDietTime,
                            breakfastDietTime,
                            midMorningDietTime,
                            lunchDietTime,
                            midEveningDietTime,
                            dinnerDietTime,
                            bedTimeDietTime,
                            perDayCalories,
                            recommendedMorningSnacksCalories,
                            recommendedBreakfastCalories,
                            recommendedMidMorningCalories,
                            recommendedLunchCalories,
                            recommendedMidEveningCalories,
                            recommendedDinnerCalories,
                            recommendedBedTimeCalories,
                            morningSnacksOptions,
                            breakfastOptions,
                            midMorningOptions,
                            lunchOptions,
                            midEveningOptions,
                            dinnerOptions,
                            bedTimeOptions,
                        } = response.responseData.result;

                        var result = response.responseData.result;
                        this.setState({
                            isFoundAlreadyPendingApproveDietPlan: true,
                            foundAlreadyPendingApproveDietPlan: result,

                            morningSnacksDietTime,
                            breakfastDietTime,
                            midMorningDietTime,
                            lunchDietTime,
                            midEveningDietTime,
                            dinnerDietTime,
                            bedTimeDietTime,
                            perDayCalories,
                            recommendedMorningSnacksCalories,
                            recommendedBreakfastCalories,
                            recommendedMidMorningCalories,
                            recommendedLunchCalories,
                            recommendedMidEveningCalories,
                            recommendedDinnerCalories,
                            recommendedBedTimeCalories,
                            morningSnacksList: morningSnacksOptions,
                            breakfastList: breakfastOptions,
                            midMorningDietList: midMorningOptions,
                            lunchList: lunchOptions,
                            midEveningDietList: midEveningOptions,
                            dinnerList: dinnerOptions,
                            bedTimeDietList: bedTimeOptions,
                            loader: false,


                            dietPlanDetails: {
                                perDayCalories: perDayCalories,
                                recommendedMorningSnacksCalories: recommendedMorningSnacksCalories,
                                recommendedBreakfastCalories: recommendedBreakfastCalories,
                                recommendedMidMorningCalories: recommendedMidMorningCalories,
                                recommendedLunchCalories: recommendedLunchCalories,
                                recommendedMidEveningCalories: recommendedMidEveningCalories,
                                recommendedDinnerCalories: recommendedDinnerCalories,
                                recommendedBedTimeCalories: recommendedBedTimeCalories,
                                morningSnacksDietTime: morningSnacksDietTime,
                                breakfastDietTime: breakfastDietTime,
                                midMorningDietTime: midMorningDietTime,
                                lunchDietTime: lunchDietTime,
                                midEveningDietTime: midEveningDietTime,
                                dinnerDietTime: dinnerDietTime,
                                bedTimeDietTime: bedTimeDietTime,
                                // dietPlanId: '5e6a29f402cc542da57385f7',
                            },
                        })
                    } else {
                        this.setState({
                            isFoundAlreadyPendingApproveDietPlan: false,
                            foundAlreadyPendingApproveDietPlan: {},
                        })
                    }
                } else if (response.statusCode == 0) {
                    this.setState({ loader: false })
                    cogoToast.error(response.error.errorMessage)
                }
            })

    }

    getDietPlanTemplate = (e) => {
        let paramsObj = {
            params: {
                templateId: e.value
            }
        }
        this.setState({ loader: true })
        getDietPlanTempelate(paramsObj)
            .then((res) => {
                let response = res.data
                if (response.statusCode == 1) {
                    cogoToast.info(response.responseData.message)

                    const { morningSnacksDietTime,
                        breakfastDietTime,
                        midMorningDietTime,
                        lunchDietTime,
                        midEveningDietTime,
                        dinnerDietTime,
                        bedTimeDietTime,
                        perDayCalories,
                        recommendedMorningSnacksCalories,
                        recommendedBreakfastCalories,
                        recommendedMidMorningCalories,
                        recommendedLunchCalories,
                        recommendedMidEveningCalories,
                        recommendedDinnerCalories,
                        recommendedBedTimeCalories,
                        morningSnacksOptions,
                        breakfastOptions,
                        midMorningOptions,
                        lunchOptions,
                        midEveningOptions,
                        dinnerOptions,
                        bedTimeOptions,
                    } = response.responseData.result;

                    var result = response.responseData.result;
                    this.setState({
                        morningSnacksDietTime,
                        breakfastDietTime,
                        midMorningDietTime,
                        lunchDietTime,
                        midEveningDietTime,
                        dinnerDietTime,
                        bedTimeDietTime,
                        perDayCalories,
                        recommendedMorningSnacksCalories,
                        recommendedBreakfastCalories,
                        recommendedMidMorningCalories,
                        recommendedLunchCalories,
                        recommendedMidEveningCalories,
                        recommendedDinnerCalories,
                        recommendedBedTimeCalories,
                        morningSnacksList: morningSnacksOptions,
                        breakfastList: breakfastOptions,
                        midMorningDietList: midMorningOptions,
                        lunchList: lunchOptions,
                        midEveningDietList: midEveningOptions,
                        dinnerList: dinnerOptions,
                        bedTimeDietList: bedTimeOptions,
                        loader: false,


                        dietPlanDetails: {
                            perDayCalories: perDayCalories,
                            recommendedMorningSnacksCalories: recommendedMorningSnacksCalories,
                            recommendedBreakfastCalories: recommendedBreakfastCalories,
                            recommendedMidMorningCalories: recommendedMidMorningCalories,
                            recommendedLunchCalories: recommendedLunchCalories,
                            recommendedMidEveningCalories: recommendedMidEveningCalories,
                            recommendedDinnerCalories: recommendedDinnerCalories,
                            recommendedBedTimeCalories: recommendedBedTimeCalories,
                            morningSnacksDietTime: morningSnacksDietTime,
                            breakfastDietTime: breakfastDietTime,
                            midMorningDietTime: midMorningDietTime,
                            lunchDietTime: lunchDietTime,
                            midEveningDietTime: midEveningDietTime,
                            dinnerDietTime: dinnerDietTime,
                            bedTimeDietTime: bedTimeDietTime,
                            // dietPlanId: '5e6a29f402cc542da57385f7',
                        },
                    })

                    // _id	"5e6a29f402cc542da57385f7"
                    // dietOfUserId	"5fe34371d7129130d2073d74"

                } else if (response.statusCode == 0) {
                    this.setState({ loader: false })
                    cogoToast.error(response.error.errorMessage)
                }
            })
    }

    assignToUser = () => {
        this.setState({ assignTemplatePopup: true })
    }


    submitAssignToUser = (e) => {
        e.preventDefault();

        const { morningSnacksDietTime,
            breakfastDietTime,
            midMorningDietTime,
            lunchDietTime,
            midEveningDietTime,
            dinnerDietTime,
            bedTimeDietTime,
            perDayCalories,
            recommendedMorningSnacksCalories,
            recommendedBreakfastCalories,
            recommendedMidMorningCalories,
            recommendedLunchCalories,
            recommendedMidEveningCalories,
            recommendedDinnerCalories,
            recommendedBedTimeCalories,
            morningSnacksList,
            breakfastList,
            midMorningDietList,
            lunchList,
            midEveningDietList,
            dinnerList,
            bedTimeDietList } = this.state

        let paramsObj = {
            dietOfUserId: this.props.userDetails._id,
            savedAsTemplate: true,
            morningSnacksDietTime,
            breakfastDietTime,
            midMorningDietTime,
            lunchDietTime,
            midEveningDietTime,
            dinnerDietTime,
            bedTimeDietTime,
            perDayCalories,
            recommendedMorningSnacksCalories,
            recommendedBreakfastCalories,
            recommendedMidMorningCalories,
            recommendedLunchCalories,
            recommendedMidEveningCalories,
            recommendedDinnerCalories,
            recommendedBedTimeCalories,
            morningSnacksOptions: morningSnacksList,
            breakfastOptions: breakfastList,
            midMorningOptions: midMorningDietList,
            lunchOptions: lunchList,
            midEveningOptions: midEveningDietList,
            dinnerOptions: dinnerList,
            bedTimeOptions: bedTimeDietList
        };

        addDietPlan(paramsObj)
            .then((res) => {
                let response = res.data
                console.log('rdddd')
                if (response.statusCode == 1) {
                    this.handleClose();
                    this.props.getUserDietPlan();
                    this.props.handleTabChange('dietPlan');
                    cogoToast.success(response.responseData.message)
                } else if (response.statusCode == 0) {
                    this.setState({ validationError: response.error.errorMessage })
                }


            })

    }

    handleOpenEditPoup = () => this.setState({ openEditPopUp: !this.state.openEditPopUp });

    handleChange = (e) => {
        this.setState({
            [e.target.name]: e.target.value,
            errors: false
        })
    }

    showAllRejectMsgPopup = () => {
        this.setState({
            showAllRejectMsgPopup: true
        })
    }

    addNotesForDietPlanReview = () => {
        this.setState({
            addNotesForDietPlanReviewPopup: true
        })
    }

    submitForReviewDietPlan = (e) => {
        e.preventDefault();
        this.setState({
            rejectloader: true
        })
        const { notes } = this.state
        let params = {};
        params.dietOfUserId = this.props.userId;
        params.notes = notes;

        reviewDietPlan(params)
            .then((response) => {
                if (response && response.data && response.data.responseData) {
                    this.setState({
                        rejectloader: false
                    }, function () {
                        this.getPendingApproveDietPlan()
                    })
                    // toast.success(response.data.responseData.message)
                } else if (response.data.statusCode == 0) {
                    this.setState({ loader: false })
                    // toast.error(response.data.error.errorMessage)
                }
                this.setState({ addNotesForDietPlanReviewPopup: false })
            })
    }

    render() {
        let { dietTemplateLabels,
            morningSnacksList, breakfastList, midMorningDietList, lunchList, midEveningDietList, dinnerList, bedTimeDietList, dietPlanDetails, assignTemplatePopup, morningSnacksDietTime,
            breakfastDietTime,
            midMorningDietTime,
            lunchDietTime,
            midEveningDietTime,
            dinnerDietTime,
            bedTimeDietTime,
            perDayCalories,
            recommendedMorningSnacksCalories,
            recommendedBreakfastCalories,
            recommendedMidMorningCalories,
            recommendedLunchCalories,
            recommendedMidEveningCalories,
            recommendedDinnerCalories,
            recommendedBedTimeCalories, loader, validationError, openEditPopUp,
            isFoundAlreadyPendingApproveDietPlan, foundAlreadyPendingApproveDietPlan, showAllRejectMsgPopup } = this.state
        return (

            <div>
                {loader && <Loader />}

                {isFoundAlreadyPendingApproveDietPlan ?
                    <div className="text-center">
                        <h4>
                            {foundAlreadyPendingApproveDietPlan.status == 0 ? 'Diet plan is pending for review by manager' : foundAlreadyPendingApproveDietPlan.status == 1 ? 'Rejected Diet Plan By Manager' : foundAlreadyPendingApproveDietPlan.status == 2 ? 'Diet Plan Save In Draft' : null}
                        </h4>
                        <br></br>
                    </div>
                    :
                    <div className="mb-5">
                        <label className="cm_template">You can select a template from already saved ones and assign it to a user.</label>
                        <Select
                            isSearchable
                            options={dietTemplateLabels}
                            onChange={(val) => this.getDietPlanTemplate(val)}
                            placeholder="Select a template."
                            noOptionsMessage={() => "Ooops! No result found."}
                            className="diet-custom-styled-select"
                            classNamePrefix="diet"
                        />
                    </div>
                }



                {!morningSnacksList.length && !breakfastList.length && !lunchList.length && !dinnerList.length && !midMorningDietList.length && !midEveningDietList.length && !bedTimeDietList.length ? null :
                    <div>
                        <div className="row mb-5">
                            <div className="col"><h2>Diet Plan Template</h2>
                                <h6>Total calorie target: {perDayCalories}/day</h6>

                            </div>
                            {/* <div className="col text-right"><button className="btn btn-info" onClick={this.assignToUser}>Assign to user */}


                            <div className="col text-right">
                                {isFoundAlreadyPendingApproveDietPlan ?
                                    <>
                                        {foundAlreadyPendingApproveDietPlan.status == 1 || foundAlreadyPendingApproveDietPlan.status == 2 ?
                                            <>
                                                <button className="btn btn-info" onClick={this.addNotesForDietPlanReview}>
                                                    Submit For Review
                                                    <i className="fa fa-send ml-1"></i>
                                                </button>
                                                &nbsp;
                                            </>
                                            : null}

                                        {foundAlreadyPendingApproveDietPlan.notes && foundAlreadyPendingApproveDietPlan.notes.length > 0 ?
                                            <button className="btn btn-info" onClick={this.showAllRejectMsgPopup}>
                                                Show Messages
                                                <i className="fa fa-envelope ml-1"></i>
                                            </button>
                                            : null}
                                    </>
                                    : null}
                                &nbsp;
                                <button className="btn btn-info" onClick={this.handleOpenEditPoup}>
                                    Save As Draft
                                    <i className="fa fa-save ml-1"></i>
                                </button>
                            </div>
                        </div>
                        <CardWrapper>
                            <div className="diet-plan-heading">
                                <h3 className="heading-title">Pre-Breakfast</h3>
                                <h6>Calorie Target :
                                    <span className="value">{dietPlanDetails.recommendedMorningSnacksCalories} cal</span>
                                </h6>
                                <h6>Timing :
                                    <span className="value">{dietPlanDetails.morningSnacksDietTime ? dietPlanDetails.morningSnacksDietTime : '-'}</span>
                                </h6>
                            </div>


                            {!morningSnacksList.length ?
                                <p className="text-danger">No food allotted</p> :
                                <div className="diet-plan-wrapper">
                                    {morningSnacksList.map((options, i) => (
                                        <DietPlanCard itrate={i} dietList={options.morningSnacksDiet || []} totalCalories={options.morningSnacksDietCalorie || []} />
                                    ))}
                                </div>}
                        </CardWrapper>


                        <CardWrapper>

                            <div className="diet-plan-heading">
                                <h3 className="heading-title">Breakfast</h3>
                                <h6>Calorie Target :
                                    <span className="value">{dietPlanDetails.recommendedBreakfastCalories} cal</span>
                                </h6>
                                <h6>Timing :
                                    <span className="value">{dietPlanDetails.breakfastDietTime}</span>
                                </h6>
                            </div>

                            {!breakfastList.length ? <p className="text-danger">No food allotted</p> :
                                <div className="diet-plan-wrapper">
                                    {breakfastList.map((options, i) => (
                                        <DietPlanCard itrate={i} dietList={options.breakfastDiet || []} totalCalories={options.breakfastDietCalorie || []} />
                                    ))}
                                </div>}
                        </CardWrapper>


                        <CardWrapper>

                            <div className="diet-plan-heading">
                                <h3 className="heading-title">Mid Morning</h3>
                                <h6>Calorie Target :
                                    <span className="value">{dietPlanDetails.recommendedMidMorningCalories} cal</span>
                                </h6>
                                <h6>Timing :
                                    <span className="value">{dietPlanDetails.midMorningDietTime}</span>
                                </h6>
                            </div>

                            {!midMorningDietList.length ? <p className="text-danger">No food allotted</p> :
                                <div className="diet-plan-wrapper">
                                    {midMorningDietList.map((options, i) => (
                                        <DietPlanCard itrate={i} dietList={options.midMorningDiet || []} totalCalories={options.midMorningDietCalorie || []} />
                                    ))}
                                </div>}
                        </CardWrapper>


                        <CardWrapper>
                            <div className="diet-plan-heading">
                                <h3 className="heading-title">Lunch</h3>
                                <h6>Calorie Target :
                                    <span className="value">{dietPlanDetails.recommendedLunchCalories} cal</span>
                                </h6>
                                <h6>Timing :
                                    <span className="value">{dietPlanDetails.lunchDietTime}</span>
                                </h6>
                            </div>

                            {!lunchList.length ? <p className="text-danger">No food allotted</p> :
                                <div className="diet-plan-wrapper">
                                    {lunchList.map((options, i) => (
                                        <DietPlanCard itrate={i} dietList={options.lunchDiet || []} totalCalories={options.lunchDietCalorie || []} />
                                    ))}
                                </div>}
                        </CardWrapper>

                        <CardWrapper>

                            <div className="diet-plan-heading">
                                <h3 className="heading-title">Mid Evening</h3>
                                <h6>Calorie Target :
                                    <span className="value">{dietPlanDetails.recommendedMidEveningCalories} cal</span>
                                </h6>
                                <h6>Timing :
                                    <span className="value">{dietPlanDetails.midEveningDietTime}</span>
                                </h6>
                            </div>

                            {!midEveningDietList.length ? <p className="text-danger">No food allotted</p> :
                                <div className="diet-plan-wrapper">
                                    {midEveningDietList.map((options, i) => (
                                        <DietPlanCard itrate={i} dietList={options.midEveningDiet || []} totalCalories={options.midEveningDietCalorie || []} />
                                    ))}
                                </div>}
                        </CardWrapper>


                        <CardWrapper>

                            <div className="diet-plan-heading">
                                <h3 className="heading-title">Dinner</h3>
                                <h6>Calorie Target :
                                    <span className="value">{dietPlanDetails.recommendedDinnerCalories} cal</span>
                                </h6>
                                <h6>Timing :
                                    <span className="value">{dietPlanDetails.dinnerDietTime}</span>
                                </h6>
                            </div>

                            {!dinnerList.length ? <p className="text-danger">No food allotted</p> :
                                <div className="diet-plan-wrapper">
                                    {dinnerList.map((options, i) => (
                                        <DietPlanCard itrate={i} dietList={options.dinnerDiet || []} totalCalories={options.dinnerDietCalorie || []} />
                                    ))}
                                </div>
                            }
                        </CardWrapper>



                        <CardWrapper>
                            <div className="diet-plan-heading">
                                <h3 className="heading-title">Bed Time</h3>
                                <h6>Calorie Target :
                                    <span className="value">{dietPlanDetails.recommendedBedTimeCalories} cal</span>
                                </h6>
                                <h6>Timing :
                                    <span className="value">{dietPlanDetails.bedTimeDietTime}</span>
                                </h6>
                            </div>

                            {!bedTimeDietList.length ? <p className="text-danger">No food allotted</p> :
                                <div className="diet-plan-wrapper">
                                    {bedTimeDietList.map((options, i) => (
                                        <DietPlanCard itrate={i} dietList={options.bedTimeDiet || []} totalCalories={options.bedTimeDietCalorie || []} />
                                    ))}
                                </div>}
                        </CardWrapper>

                    </div>}

                <EditDietPlan getPendingApproveDietPlan={this.getPendingApproveDietPlan} handleClose={this.handleClose}  {...this.state} dietOfUserId={this.props.dietOfUserId} updateDisabled={false} displayButton="Save As Draft" />

                <Modal
                    show={assignTemplatePopup}
                    onHide={this.handleClose}
                    size="md"
                    aria-labelledby="contained-modal-title-vcenter"
                    centered
                >

                    <Modal.Body className="text-center">

                        <Modal.Title>This action will replace user's existing diet plan. Are you sure you want to assign this template to user?</Modal.Title>

                        <div className="form_group_wrapper">
                            <h4 className="text-center text-danger">{validationError}</h4>

                            <button className="btn btn-danger cm_danger" onClick={this.handleClose}>Cancel</button> &nbsp; &nbsp;
                            <button className="btn btn-success" onClick={this.submitAssignToUser} >Assign</button>
                        </div>
                    </Modal.Body>
                </Modal>

                <Modal show={this.state["addNotesForDietPlanReviewPopup"]} closeOnDocumentClick onHide={this.handleClose} centered size="lg" backdrop="static">
                    <Modal.Header closeButton>
                        <Modal.Title><h6>Submit For Review</h6> </Modal.Title>
                    </Modal.Header>

                    <Modal.Body>
                        <Form onSubmit={this.submitForReviewDietPlan}>
                            <div className='row'>
                                <FormGroup className='col-12'>
                                    <FormLabel>Notes</FormLabel>
                                    <Form.Control
                                        as="textarea"
                                        placeholder="Notes for approval"
                                        value={this.state["notes"]}
                                        name='notes'
                                        onChange={this.handleChange}
                                        required
                                        rows={3}
                                    />

                                    <Form.Control.Feedback type="invalid">
                                        <FormLabel >First Name must be between 1 to 30 character</FormLabel>
                                    </Form.Control.Feedback>
                                </FormGroup>
                            </div>


                            <Row className="justify-content-center">
                                <Button variant="danger" onClick={this.handleClose}>Cancel</Button>
                                <Button className="ml-2" variant="success" type="submit" disabled={this.state["rejectloader"]} >
                                    {this.state["rejectloader"] ? 'Wait' : 'Submit for Review'}
                                </Button>
                            </Row>

                        </Form>
                    </Modal.Body>
                </Modal>

                <Modal
                    show={this.state["showAllRejectMsgPopup"]}
                    closeOnDocumentClick
                    onHide={this.handleClose}
                    centered
                    size="lg"
                    backdrop="static">

                    <Modal.Header closeButton>
                        <Modal.Title><h6>All Messages</h6> </Modal.Title>
                    </Modal.Header>

                    <Modal.Body>
                        <div className="msg_box">
                            {foundAlreadyPendingApproveDietPlan.notes && foundAlreadyPendingApproveDietPlan.notes.map(r =>
                                <div className="msg_card">
                                    <h6>{r.noteByUserId.firstName} {r.noteByUserId.lastName}</h6>
                                    <time>{moment(r.created).format('Do MMM YYYY H:mm')} </time>
                                    <span className={`msg_status ${r.action == 0 ? 'review' : r.action == 1 ? 'rejected' : ''}`}>{r.action == 0 ? 'Review' : r.action == 1 ? 'Rejected' : null}</span>
                                    <p>{r.message}</p>
                                </div>
                            )}
                        </div>
                    </Modal.Body>
                </Modal>



            </div>

        )
    }
}

export default DietPlanTemplate;