import React from 'react';  
import { MiniLoader } from '../Reuse';

const HelpVideoHeader = ({ 
    title,
    totalCount, 
    miniLoader, 
    placeholder,
    search, 
    handleSearch
}) => {
    return (
        <>
            <div className="sub_header">
                <h3>{title}</h3>
                <span className="separator"></span>
                <span>{totalCount} Total</span>
                <div className="search_wrapper">
                    {
                        miniLoader ? <MiniLoader /> : <i className="fa fa-search" aria-hidden="true"></i>
                    }

                    <input
                        type="text"
                        placeholder={placeholder}
                        className="form-control"
                        value={search}
                        onChange={handleSearch}
                    />
                </div>
               
            </div>
        </>
    );
};

export default HelpVideoHeader;