import React from "react";

const FollowupHeader = () => {
  return (
    <div className="pt-4">
      <div className="sub_header card_wrapper p-2 text-left">
        <h3 className="w-100">Followup</h3>
      </div>
    </div>
  );
};

export default FollowupHeader;
