import axios from 'axios';
import API from '../Api';

export function getNotifications(params) {
    return axios.get(API.GET_NOTIFICATION, params)
}

export function getUserProductDetail(params) {
    return axios.get(API.GET_USER_PRODUCT_DETAILS,params)
}

export function getNotificationCount(params) {
    return axios.get(API.NOTIFICATION_COUNT,params)
}

export function notificationViewed(params) {
    return axios.post(API.NOTIFICATION_VIEWED,params)
}

export function getFollowupNotificationCount(params) {
    return axios.get(API.FOLLOWUP_NOTIFICATION_COUNT,params)
}


export function getFollowupNotification(params) {
    return axios.get(API.FOLLOWUP_NOTIFICATION, params)
}

export function markFollowupNotificationRead(params) {
    return axios.post(API.NOTIFICATION_READ,params)
}